import { Transition } from "@headlessui/react";
import Prices from "components/Prices";
import React, { FC } from "react";
import { Link } from "react-router-dom";
import { CheckCircleIcon, CheckIcon } from "@heroicons/react/24/solid";

interface Props {
	show: boolean;
	reference?: string;
}

const NotifyCreateOrder: FC<Props> = ({ show, reference = "" }) => {
	return (
		<Transition
			appear
			show={show}
			className="p-4 max-w-md w-full bg-white dark:bg-slate-800 shadow-lg rounded-2xl pointer-events-auto ring-1 ring-black/5 dark:ring-white/10 text-slate-900 dark:text-slate-200"
			enter="transition-all duration-150"
			enterFrom="opacity-0 translate-x-20"
			enterTo="opacity-100 translate-x-0"
			leave="transition-all duration-150"
			leaveFrom="opacity-100 translate-x-0"
			leaveTo="opacity-0 translate-x-20"
		>
			<p className="block text-base leading-none flex flex-row items-center">
				<svg
					xmlns="http://www.w3.org/2000/svg"
					fill="none"
					viewBox="0 0 24 24"
					strokeWidth="1.5"
					stroke="currentColor"
					className="w-6 h-6 text-green-500 mr-1"
				>
					<path
						strokeLinecap="round"
						strokeLinejoin="round"
						d="M9 12.75L11.25 15 15 9.75M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
					/>
				</svg>
				Commande <span className="font-semibold">#{reference}</span> créée
			</p>
		</Transition>
	);
};

export default NotifyCreateOrder;
