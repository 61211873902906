import React, { FC, useEffect, useState } from "react";
import { Helmet } from "react-helmet-async";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import ProductCard from "components/ProductCard";
import { Product } from "data/data";
import services from "utils/services";
import ContactInfo from "./PageCheckout/ContactInfo";
import { useNavigate } from "react-router-dom";
import { ArrowRightIcon } from "@heroicons/react/24/solid";
import { useDispatch, useSelector } from "react-redux";
import { addProducts } from "redux/reducers/products";

export interface PageCollectionProps {
	className?: string;
}

const PageCollection: FC<PageCollectionProps> = ({ className = "" }) => {
	const cart = useSelector((state: any) => state.cart);
	const productStore = useSelector((state: any) => state.product);

	const [products, setProducts] = useState<Product[]>(productStore.list);
	const navigate = useNavigate();
	const dispatch = useDispatch();

	useEffect(() => {
		services.getProducts(
			{},
			(res) => {
				setProducts(res);
				dispatch(addProducts(res));
			},
			(err) => {
				console.error(err);
			}
		);
	}, []);

	return (
		<div
			className={`nc-PageCollection ${className}`}
			data-nc-id="PageCollection"
		>
			<Helmet>
				{/* <title>Collection || Ciseco Ecommerce Template</title> */}
			</Helmet>

			<div className="container py-16 lg:pb-28 lg:pt-20 space-y-16 sm:space-y-20 lg:space-y-28">
				<div className="space-y-10 lg:space-y-14">
					<ContactInfo
						onOpenActive={() => {}}
						onCloseActive={() => {}}
						onEdit={() => {
							navigate("/client");
						}}
						hideButton={true}
					/>
					{/* HEADING */}
					{/* <div className="max-w-screen-sm">
						<h2 className="block text-2xl sm:text-3xl lg:text-4xl font-semibold">
							Man collection
						</h2>
						<span className="block mt-4 text-neutral-500 dark:text-neutral-400 text-sm sm:text-base">
							We not only help you design exceptional products, but also make it
							easy for you to share your designs with more like-minded people.
						</span>
					</div> */}

					{/* <hr className="border-slate-200 dark:border-slate-700" /> */}
					<main>
						{/* TABS FILTER */}
						{/* <TabFilters /> */}

						{/* LOOP ITEMS */}
						<div className="grid grid-cols-3  lg:grid-cols-5 gap-x-8 gap-y-10 mt-8 lg:mt-10">
							{products.map((item, index) => (
								<ProductCard data={item} key={index} />
							))}
						</div>
					</main>
				</div>
				{cart.items.length > 0 && (
					<div className="pt-2 text-right">
						<ButtonPrimary onClick={() => navigate("/checkout")}>
							Checkout{" "}
							<ArrowRightIcon className="ml-2 w-5 h-5" aria-hidden="true" />
						</ButtonPrimary>
					</div>
				)}
			</div>
		</div>
	);
};

export default PageCollection;
