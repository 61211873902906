import React from "react";
import { useLocation } from "react-router-dom";
import HeaderLogged from "components/Header/HeaderLogged";
import Header from "components/Header/Header";
import { useAuth } from "provider/useAuth";

const SiteHeader = () => {
	const authContext = useAuth();

	return authContext.token ? <HeaderLogged /> : <Header />;
};

export default SiteHeader;
