import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { Page } from "./types";
import ScrollToTop from "./ScrollToTop";
import Footer from "shared/Footer/Footer";
import PageHome from "containers/PageHome/PageHome";
import Page404 from "containers/Page404/Page404";
import PageSignUp from "containers/PageSignUp/PageSignUp";
import PageLogin from "containers/PageLogin/PageLogin";
import SiteHeader from "containers/SiteHeader";
import PageCollection from "containers/PageCollection";
import ProductDetailPage from "containers/ProductDetailPage/ProductDetailPage";
import CheckoutPage from "containers/PageCheckout/CheckoutPage";
import { Toaster } from "react-hot-toast";
import CustomerAccountPage from "containers/AccountPage/CustomerAccountPage";
import ViewOrderPage from "containers/OrderPage/ViewOrderPage";
import { ProtectedRoute } from "./ProtectedRoute";
import { AuthProvider, AuthProviderProps } from "provider/AuthProvider";
import SettingsPage from "containers/SettingsPage/SettingsPage";
import UserEdit from "containers/UserPage/UserEdit";
import Dashboard from "containers/Dashboard/Dashboard";
import Stocks from "containers/Stocks/StocksPage";
import GalleryEdit from "containers/GalleryPage/GalleryEdit";
import ProviderEdit from "containers/ProviderPage/ProviderEdit";

export const pages: Page[] = [
	{ path: "/login", component: PageLogin, isAuthProtected: false },
	{ path: "/", component: PageHome, isAuthProtected: true, isIndex: true },
	{ path: "/produits", component: PageCollection, isAuthProtected: true },
	// { path: "/home2", component: PageHome2 },
	// { path: "/home3", component: PageHome3 },
	//
	// { path: "/home-header-2", component: PageHome },
	{
		path: "/product-detail/:name",
		component: ProductDetailPage,
		isAuthProtected: true,
	},
	// { path: "/product-detail-2", component: ProductDetailPage2 },
	//
	// { path: "/page-collection-2", component: PageCollection2 },
	// { path: "/page-collection", component: PageCollection, isAuthProtected: true },
	// { path: "/page-search", component: PageSearch },
	//
	// { path: "/account", component: AccountPage },
	{ path: "/client", component: CustomerAccountPage },
	{
		path: "/parametres",
		component: SettingsPage,
		isAuthProtected: true,
	},
	{
		path: "/gallery/:galleryId?",
		component: GalleryEdit,
		isAuthProtected: true,
	},
	{ path: "/utilisateur/:userId?", component: UserEdit, isAuthProtected: true },
	{
		path: "/apporteur/:providerId?",
		component: ProviderEdit,
		isAuthProtected: true,
	},
	// { path: "/utilisateur", component: UserEdit, isAuthProtected: true },
	// { path: "/account-savelists", component: AccountSavelists },
	// { path: "/account-change-password", component: AccountPass },
	// { path: "/account-billing", component: AccountBilling },
	// { path: "/account-my-order", component: AccountOrder },
	//
	{ path: "/checkout", component: CheckoutPage, isAuthProtected: true },
	//
	//
	// { path: "/contact", component: PageContact },
	// { path: "/signup", component: PageSignUp },
	{ path: "/commande/:id", component: ViewOrderPage, isAuthProtected: true },
	{ path: "/404", component: Page404, isAuthProtected: false },
	{ path: "/dashboard", component: Dashboard, isAuthProtected: true },
	{ path: "/stocks", component: Stocks, isAuthProtected: true },
];

const MyRoutes = () => {
	return (
		<BrowserRouter>
			<AuthProvider>
				<Toaster />
				<ScrollToTop />
				<SiteHeader />
				<Routes>
					{pages.map(
						(
							{ component: Component, path, isAuthProtected, isIndex },
							index
						) => {
							return (
								<Route
									key={index}
									element={
										isAuthProtected ? (
											<ProtectedRoute>
												{" "}
												<Component />{" "}
											</ProtectedRoute>
										) : (
											<Component />
										)
									}
									path={path}
									index={isIndex ? true : false}
								/>
							);
						}
					)}
					<Route element={<Page404 />} />
				</Routes>
				{/* <Footer /> */}
			</AuthProvider>
		</BrowserRouter>
	);
};

export default MyRoutes;
