import axios from "axios";

import store from "../redux/store";
import { logout, saveUser } from "redux/reducers/auth";
const instance = axios.create({
	baseURL: process.env.REACT_APP_API_URL,
	headers: {
		"Content-Type": "application/json",
		Authorization: "Authorization " + store.getState().auth.token || null,
	},
});

instance.interceptors.request.use(
	(config) => {
		config.headers["Authorization"] =
			"Authorization " + store.getState().auth.token;
		return config;
	},
	(error) => {
		Promise.reject(error);
	}
);

instance.interceptors.response.use(
	(response) => {
		return response;
	},
	(error) => {
		const originalRequest = error.config;
		const refreshToken = store.getState().auth.refreshToken;
		if (
			error.response &&
			error.response.status === 401 &&
			!originalRequest._retry &&
			refreshToken
		) {
			originalRequest._retry = true;

			return instance
				.post("/refresh-token", {
					refreshToken: refreshToken,
				})
				.then((res) => {
					if (res.status === 200) {
						// 1) put token to store
						store.dispatch(
							saveUser({
								token: res.data.token,
								refreshToken: res.data.refreshToken,
								user: res.data.user,
							})
						);

						// 2) Change Authorization header
						instance.defaults.headers.common["Authorization"] =
							"Authorization " + res.data.token;

						// 3) return originalRequest object with Axios.
						originalRequest.headers["Authorization"] =
							"Authorization " + res.data.token;
						return axios(originalRequest);
					}
				})
				.catch((err) => {
					console.log("ERROR IS " + err);
					store.dispatch(logout());
					return Promise.reject(error);
				});
		}
		return Promise.reject(error);
	}
);

type CallbackFunction = (args: any) => void;
const get = (
	uri: string,
	params: object,
	successCallback: CallbackFunction,
	failureCallback: CallbackFunction
) => {
	instance
		.get(uri, params)
		.then((response) => {
			if (response.status === 401) {
				window.location.href = "/login";
			} else {
				return successCallback(response.data);
			}
		})
		.catch((err) => {
			console.log(uri);
			console.log(err);
			return failureCallback(err);
		});
};

const post = async (
	uri: string,
	data: any,
	successCallback: CallbackFunction,
	failureCallback: CallbackFunction,
	config?: any
) => {
	return instance
		.post(uri, data, config)
		.then((response) => {
			if (response.status === 401) {
				window.location.href = "/login";
			} else if (response.status >= 400) {
				if (failureCallback) return failureCallback(response);
			} else {
				return successCallback(response.data);
			}
		})
		.catch((err) => {
			console.log(uri);
			console.log(err);
			if (failureCallback) return failureCallback(err);
		});
};

const patch = (
	uri: string,
	data: any,
	successCallback: CallbackFunction,
	failureCallback: CallbackFunction,
	config?: any
) => {
	instance
		.patch(uri, data)
		.then((response) => {
			if (response.status === 401) {
				window.location.href = "/login";
			} else if (response.status >= 400) {
				if (failureCallback) return failureCallback(response);
			} else {
				return successCallback(response.data);
			}
		})
		.catch((err) => {
			if (failureCallback) return failureCallback(err);
		});
};

const exported = { get, post, patch };
export default exported;
