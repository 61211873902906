import Label from "components/Label/Label";
import React, { FC, useState } from "react";
import Input from "shared/Input/Input";
import Select from "shared/Select/Select";

export interface GalleryForm {
	className?: string;
	gallery?: any;
	setGallery?: any;
	errors?: any;
}

const GalleryForm: FC<GalleryForm> = ({ gallery, setGallery, errors }) => {
	return (
		<div className="flex flex-col md:flex-row">
			<div className="flex-grow mt-10 md:mt-0 space-y-6">
				<div className="flex flex-col md:flex-row gap-5">
					<div className="mt-10 md:mt-0 space-y-1  flex-1">
						<Label>Nom</Label>
						<Input
							className={
								"mt-1.5" +
								(errors["name"] !== undefined ? " border-danger" : "")
							}
							value={gallery.name}
							onChange={({ target }) =>
								setGallery({ ...gallery, name: target.value })
							}
						/>
					</div>
					<div className="mt-10 md:mt-0 space-y-1  flex-1">
						<Label>Ville</Label>
						<Input
							className={
								"mt-1.5" +
								(errors["city"] !== undefined ? " border-danger" : "")
							}
							value={gallery.city}
							onChange={({ target }) =>
								setGallery({ ...gallery, city: target.value })
							}
						/>
					</div>
				</div>
				<div className="flex flex-col md:flex-row">
					<div className="mt-10 md:mt-0 space-y-1  flex-1">
						<Label>Adresse</Label>
						<Input
							className={
								"mt-1.5" +
								(errors["address"] !== undefined ? " border-danger" : "")
							}
							value={gallery.address}
							onChange={({ target }) =>
								setGallery({ ...gallery, address: target.value })
							}
						/>
					</div>
				</div>

				<div className="flex flex-col md:flex-row">
					<div className="mt-10 md:mt-0 space-y-1 flex-1">
						<Label>Email</Label>
						<div className="mt-1.5 flex">
							<span
								className={
									"inline-flex items-center px-2.5 rounded-l-2xl border border-r-0 border-neutral-200 dark:border-neutral-700 bg-neutral-50 dark:bg-neutral-800 text-neutral-500 dark:text-neutral-400 text-sm" +
									(errors["email"] !== undefined ? " border-danger" : "")
								}
							>
								<i className="text-2xl las la-envelope" />
							</span>
							<Input
								className={
									"!rounded-l-none" +
									(errors["email"] !== undefined ? " border-danger" : "")
								}
								placeholder="example@email.com"
								value={gallery.email}
								onChange={({ target }) =>
									setGallery({ ...gallery, email: target.value })
								}
							/>
						</div>
						{!!errors && !!errors.email && (
							<span className="text-sm ">{errors.email}</span>
						)}
					</div>
					<div className="mt-10 md:mt-0 md:pl-5 space-y-1  flex-1">
						<Label>Téléphone</Label>
						<div className="mt-1.5 flex">
							<span
								className={
									"inline-flex items-center px-2.5 rounded-l-2xl border border-r-0 border-neutral-200 dark:border-neutral-700 bg-neutral-50 dark:bg-neutral-800 text-neutral-500 dark:text-neutral-400 text-sm" +
									(errors["telephone"] !== undefined ? " border-danger" : "")
								}
							>
								<i className="text-2xl las la-phone-volume"></i>
							</span>
							<Input
								className={
									"!rounded-l-none" +
									(errors["telephone"] !== undefined ? " border-danger" : "")
								}
								value={gallery.telephone}
								onChange={({ target }) =>
									setGallery({ ...gallery, telephone: target.value })
								}
							/>
						</div>
					</div>
				</div>

				<div className="flex flex-col md:flex-row gap-5">
					<div className="mt-10 md:mt-0 space-y-1  flex-1">
						<Label>Identifiant</Label>
						<div className="mt-1.5 flex">
							<span
								className={
									"inline-flex items-center px-2.5 rounded-l-2xl border border-r-0 border-neutral-200 dark:border-neutral-700 bg-neutral-50 dark:bg-neutral-800 text-neutral-500 dark:text-neutral-400 text-sm" +
									(errors["username"] !== undefined ? " border-danger" : "")
								}
							>
								<i className="text-2xl las la-user"></i>
							</span>
							<Input
								className={
									"!rounded-l-none" +
									(errors["username"] !== undefined ? " border-danger" : "")
								}
								type="text"
								value={gallery.username}
								onChange={({ target }) =>
									setGallery({ ...gallery, username: target.value })
								}
							/>
						</div>
					</div>
					<div className="mt-10 md:mt-0 space-y-1  flex-1">
						<Label>Mot de passe</Label>
						<div className="mt-1.5 flex">
							<span
								className={
									"inline-flex items-center px-2.5 rounded-l-2xl border border-r-0 border-neutral-200 dark:border-neutral-700 bg-neutral-50 dark:bg-neutral-800 text-neutral-500 dark:text-neutral-400 text-sm" +
									(errors["password"] !== undefined ? " border-danger" : "")
								}
							>
								<i className="text-2xl las la-lock"></i>
							</span>
							<Input
								className={
									"!rounded-l-none" +
									(errors["password"] !== undefined ? " border-danger" : "")
								}
								type="password"
								value={gallery.password}
								onChange={({ target }) =>
									setGallery({ ...gallery, password: target.value })
								}
							/>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default GalleryForm;
