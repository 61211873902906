import { createSlice } from "@reduxjs/toolkit";

const initialState = {
	list: [],
};

export const productSlice = createSlice({
	name: "product",
	initialState,
	reducers: {
		addProducts: (state, action) => {
			state.list = action.payload;
		},
	},
});

// Action creators are generated for each case reducer function
export const { addProducts } = productSlice.actions;

export default productSlice.reducer;
