import Label from "components/Label/Label";
import React, { FC, useState } from "react";
import Input from "shared/Input/Input";
import Select from "shared/Select/Select";

export interface ProviderForm {
	className?: string;
	provider?: any;
	setProvider?: any;
	errors?: any;
}

const ProviderForm: FC<ProviderForm> = ({ provider, setProvider, errors }) => {
	return (
		<div className="flex flex-col md:flex-row">
			<div className="flex-grow mt-10 md:mt-0 space-y-6">
				<div className="flex flex-col md:flex-row gap-5">
					<div className="mt-10 md:mt-0 space-y-1  flex-1">
						<Label>Nom</Label>
						<Input
							className={
								"mt-1.5" +
								(errors["name"] !== undefined ? " border-danger" : "")
							}
							value={provider.lastName}
							onChange={({ target }) =>
								setProvider({ ...provider, lastName: target.value })
							}
						/>
					</div>
					<div className="mt-10 md:mt-0 space-y-1  flex-1">
						<Label>Prénom</Label>
						<Input
							className={
								"mt-1.5" +
								(errors["firstName"] !== undefined ? " border-danger" : "")
							}
							value={provider.firstName}
							onChange={({ target }) =>
								setProvider({ ...provider, firstName: target.value })
							}
						/>
					</div>
				</div>

				<div className="flex flex-col md:flex-row">
					<div className="mt-10 md:mt-0 space-y-1  flex-1">
						<Label>Téléphone</Label>
						<div className="mt-1.5 flex">
							<span
								className={
									"inline-flex items-center px-2.5 rounded-l-2xl border border-r-0 border-neutral-200 dark:border-neutral-700 bg-neutral-50 dark:bg-neutral-800 text-neutral-500 dark:text-neutral-400 text-sm" +
									(errors["telephone"] !== undefined ? " border-danger" : "")
								}
							>
								<i className="text-2xl las la-phone-volume"></i>
							</span>
							<Input
								className={
									"!rounded-l-none" +
									(errors["telephone"] !== undefined ? " border-danger" : "")
								}
								value={provider.telephone}
								onChange={({ target }) =>
									setProvider({ ...provider, telephone: target.value })
								}
							/>
						</div>
					</div>
					<div className="mt-10 md:mt-0 md:pl-5 space-y-1  flex-1">
						<Label>Commission</Label>
						<div className="mt-1.5 flex">
							{/* <span
								className={
									"inline-flex items-center px-2.5 rounded-l-2xl border border-r-0 border-neutral-200 dark:border-neutral-700 bg-neutral-50 dark:bg-neutral-800 text-neutral-500 dark:text-neutral-400 text-sm" +
									(errors["telephone"] !== undefined ? " border-danger" : "")
								}
							>
								<i className="text-2xl las la-phone-volume"></i>
							</span> */}
							<Input
								className={
									errors["commission"] !== undefined ? " border-danger" : ""
								}
								value={provider.commission}
								defaultValue={10}
								onChange={({ target }) =>
									setProvider({ ...provider, commission: target.value })
								}
							/>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default ProviderForm;
