import { Dialog, Transition } from "@headlessui/react";
import React, { FC, Fragment, useEffect, useState } from "react";
import ButtonCircle from "shared/Button/ButtonCircle";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import ButtonSecondary from "shared/Button/ButtonSecondary";
import ButtonClose from "shared/ButtonClose/ButtonClose";

export interface ModalDetailsChargesProps {
	gallery: any;
	show: boolean;
	charges: [];
	dateExp: any;
	onSaveCharges: (charges: any[]) => void;
	onCloseModal: () => void;
}

const ModalDetailsCharges: FC<ModalDetailsChargesProps> = ({
	gallery,
	show,
	charges,
	dateExp,
	onSaveCharges,
	onCloseModal,
}) => {
	const [isEdit, setIsEdit] = useState(false);
	const [editCharges, setEditCharges] = useState<any[]>([]);
	let totalCharges = 0;

	useEffect(() => {
		setEditCharges(
			charges.map((c: any) => {
				return { ...c };
			})
		);
	}, []);
	return (
		<Transition appear show={show} as={Fragment}>
			<Dialog
				as="div"
				className="fixed inset-0 z-50"
				onClose={() => {
					charges.forEach((c: any) => {
						c.delete = 0;
					});
					setEditCharges([...charges]);
					onCloseModal();
				}}
			>
				<div className="flex items-stretch md:items-center justify-center h-full text-center md:px-4">
					<Transition.Child
						as={Fragment}
						enter="ease-out duration-300"
						enterFrom="opacity-0"
						enterTo="opacity-100"
						leave="ease-in duration-200"
						leaveFrom="opacity-100"
						leaveTo="opacity-0"
					>
						<Dialog.Overlay className="fixed inset-0 bg-black/40 dark:bg-black/70" />
					</Transition.Child>

					{/* This element is to trick the browser into centering the modal contents. */}
					<span className="inline-block align-middle" aria-hidden="true">
						&#8203;
					</span>
					<Transition.Child
						as={Fragment}
						enter="ease-out duration-300"
						enterFrom="opacity-0 scale-95"
						enterTo="opacity-100 scale-100"
						leave="ease-in duration-200"
						leaveFrom="opacity-100 scale-100"
						leaveTo="opacity-0 scale-95"
					>
						<div className="relative inline-flex xl:py-8 w-full max-h-full w-[600px]">
							<div
								className="flex-1 flex overflow-hidden max-h-full p-8 w-full text-left align-middle transition-all transform lg:rounded-2xl bg-white 
				  dark:bg-neutral-900 dark:border dark:border-slate-700 dark:text-slate-100 shadow-xl max-h-[500px]"
							>
								<span className="absolute right-3 top-3 z-50">
									<ButtonClose onClick={onCloseModal} />
								</span>

								<div className="flex-1 overflow-y-auto rounded-xl hiddenScrollbar">
									<h3 className="text-xl font-semibold flex items-center">
										Charges{" "}
										{!!gallery && (
											<ButtonCircle
												className="transform ml-2 bg-slate-400 text-black hover:bg-slate-300"
												size="w-6 h-6"
												type="button"
												onClick={() => {
													setIsEdit(true);
												}}
											>
												<svg
													xmlns="http://www.w3.org/2000/svg"
													fill="none"
													viewBox="0 0 24 24"
													strokeWidth="1.5"
													stroke="currentColor"
													className="w-3 h-3"
												>
													<path
														strokeLinecap="round"
														strokeLinejoin="round"
														d="M16.862 4.487l1.687-1.688a1.875 1.875 0 112.652 2.652L6.832 19.82a4.5 4.5 0 01-1.897 1.13l-2.685.8.8-2.685a4.5 4.5 0 011.13-1.897L16.863 4.487zm0 0L19.5 7.125"
													/>
												</svg>
											</ButtonCircle>
										)}
									</h3>
									{editCharges
										.filter((ch: any) => !ch.delete)
										.map((ch: any, ind) => {
											totalCharges += parseFloat(ch.amount);
											return (
												<div
													className="flex flex-row item-center justify-between mt-2.5 mb-1"
													key={ind}
												>
													<p className="font-semibold text-slate-900 text-sm">
														{isEdit ? (
															<input
																className="rounded-sm w-[80%] text-sm"
																type="text"
																value={ch.name}
																onChange={({ target }) => {
																	ch.name = target.value;
																	setEditCharges([...editCharges]);
																}}
															/>
														) : (
															ch.name
														)}
													</p>

													{!gallery ? (
														<p className="font-semibold text-slate-900 text-sm text-left">
															{ch.gallery.name}
														</p>
													) : null}
													<p className="text-slate-900 mt-0.5 text-sm flex gap-1 justify-end items-center text-right">
														{isEdit ? (
															<input
																className="rounded-sm w-[50%] text-sm"
																type="text"
																value={ch.amount}
																onChange={({ target }) => {
																	ch.amount = target.value;
																	setEditCharges([...editCharges]);
																}}
															/>
														) : (
															ch.amount
														)}{" "}
														MAD
														{isEdit && (
															<button
																className={`flex items-center justify-center rounded-lg bg-red-400 hover:bg-red-600 text-slate-50 w-4 h-4`}
																onClick={() => {
																	// ch.delete = 1;
																	editCharges.find(
																		(c: any) => c._id == ch._id
																	).delete = 1;

																	setEditCharges([...editCharges]);
																}}
															>
																-
															</button>
														)}
													</p>
												</div>
											);
										})}
									<div className="flex flex-row item-center justify-between mt-2.5 mb-1">
										<p className="font-bold text-slate-900 text-md">Total</p>
										<p className="font-bold text-slate-900 text-md">
											{totalCharges.toFixed(2)}
										</p>
									</div>
									{isEdit && (
										<>
											<div className="flex mt-2">
												<ButtonPrimary
													className="xl:py-1 py-1 xl:px-3 sm:px-3 rounded-xl shadow-none sm:text-sm bg-slate-600"
													onClick={() => {
														editCharges.push({
															name: "",
															amount: 0,
															date: dateExp,
														});
														setEditCharges([...editCharges]);
													}}
												>
													+ Ajouter
												</ButtonPrimary>
											</div>
											<div className="flex flex-col sm:flex-row pt-6 items-center justify-center">
												<ButtonPrimary
													className="xl:py-1 py-1 sm:px-3 rounded-xl shadow-none sm:text-sm"
													onClick={() => {
														onSaveCharges(editCharges);
														setIsEdit(false);
													}}
												>
													Valider
												</ButtonPrimary>
												<ButtonSecondary
													className="xl:py-1 py-1 sm:px-3 rounded-xl ml-3 bg-slate-200 sm:text-sm"
													onClick={() => {
														setIsEdit(false);
														editCharges.forEach((c: any) => {
															c.delete = 0;
														});
														setEditCharges([...editCharges]);
													}}
												>
													Annuler
												</ButtonSecondary>
											</div>
										</>
									)}
								</div>
							</div>
						</div>
					</Transition.Child>
				</div>
			</Dialog>
		</Transition>
	);
};

export default ModalDetailsCharges;
