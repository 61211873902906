import {
	ArrowLeftIcon,
	ArrowRightIcon,
	ChevronDownIcon,
	ChevronRightIcon,
	PencilSquareIcon,
	PlusIcon,
} from "@heroicons/react/24/solid";
import BackgroundSection from "components/BackgroundSection/BackgroundSection";
import ModalUpdateStocks from "components/ModalUpdateStocks";
import dayjs from "dayjs";
import React, { FC, useEffect, useState } from "react";
import { Helmet } from "react-helmet-async";
import toast from "react-hot-toast";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import services from "utils/services";
import ShareSelect from "shared/Select/Select";
import Label from "components/Label/Label";

export interface StocksPageProps {
	className?: string;
}

const StocksPage: FC<StocksPageProps> = ({ className = "" }) => {
	const [stockData, setStockData] = useState<any>();
	const [showUpdateStock, setShowUpdateStock] = useState<number>(0);
	const [showParts, setShowParts] = useState<any>({});
	const [galleries, setGalleries] = useState<any>([]);
	const [activeTab, setActiveTab] = useState<string>("general");
	const [selectedGallery, setSelectedGallery] = useState<any>(null);
	const [selectedProduct, setSelectedProduct] = useState("");

	useEffect(() => {
		loadGalleries();
		// loadStock();
	}, []);

	// useEffect(() => {
	// 	loadStock();
	// }, [activeTab]);

	useEffect(() => {
		// if (selectedGallery) {
		loadStock(selectedGallery);
		// }
	}, [selectedGallery]);

	const loadGalleries = () => {
		services.getGalleries(
			{},
			(res) => {
				setGalleries(res.galleries);
			},
			(err) => {
				console.error(err);
			}
		);
	};
	const loadStock = (gallery: any = null) => {
		if (!selectedGallery) {
			setStockData({});
			return;
		}
		// if (activeTab != "general" && !gallery) {
		// 	setStockData({});
		// } else {
		services.getStocks(
			{ gallery: gallery ? gallery._id : null },
			(res) => {
				setSelectedProduct("");
				setStockData(res);
			},
			(err) => {
				console.error(err);
			}
		);
		// }
	};
	const updateStocks = (data: any, typeStock: number) => {
		// services[selectedGallery ? "updateGalleryStocks" : "updateGlobalStocks"](
		services.updateGalleryStocks(
			{ stocks: data, gallery: selectedGallery, typeStock },
			(success) => {
				setShowUpdateStock(0);
				loadStock(selectedGallery);
				toast.success("Stock mis à jour !", {
					position: "top-center",
					id: "nc-product-notify",
					duration: 3000,
					className: "border-1 border-green-500 font-semibold",
					style: { borderWidth: "1px" },
				});
			},
			(error) => {}
		);
	};

	return (
		<div className={`nc-StocksPage ${className}`} data-nc-id="StocksPage">
			<Helmet>
				<title>Stocks || IRIS GALERIE MAROC</title>
			</Helmet>
			<div className="nc-CommonLayoutProps container">
				<div className="mt-10 sm:mt-15 mb-10">
					<div className="max-w-2xl flex flex-row gap-3">
						<h2 className="text-xl font-semibold">Gestion des stocks</h2>
					</div>
					<hr className="mt-2 border-slate-200 dark:border-slate-700" />
					<div className="flex space-x-8 md:space-x-8 overflow-x-auto hiddenScrollbar items-end py-3">
						{/* {[
							{
								key: "general",
								name: "Stocks réserve",
							},
							{
								key: "gallery",
								name: "Stocks Galerie",
							},
						].map((item, index) => (
							<span
								onClick={() => setActiveTab(item.key)}
								key={index}
								className={`cursor-pointer block py-4 border-b-2 border-transparent flex-shrink-0 text-sm sm:text-base ${
									activeTab == item.key
										? "border-primary-500 font-medium text-slate-900 dark:text-slate-200"
										: "text-slate-500 dark:text-slate-400 hover:text-slate-800 dark:hover:text-slate-200"
								}`}
							>
								{item.name}
							</span>
						))}
					</div> */}
						{!!galleries && (
							<div>
								<Label>Gallerie</Label>
								<ShareSelect
									sizeClass="h-10"
									className="shadow-lg w-[250px] border-slate-400 rounded-lg"
									value={selectedGallery ? selectedGallery._id : ""}
									onChange={({ target }: { target: any }) => {
										if (!target.value) {
											setSelectedGallery(null);
										} else {
											setSelectedGallery(
												galleries.find((g: any) => g._id == target.value)
											);
										}
									}}
									placeholder=""
								>
									<option value=""></option>
									{galleries.map((g: any, ind: number) => (
										<option value={g._id} key={ind}>
											{g.name}
										</option>
									))}
								</ShareSelect>
							</div>
						)}

						{!!selectedGallery && (
							<>
								<ButtonPrimary
									className="bg-green-600 hover:bg-green-700 shadow-none"
									fontSize="text-sm"
									sizeClass="py-2 px-3"
									onClick={() => {
										setShowUpdateStock(1);
									}}
								>
									<PlusIcon className="w-4 h-4 mr-2 -ml-1" aria-hidden="true" />
									Fournisseur <ArrowRightIcon className="mx-1" /> Réserve
								</ButtonPrimary>

								<ButtonPrimary
									className="bg-slate-600 hover:bg-slate-700 shadow-none"
									fontSize="text-sm"
									sizeClass="py-2 px-3"
									onClick={() => {
										setShowUpdateStock(2);
									}}
								>
									<PlusIcon className="w-4 h-4 mr-2 -ml-1" aria-hidden="true" />
									Réserve <ArrowRightIcon className="mx-1" /> Galerie
								</ButtonPrimary>
							</>
						)}
					</div>
					<div className="py-1 flex flex-row gap-2 border-1 border-slate-100 flex-wrap">
						{!!stockData &&
							!!stockData.stocks &&
							stockData.stocks.map((stock: any, ind: number) => {
								return (
									<>
										<div
											className="text-sm py-1 flex flex-col gap-2 border-1 border-slate-100"
											key={ind}
										>
											<p className="w-[300px] font-semibold">{stock.name}</p>
											<p>
												{stock.quantityReserve || 0} - {stock.quantity || 0}{" "}
											</p>
										</div>
										{ind < stockData.stocks.length - 1 &&
											stock.type !== stockData.stocks[ind + 1].type && (
												<div className="basis-[100%] height-[0]"></div>
											)}
									</>
								);
							})}
					</div>
					<div className="flex flex-row gap-3  mt-5 items-center">
						<h2 className="flex text-xl font-semibold">
							Historique des stocks
						</h2>
						{!!stockData &&
							!!stockData.history &&
							stockData.history.length > 0 && (
								<ShareSelect
									sizeClass="h-10"
									className="shadow-lg border-[#6b7280] rounded-md mt-3 w-[300px]"
									value={selectedProduct}
									onChange={({ target }: { target: any }) => {
										setSelectedProduct(target.value);
									}}
									placeholder="Filtrer par produit"
								>
									<option value=""></option>
									{!!stockData &&
										!!stockData.stocks &&
										stockData.stocks.map((p: any, ind: number) => (
											<option value={p.name} key={ind}>
												{p.name}
											</option>
										))}
								</ShareSelect>
							)}
					</div>
					<hr className="mt-2 border-slate-200 dark:border-slate-700"></hr>

					{!!stockData &&
						!!stockData.history &&
						stockData.history
							.filter(
								(h: any) =>
									!selectedProduct ||
									h.stocks.find((s: any) => s.name == selectedProduct)
							)
							.sort((p1: any, p2: any) => p1.order > p2.order)
							.map((hist: any, ind: number) => (
								<div className="relative" key={ind}>
									<BackgroundSection className="bg-neutral-200/70 dark:bg-black/20  2xl:max-w-[100%] xl:max-w-[100%] max-w-[100%] xl:rounded-[6px]" />
									<div
										className={
											"text-sm py-1 px-5 flex flex-col gap-2 border border-slate-100 border-solid mt-3 relative  xl:rounded-[6px] " +
											(hist.type == "to_gallery" ||
											hist.type == "sales" ||
											hist.type == "incidents"
												? "border-red-500"
												: "border-green-500")
										}
									>
										<div className="flex flex-row justify-between items-center">
											<p className="text-md  font-semibold">
												{dayjs(hist.date).format("DD/MM/YYYY HH:mm")}{" "}
												{hist.comment ? " - " + hist.comment : ""}
											</p>
											<span
												onClick={() => {
													showParts[ind] = !showParts[ind];
													setShowParts({ ...showParts });
												}}
												className=" cursor-pointer w-12 sm:h-7 rounded-full text-slate-700 dark:text-slate-300 hover:bg-slate-100 dark:hover:bg-slate-800 focus:outline-none flex items-center justify-center"
											>
												{showParts[ind] ? (
													<ChevronDownIcon className="h-4 w-4" />
												) : (
													<ChevronRightIcon className="h-4 w-4" />
												)}
											</span>
										</div>
										{!!showParts[ind] && (
											<div className="py-1 flex flex-row gap-2 border-1 border-slate-100 flex-wrap">
												<table className="w-[600px] table-auto text-neutral-700">
													<thead>
														<th></th>
														{hist.type == "sales" ? (
															<>
																<th className=" text-neutral-700">
																	Qté initiale
																</th>
																<th className=" text-neutral-700">
																	Qté vendue
																</th>
																<th className=" text-neutral-700">
																	Qté restante
																</th>
															</>
														) : hist.type == "incidents" ? (
															<>
																{/* <th className=" text-neutral-700">
																Qté initiale
															</th> */}
																<th className=" text-neutral-700">
																	Qté détériorée
																</th>
																{/* <th className=" text-neutral-700">
																Qté restante
															</th> */}
															</>
														) : (
															<>
																<th className=" text-neutral-700">
																	Stock préc
																</th>
																<th className=" text-neutral-700">
																	{hist.type == "to_gallery"
																		? "Qté envoyée"
																		: "Qté ajoutée"}
																</th>
																<th className=" text-neutral-700">
																	Stock final
																</th>
																{hist.type == "to_gallery" && <th>Réserve</th>}
															</>
														)}
													</thead>
													<tbody>
														{hist.stocks.map((stock: any, ind: number) => (
															<tr>
																<td className="py-1 font-semibold">
																	{stock.name}
																</td>
																{hist.type == "sales" ? (
																	<>
																		<td className="text-center">
																			{stock.quantityInitial}
																		</td>
																	</>
																) : hist.type == "incidents" ? (
																	<td className="text-center">
																		{stock.quantityTrash || 0}
																	</td>
																) : (
																	<>
																		<td className="text-center">
																			{stock.previousQty}
																		</td>
																		<td className="text-center">
																			{stock.modifiedQty}
																		</td>
																	</>
																)}
																<td className="text-center">
																	{hist.type == "update"
																		? stock.quantityReserve
																		: stock.quantity}
																</td>
																{hist.type == "sales" && (
																	<td className="text-center">
																		{stock.quantityInitial -
																			stock.quantity -
																			(stock.quantityTrash || 0)}
																	</td>
																)}
																{hist.type == "to_gallery" && (
																	<td className="text-center">
																		{stock.quantityReserve}
																	</td>
																)}
															</tr>
														))}
													</tbody>
												</table>
											</div>
										)}
									</div>
								</div>
							))}
				</div>
			</div>

			{showUpdateStock !== 0 && (
				<ModalUpdateStocks
					stocks={stockData.stocks}
					globalStocks={stockData.globalStocks}
					gallery={selectedGallery}
					show={showUpdateStock !== 0}
					typeShow={showUpdateStock}
					onSave={(data, typeStock) => {
						updateStocks(data, typeStock);
					}}
					onCloseModal={() => setShowUpdateStock(0)}
				/>
			)}
		</div>
	);
};

export default StocksPage;
