import Label from "components/Label/Label";
import React, { FC, useState } from "react";
import Input from "shared/Input/Input";
import Select from "react-select";
import Textarea from "shared/Textarea/Textarea";
import { COUNTRY_LIST } from "contains/contants";

export interface CustomerForm {
	className?: string;
	customer?: any;
	setCustomer?: any;
	errors?: any;
}

const CustomerForm: FC<CustomerForm> = ({
	customer,
	setCustomer,
	errors = {},
}) => {
	return (
		<div className="flex flex-col md:flex-row">
			<div className="flex-grow mt-10 md:mt-0 space-y-6">
				{/* <div className="flex flex-row">
					<div className="pr-5 flex-col flex">
						<Radio
							className={
								"pt-3.5" +
								(errors["gender"] !== undefined ? " border-danger" : "")
							}
							sizeClassName={
								"h-6 w-6" +
								(errors["gender"] !== undefined ? " border-danger" : "")
							}
							name="gender"
							id="male"
							defaultChecked={customer.gender == "male"}
							label="Homme"
							onChange={(value) => {
								setCustomer({ ...customer, gender: value });
							}}
						/>
					</div>
					<div className="pl-5">
						<Radio
							className={
								"pt-3.5" +
								(errors["gender"] !== undefined ? " border-danger" : "")
							}
							sizeClassName={
								"h-6 w-6" +
								(errors["gender"] !== undefined ? " border-danger" : "")
							}
							name="gender"
							id="female"
							defaultChecked={customer.gender == "female"}
							label={"Femme"}
							onChange={(value) => {
								setCustomer({ ...customer, gender: value });
							}}
						/>
					</div>
				</div> */}
				<div className="flex flex-col md:flex-row mt-3">
					<div className="mt-10 md:mt-0 space-y-1 flex-1">
						<Label>Nom</Label>
						<Input
							className={
								"mt-1.5" +
								(errors["lastName"] !== undefined ? " border-danger" : "")
							}
							value={customer.lastName}
							onChange={({ target }) =>
								setCustomer({ ...customer, lastName: target.value })
							}
						/>
					</div>
					<div className="mt-10 md:mt-0 md:pl-5 space-y-1 flex-1">
						<Label>Prénom</Label>
						<Input
							className={
								"mt-1.5" +
								(errors["firstName"] !== undefined ? " border-danger" : "")
							}
							value={customer.firstName}
							onChange={({ target }) =>
								setCustomer({ ...customer, firstName: target.value })
							}
						/>
					</div>
				</div>

				<div className="flex flex-col md:flex-row">
					<div className="mt-10 md:mt-0 space-y-1 flex-1">
						<Label>Email</Label>
						<div
							className={
								"mt-1.5 flex" +
								(errors["email"] !== undefined ? " border-danger" : "")
							}
						>
							<span
								className={
									"inline-flex items-center px-2.5 rounded-l-2xl border border-r-0 border-neutral-200 dark:border-neutral-700 bg-neutral-50 dark:bg-neutral-800 text-neutral-500 dark:text-neutral-400 text-sm" +
									(errors["email"] !== undefined ? " border-danger" : "")
								}
							>
								<i className="text-2xl las la-envelope" />
							</span>
							<Input
								className={
									"!rounded-l-none" +
									(errors["email"] !== undefined ? " border-danger" : "")
								}
								placeholder="example@email.com"
								value={customer.email}
								onChange={({ target }) =>
									setCustomer({ ...customer, email: target.value })
								}
							/>
						</div>
						{!!errors && !!errors.email && (
							<span className="text-sm ">{errors.email}</span>
						)}
					</div>
					<div className="mt-10 md:mt-0 md:pl-5 space-y-1  flex-1">
						<Label>Téléphone</Label>
						<div className="mt-1.5 flex">
							<span
								className={
									"inline-flex items-center px-2.5 rounded-l-2xl border border-r-0 border-neutral-200 dark:border-neutral-700 bg-neutral-50 dark:bg-neutral-800 text-neutral-500 dark:text-neutral-400 text-sm" +
									(errors["telephone"] !== undefined ? " border-danger" : "")
								}
							>
								<i className="text-2xl las la-phone-volume"></i>
							</span>
							<Input
								className={
									"!rounded-l-none" +
									(errors["telephone"] !== undefined ? " border-danger" : "")
								}
								value={customer.telephone}
								onChange={({ target }) =>
									setCustomer({ ...customer, telephone: target.value })
								}
							/>
						</div>
					</div>
				</div>
				<div className="flex flex-col md:flex-row">
					<div className="mt-10 md:mt-0 space-y-1 flex-1">
						<Label>Pays</Label>
						<Select
							styles={{
								control: (baseStyles, state) => ({
									...baseStyles,
									borderColor:
										errors["country"] !== undefined
											? "rgba(220, 53, 69)"
											: "rgba(229, 231, 235)",
									borderRadius: "1rem",
								}),
							}}
							className={
								"mt-1.5 rounded-2xl" +
								(errors["country"] !== undefined ? " border-danger" : "")
							}
							classNames={{
								control: (state) =>
									"border-0 py-1 px-1 border-1 rounded-2xl border-neutral-200" +
									(errors["country"] !== undefined ? " border-danger" : ""),
							}}
							value={{ value: customer.country, label: customer.country }}
							onChange={(choice) =>
								setCustomer({
									...customer,
									country: choice?.value,
								})
							}
							placeholder="Pays"
							options={COUNTRY_LIST.map((c) => {
								return {
									value: c.name,
									label: c.name,
								};
							})}
						/>
					</div>
				</div>

				{/* ---- */}
				{/* <div className="max-w-lg">
					<Label>Date de naissance</Label>
					<div className="mt-1.5 flex">
						<span className="inline-flex items-center px-2.5 rounded-l-2xl border border-r-0 border-neutral-200 dark:border-neutral-700 bg-neutral-50 dark:bg-neutral-800 text-neutral-500 dark:text-neutral-400 text-sm">
							<i className="text-2xl las la-calendar"></i>
						</span>
						<Input
							className="!rounded-l-none"
							type="date"
							value={customer.dateBirth}
							onChange={({ target }) =>
								setCustomer({ ...customer, dateBirth: target.value })
							}
						/>
					</div>
				</div> */}
				{/* ---- */}
				{/* <div>
								<Label>Address</Label>
								<div className="mt-1.5 flex">
									<span className="inline-flex items-center px-2.5 rounded-l-2xl border border-r-0 border-neutral-200 dark:border-neutral-700 bg-neutral-50 dark:bg-neutral-800 text-neutral-500 dark:text-neutral-400 text-sm">
										<i className="text-2xl las la-map-signs"></i>
									</span>
									<Input
										className="!rounded-l-none"
									/>
								</div>
							</div> */}

				{/* ---- */}

				{/* ---- */}

				{/* ---- */}
				<div>
					<Label>Commentaire</Label>
					<Textarea
						className="mt-1.5"
						value={customer.otherInformations}
						onChange={({ target }) =>
							setCustomer({
								...customer,
								otherInformations: target.value,
							})
						}
					/>
				</div>
			</div>
		</div>
	);
};

export default CustomerForm;
