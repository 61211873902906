import Label from "components/Label/Label";
import toast from "react-hot-toast";
import Prices from "components/Prices";
import { Product } from "data/data";
import { useEffect, useState } from "react";
import { Helmet } from "react-helmet-async";
import { Link, useNavigate } from "react-router-dom";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import Input from "shared/Input/Input";
import ContactInfo from "./ContactInfo";
import PaymentMethod from "./PaymentMethod";
import { useDispatch, useSelector } from "react-redux";
import {
	removeFromcart,
	setOrder,
	setDiscountAmount,
	clearCart,
	setDeliveryFees,
	updateItem,
} from "redux/reducers/cart";
import ButtonSecondary from "shared/Button/ButtonSecondary";
import services from "utils/services";
// import ModalUploadPhotos from "components/ModalUploadPhotos";
import NotifyCreateOrder from "components/NotifyCreateOrder";
import dayjs from "dayjs";
import ShippingAddress from "./ShippingAddress";
import Checkbox from "shared/Checkbox/Checkbox";
import Select from "shared/Select/Select";
import { useAuth } from "provider/useAuth";
import ButtonClose from "shared/ButtonClose/ButtonClose";

const CheckoutPage = () => {
	const authContext = useAuth();
	const [tabActive, setTabActive] = useState<
		"ContactInfo" | "ShippingAddress" | "PaymentMethod" | null
	>("PaymentMethod");
	const [loading, setLoading] = useState<boolean>(false);
	const [discount, setDiscount] = useState<any>(false);
	const [shippingAmount, setShippingAmount] = useState<any>(0);
	const [usersList, setUsersList] = useState<any>([]);
	const [currentUser, setCurrentUser] = useState<any>(null);
	const [providers, setProviders] = useState<any>([]);
	const [selectedProvider, setSelectedProvider] = useState<any>(null);
	// const [modalShow, setModalShow] = useState<any>(false);
	// const [uploadItem, setUploadItem] = useState<any>(null);
	const cart = useSelector((state: any) => state.cart);
	const productStore = useSelector((state: any) => state.product);
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const handleScrollToEl = (id: string) => {
		const element = document.getElementById(id);
		setTimeout(() => {
			element?.scrollIntoView({ behavior: "smooth" });
		}, 80);
	};

	useEffect(() => {
		getUsers();
		getProviders();

		if (cart.items.some((it: any) => it.withDelivery)) {
			setTabActive("ShippingAddress");
			handleScrollToEl("ShippingAddress");
		} else {
			setTabActive("PaymentMethod");
			handleScrollToEl("PaymentMethod");
		}
	}, []);

	const getUsers = () => {
		services.getUsers(
			{ gallery: authContext.user._id, checkout: 1 },
			(success) => {
				setUsersList(success.users);
				setCurrentUser(
					success.users.length === 1 ? { _id: success.users[0]._id } : null
				);
			},
			(error) => console.error(error)
		);
	};

	const getProviders = () => {
		services.getProviders(
			{},
			(success) => {
				setProviders(success.providers);
			},
			(error) => console.error(error)
		);
	};

	const validateOrder = () => {
		services.saveOrder(
			{
				...cart,
				user: currentUser,
				provider: selectedProvider,
				orderDate: dayjs(cart.orderDate).format("YYYY-MM-DD HH:mm:ss"),
				status: "PAID",
			},
			(success) => {
				dispatch(setOrder({ order: { _id: success.order._id } }));
				dispatch(clearCart());
				//navigate("/commande/" + success.order._id);

				toast.custom(
					(t) => (
						<NotifyCreateOrder
							show={t.visible}
							reference={success.order.reference}
						/>
					),
					{ position: "top-right", id: "nc-product-notify", duration: 3000 }
				);

				navigate("/");
			},
			(error) => {
				console.error("order save error", error);
			}
		);
	};

	const applyDiscount = (value: number, amount = false) => {
		let discountAmount = value || 0;
		let discountPercent = value || 0;

		if (!amount) {
			discountAmount = (cart.totals.subTotal * value) / 100;
			if (cart.discountPercent == value) {
				discountAmount = 0;
				discountPercent = 0;
			}
		} else {
			discountPercent = Math.round((value / cart.totals.subTotal) * 100);
		}

		if (discountAmount >= cart.totals.subTotal) {
			discountAmount = cart.totals.subTotal;
			discountPercent = 100;
		}

		console.log({
			discountAmount,
			discountPercent,
		});

		dispatch(
			setDiscountAmount({
				discountAmount,
				discountPercent,
			})
		);
	};

	const renderProduct = (item: Product, index: number) => {
		const { price, name, support, dimension, effect, options } = item;
		const currentProduct = productStore.list.find((p: any) => p.name == name);

		return (
			<div className="">
				<div
					key={index}
					className="mt-2 mb-2 relative flex py-4 first:pt-0 last:pb-0"
				>
					{/* <div className="mb-2">
					<div className="relative h-36 w-24 sm:w-28 flex-shrink-0 overflow-hidden rounded-xl bg-slate-100 flex items-center justify-center">
						{item.photos &&
						item.photos.length &&
						item.photos[0] &&
						item.photos[0].thumb ? (
							<img
								className="w-32 h-32 rounded-lg object-cover z-0"
								src={
									"data:" +
									item.photos[0].contentType +
									";base64," +
									item.photos[0].thumb
								}
								alt=""
							/>
						) : (
							<div className="flex flex-col items-center">
								<svg
									xmlns="http://www.w3.org/2000/svg"
									fill="none"
									viewBox="0 0 24 24"
									strokeWidth={1.5}
									stroke="currentColor"
									className="w-10 h-10 z-0"
								>
									<path
										strokeLinecap="round"
										strokeLinejoin="round"
										d="M3 16.5v2.25A2.25 2.25 0 005.25 21h13.5A2.25 2.25 0 0021 18.75V16.5m-13.5-9L12 3m0 0l4.5 4.5M12 3v13.5"
									/>
								</svg>
							</div>
						)}
					</div>
					<div className="font-bold text-center">Upload</div>
					<Link
						to="#"
						onClick={() => {
							setUploadItem(item);
							setModalShow(true);
						}}
						className="absolute inset-0"
					></Link>
				</div> */}
					<div className="ml-3 sm:ml-6 flex flex-1 flex-col">
						<div>
							<div className="flex justify-between ">
								<div className="flex-[1.5] ">
									<h3 className="text-base font-semibold">
										<Link to="/product-detail">{name}</Link>
									</h3>
									<div className="mt-1.5 sm:mt-2.5 flex flex-col text-sm text-slate-600 dark:text-slate-300">
										<div className="flex items-center space-x-1.5">
											<span>{support ? support.name : ""}</span>
										</div>
										{!!dimension && (
											<>
												<span className="mx-4 border-l border-slate-200 dark:border-slate-700 "></span>
												<div className="flex items-center space-x-1.5">
													<span>
														{dimension.type ? dimension.type + " - " : ""}{" "}
														{dimension.size}
													</span>
												</div>
											</>
										)}
										{/* {!!effect && (
										<>
											<span className="mx-4 border-l border-slate-200 dark:border-slate-700 "></span>
											<div className="flex items-center space-x-1.5">
												<span>{effect}</span>
											</div>
										</>
									)} */}

										{!!options &&
											options.length > 0 &&
											options.map((o: any) => (
												<>
													<span className="mx-4 border-l border-slate-200 dark:border-slate-700 "></span>
													<div className="flex items-center space-x-1.5">
														<span>{o.name}</span>
													</div>
												</>
											))}
									</div>
								</div>
							</div>
						</div>
					</div>
					<div className="hidden flex flex-col sm:flex justify-end">
						<Prices price={price} className="mt-0.5 w-[120px] text-right" />
						<div className="mt-auto pt-4 items-center justify-end text-sm text-right">
							<a
								href="##"
								className="relative z-10 text-right mt-3 font-medium text-primary-6000 hover:text-primary-500 text-sm "
								onClick={() => dispatch(removeFromcart(item))}
							>
								<span>Supprimer</span>
							</a>
						</div>
					</div>
				</div>

				<div className="mt-3 mb-3">
					<Checkbox
						sizeClassName="h-5 w-5"
						name="free"
						label="Offert"
						defaultChecked={item.free}
						onChange={(checked) => {
							dispatch(
								updateItem({ timestamp: item.timestamp, free: checked })
							);
						}}
					/>
				</div>
			</div>
		);
	};

	const renderLeft = () => {
		return (
			<div className="space-y-8">
				<div id="ContactInfo" className="scroll-mt-24">
					<ContactInfo
						isActive={tabActive === "ContactInfo"}
						onOpenActive={() => {
							setTabActive("ContactInfo");
							handleScrollToEl("ContactInfo");
						}}
						onCloseActive={() => {
							if (cart.items.some((it: any) => it.withDelivery)) {
								setTabActive("ShippingAddress");
								handleScrollToEl("ShippingAddress");
							} else {
								setTabActive("PaymentMethod");
								handleScrollToEl("PaymentMethod");
							}
						}}
					/>
				</div>

				{cart.items.some((it: any) => it.withDelivery) && (
					<div id="ShippingAddress" className="scroll-mt-24">
						<ShippingAddress
							isActive={tabActive === "ShippingAddress"}
							onOpenActive={() => {
								setTabActive("ShippingAddress");
								handleScrollToEl("ShippingAddress");
							}}
							onCloseActive={() => {
								setTabActive("PaymentMethod");
								handleScrollToEl("PaymentMethod");
							}}
						/>
					</div>
				)}

				<div id="PaymentMethod" className="scroll-mt-24">
					<PaymentMethod
						isActive={tabActive === "PaymentMethod"}
						onOpenActive={() => {
							setTabActive("PaymentMethod");
							handleScrollToEl("PaymentMethod");
						}}
						onCloseActive={() => setTabActive(null)}
					/>
				</div>
			</div>
		);
	};

	return (
		<div className="nc-CheckoutPage">
			<Helmet>
				<title>Checkout || IRISGALERIE MAROC</title>
			</Helmet>

			{/* {modalShow && (
				<ModalUploadPhotos
					show={modalShow}
					onCloseModal={() => {
						setUploadItem(null);
						setModalShow(false);
					}}
					product={uploadItem}
					orderId={cart.order._id}
				/>
			)} */}
			<main className="container py-16 lg:pb-28 lg:pt-20 ">
				<div className="mb-16">
					<h2 className="block text-2xl sm:text-3xl lg:text-4xl font-semibold ">
						Checkout
					</h2>
					{/* <div className="block mt-3 sm:mt-5 text-xs sm:text-sm font-medium text-slate-700 dark:text-slate-400">
						<Link to={"/#"} className="">
							Homepage
						</Link>
						<span className="text-xs mx-1 sm:mx-1.5">/</span>
						<Link to={"/#"} className="">
							Clothing Categories
						</Link>
						<span className="text-xs mx-1 sm:mx-1.5">/</span>
						<span className="underline">Checkout</span>
					</div> */}
				</div>

				<div className="flex flex-col lg:flex-row">
					<div className="flex-1">
						<div className="flex flex-row mt-10 md:mt-0 space-y-1  flex-1 items-center mb-5 gap-3">
							<Label>Vendeur</Label>

							<Select
								className={
									"w-[250px] mt-1.5" + (!currentUser ? " border-danger" : "")
								}
								placeholder=""
								value={currentUser?._id}
								onChange={({ target }) => setCurrentUser({ _id: target.value })}
							>
								<option value=""></option>
								{usersList.map((g: any) => (
									<option value={g._id}>{g.firstName}</option>
								))}
							</Select>
						</div>
						{renderLeft()}
					</div>

					<div className="flex-shrink-0 border-t lg:border-t-0 lg:border-l border-slate-200 dark:border-slate-700 my-10 lg:my-0 lg:mx-10 xl:lg:mx-14 2xl:mx-16 "></div>

					<div className="w-full lg:w-[36%] ">
						<h3 className="text-lg font-semibold">
							Récapitulatif de la commande
						</h3>
						<p>
							<span className="text-xs">
								Date de commande:{" "}
								{dayjs(cart.orderDate).format("DD/MM/YYYY HH:mm")}
							</span>
						</p>
						{!!cart.referenceIRIS && (
							<p>
								<span className="text-xs">Réf. IRIS: {cart.referenceIRIS}</span>
							</p>
						)}
						{!!cart.numTicket && (
							<p>
								<span className="text-xs">Num. Ticket: {cart.numTicket}</span>
							</p>
						)}
						<div className="mt-8 divide-y divide-slate-200/70 dark:divide-slate-700 ">
							{cart.items.map(renderProduct)}
						</div>

						<ButtonSecondary
							href="/produits"
							className="mt-5 mb-5 w-full border border-slate-200 dark:border-slate-700"
						>
							Ajouter des produits
						</ButtonSecondary>
						{providers.lentgh > 0 && (
							<div className="flex flex-row mt-10 md:mt-0 space-y-1 flex-1 items-center mb-5 gap-3 pt-5  border-t  border-slate-200/70 dark:border-slate-700">
								<Label>Apporteur d'affaires</Label>

								<Select
									className={"w-[250px]"}
									placeholder=""
									value={selectedProvider?._id}
									onChange={({ target }) =>
										setSelectedProvider({ _id: target.value })
									}
								>
									<option value=""></option>
									{providers.map((p: any) => (
										<option value={p._id}>
											{p.lastName} {p.firstName}
										</option>
									))}
								</Select>
							</div>
						)}

						{cart.items.length > 0 && (
							<>
								<div className="mt-10 pt-6 text-sm text-slate-500 dark:text-slate-400 border-t border-slate-200/70 dark:border-slate-700 ">
									<div>
										<Label className="text-sm">Remise</Label>
										<div className="flex mt-1.5">
											{[5, 10, 15, 20, 30].map((r: number, index) => (
												<button
													className={
														"text-center border rounded-2xl px-4 py-1 ml-3 font-medium text-sm w-20 transition-colors " +
														(cart.discountPercent == r
															? "bg-slate-900 hover:bg-slate-800 text-slate-50 "
															: "text-neutral-700 border-neutral-200 hover:bg-neutral-100 bg-neutral-200/70")
													}
													onClick={() => applyDiscount(r)}
												>
													{r}%
												</button>
											))}
										</div>
									</div>
									<div className="mt-4 flex justify-between py-2.5">
										<span>Sous-total</span>
										<span className="font-semibold text-slate-900 dark:text-slate-200">
											{cart.totals.subTotal.toFixed(2)} MAD
										</span>
									</div>
									{cart.items.some((it: any) => it.withDelivery) && (
										<div className="flex justify-between py-2.5 items-center">
											<span>Frais de livraison</span>
											<div className="font-semibold text-slate-900 dark:text-slate-200 flex flex-row items-center ">
												<Input
													style={{
														width: "80px",
														textAlign: "right",
													}}
													sizeClass="h-10 px-2 py-2 mr-1"
													className="flex-1 font-semibold"
													value={cart.deliveryFees}
													onChange={({ target }) =>
														dispatch(
															setDeliveryFees({ deliveryFees: target.value })
														)
													}
												/>{" "}
												MAD
												{/* {cart.deliveryFees > 0
												? cart.deliveryFees.toFixed(2) + " MAD"
												: "Non défini"} */}
											</div>
										</div>
									)}
									{/* {cart.discountAmount > 0 && ( */}
									<div className="flex justify-between py-2.5 items-center">
										<span>Remise</span>
										<div className="font-semibold text-slate-900 dark:text-slate-200 flex flex-row items-center ">
											<Input
												style={{
													width: "150px",
													textAlign: "right",
												}}
												sizeClass="h-10 px-2 py-2 mr-1"
												className="flex-1 font-semibold"
												value={cart.discountAmount || 0}
												onChange={({ target }) =>
													applyDiscount(parseFloat(target.value) || 0, true)
												}
											/>
											<ButtonClose onClick={() => applyDiscount(0)} />
										</div>

										{/* <span className="font-semibold text-slate-900 dark:text-slate-200">
												- {parseFloat(cart.discountAmount).toFixed(2)} MAD
											</span> */}
									</div>
									{/* )} */}
									<div className="flex justify-between py-2.5">
										<span>Taxes</span>
										<span className="font-semibold text-slate-900 dark:text-slate-200">
											{cart.totals.totalTax.toFixed(2)} MAD
										</span>
									</div>
									<div className="flex justify-between font-semibold text-slate-900 dark:text-slate-200 text-base pt-4">
										<span>Total TTC</span>
										<span>
											{cart.totals
												? cart.totals.totalPrice.toFixed(2) + " MAD"
												: ""}
										</span>
									</div>
								</div>
								<ButtonPrimary
									onClick={() => {
										validateOrder();
									}}
									className="mt-8 w-full disabled:bg-opacity-50"
									disabled={
										!currentUser ||
										cart.items.length == 0 ||
										// !cart.customer.shippingAddress ||
										// !cart.customer.shippingAddress.address ||
										(!cart.paymentMode &&
											cart.items.some((it: any) => !it.free)) ||
										((cart.deliveryFees === "" ||
											cart.deliveryFees < 0 ||
											isNaN(cart.deliveryFees)) &&
											cart.items.some((it: any) => it.withDelivery))
										// cart.items.some(
										// 	(it: any) => !it.photos || !it.photos.length
										// )
									}
								>
									Confirmer la commande
								</ButtonPrimary>
							</>
						)}
						{/* <div className="mt-5 text-sm text-slate-500 dark:text-slate-400 flex items-center justify-center">
							<p className="block relative pl-5">
								<svg
									className="w-4 h-4 absolute -left-1 top-0.5"
									viewBox="0 0 24 24"
									fill="none"
								>
									<path
										d="M12 22C17.5 22 22 17.5 22 12C22 6.5 17.5 2 12 2C6.5 2 2 6.5 2 12C2 17.5 6.5 22 12 22Z"
										stroke="currentColor"
										strokeWidth="1.5"
										strokeLinecap="round"
										strokeLinejoin="round"
									/>
									<path
										d="M12 8V13"
										stroke="currentColor"
										strokeWidth="1.5"
										strokeLinecap="round"
										strokeLinejoin="round"
									/>
									<path
										d="M11.9945 16H12.0035"
										stroke="currentColor"
										strokeWidth="2"
										strokeLinecap="round"
										strokeLinejoin="round"
									/>
								</svg>
								Learn more{` `}
								<a
									target="_blank"
									rel="noopener noreferrer"
									href="##"
									className="text-slate-900 dark:text-slate-200 underline font-medium"
								>
									Taxes
								</a>
								<span>
									{` `}and{` `}
								</span>
								<a
									target="_blank"
									rel="noopener noreferrer"
									href="##"
									className="text-slate-900 dark:text-slate-200 underline font-medium"
								>
									Shipping
								</a>
								{` `} infomation
							</p>
						</div> */}
					</div>
				</div>
			</main>
		</div>
	);
};

export default CheckoutPage;
