import React, { useEffect } from "react";
import MyRouter from "routers/index";
import { Helmet, HelmetProvider } from "react-helmet-async";
import { useDispatch, useSelector } from "react-redux";
import services from "utils/services";
import { setOrder } from "redux/reducers/cart";
import dayjs from "dayjs";

function App() {
	const cart = useSelector((state: any) => state.cart);
	const dispatch = useDispatch();

	// useEffect(() => {
	// let mounted = true;
	// if (cart.items.length) {
	// 	services.saveOrder(
	// 		{
	// 			...cart,
	// 			orderDate: dayjs(cart.orderDate).format("YYYY-MM-DD HH:mm:ss"),
	// 		},
	// 		(success) => {
	// 			console.log("order saved!", success.order._id);
	// 			dispatch(setOrder({ order: { _id: success.order._id } }));
	// 		},
	// 		(error) => {
	// 			console.error("order save error", error);
	// 		}
	// 	);
	// }
	// 	return () => {
	// 		mounted = false;
	// 	};
	// }, [cart.updatedAt]);

	return (
		<HelmetProvider>
			<Helmet>
				<title>IRIS GALERIE MAROC</title>
				<meta name="description" content="IRIS GALERIE MAROC" />
			</Helmet>

			{/* MAIN APP */}
			<div className="bg-white text-base dark:bg-slate-900 text-slate-900 dark:text-slate-200">
				<MyRouter />
			</div>
		</HelmetProvider>
	);
}

export default App;
