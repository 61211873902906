import { Dialog, Transition } from "@headlessui/react";
import React, { FC, Fragment, useEffect, useState } from "react";
import ShareSelect from "shared/Select/Select";
import ButtonCircle from "shared/Button/ButtonCircle";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import ButtonSecondary from "shared/Button/ButtonSecondary";
import ButtonClose from "shared/ButtonClose/ButtonClose";
import ReactSelect from "react-select";
import { COUNTRY_LIST } from "contains/contants";
import Textarea from "shared/Textarea/Textarea";
import Label from "./Label/Label";
import { CheckIcon } from "@heroicons/react/24/solid";

export interface ModalDailyReportProps {
	show: boolean;
	data: any;
	onSend: (data: any) => void;
	onCloseModal: () => void;
}

const ModalDailyReport: FC<ModalDailyReportProps> = ({
	show,
	data,
	onSend,
	onCloseModal,
}) => {
	const [comment, setComment] = useState("");
	return (
		<Transition appear show={show} as={Fragment}>
			<Dialog
				as="div"
				className="fixed inset-0 z-50"
				onClose={() => {
					onCloseModal();
				}}
			>
				<div className="flex items-stretch md:items-center justify-center h-full text-center md:px-4">
					<Transition.Child
						as={Fragment}
						enter="ease-out duration-300"
						enterFrom="opacity-0"
						enterTo="opacity-100"
						leave="ease-in duration-200"
						leaveFrom="opacity-100"
						leaveTo="opacity-0"
					>
						<Dialog.Overlay className="fixed inset-0 bg-black/40 dark:bg-black/70" />
					</Transition.Child>

					{/* This element is to trick the browser into centering the modal contents. */}
					<span className="inline-block align-middle" aria-hidden="true">
						&#8203;
					</span>
					<Transition.Child
						as={Fragment}
						enter="ease-out duration-300"
						enterFrom="opacity-0 scale-95"
						enterTo="opacity-100 scale-100"
						leave="ease-in duration-200"
						leaveFrom="opacity-100 scale-100"
						leaveTo="opacity-0 scale-95"
					>
						<div className="relative inline-flex xl:py-8 w-full max-h-full w-[550px]">
							<div
								className="flex-1 flex overflow-hidden max-h-full p-8 w-full text-left align-middle transition-all transform lg:rounded-2xl bg-white 
				  dark:bg-neutral-900 dark:border dark:border-slate-700 dark:text-slate-100 shadow-xl max-h-[500px]"
							>
								<span className="absolute right-3 top-3 z-50">
									<ButtonClose onClick={onCloseModal} />
								</span>

								<div className="flex-1 overflow-y-auto rounded-xl hiddenScrollbar">
									<h3 className="text-xl font-semibold flex items-center">
										Rapport de la journée
									</h3>

									<div className="mt-5">
										<ul>
											<li className="">
												<span className="font-semibold">Total ventes : </span>
												{data.total} ventes -{" "}
												{parseFloat(data.totalCA).toFixed(2)} MAD
											</li>
											<li>
												<span className="font-semibold">Total vues : </span>
												{data.totalVues}
											</li>

											<li>
												<span className="font-semibold">
													Nombres photos prises :{" "}
												</span>
												{data.totalPhotos}
											</li>
										</ul>
										<br />
										{data.daySales.length > 0 && (
											<ul>
												<li className="underline font-semibold">
													Détails ventes :{" "}
												</li>
												{data.daySales.map(
													({ name, total, free }: any, index: number) => (
														<li key={index}>
															{name} {free ? " - Offert" : ""}
														</li>
													)
												)}
											</ul>
										)}
										<p className="mt-5">
											<span className="font-semibold">
												Montant espèces disponible :{" "}
											</span>
											{parseFloat(data.currentCash).toFixed(2)} MAD{" "}
											<span className="text-xs">
												(+{parseFloat(data.cashSales).toFixed(2)})
											</span>
										</p>

										<div className="mt-2">
											<Label>Commentaire:</Label>
											{data.dayReportSent ? (
												<p>
													{data.dayReportSent.data.comment ||
														"Pas de commentaire"}
												</p>
											) : (
												<Textarea
													className="mt-1.5"
													value={comment}
													onChange={({ target }) => setComment(target.value)}
												/>
											)}
										</div>
									</div>
									<div className="flex flex-col sm:flex-row pt-6 items-center justify-center">
										{data.dayReportSent ? (
											<>
												<CheckIcon className="h-5 w-5 mr-1" /> Rapport envoyé
											</>
										) : (
											<ButtonPrimary
												className="xl:py-1 py-1 sm:px-3 rounded-xl shadow-none sm:text-sm"
												onClick={() => {
													onSend({ ...data, comment });
												}}
											>
												Envoyer le rapport
											</ButtonPrimary>
										)}
										{/* <ButtonSecondary
											className="xl:py-1 py-1 sm:px-3 rounded-xl ml-3 bg-slate-200 sm:text-sm"
											onClick={() => {
												setIsEdit(false);
												editVues.forEach((c: any) => {
													c.delete = 0;
												});
												setEditVues([...editVues]);
											}}
										>
											Annuler
										</ButtonSecondary> */}
									</div>
								</div>
							</div>
						</div>
					</Transition.Child>
				</div>
			</Dialog>
		</Transition>
	);
};

export default ModalDailyReport;
