import api from "./api";

type CallbackFunction = (args: any) => void;

const getProducts = (
	params: object,
	successCallback: CallbackFunction,
	failureCallback: CallbackFunction
) => {
	api.get(
		"/products",
		{ params },
		(res) => {
			return successCallback(res);
		},
		(err) => {
			return failureCallback(err);
		}
	);
};

const saveCustomer = (
	data: any,
	successCallback: CallbackFunction,
	failureCallback: CallbackFunction
) => {
	api[data._id ? "patch" : "post"](
		"/customer" + (data._id ? "/" + data._id : ""),
		data,
		(res) => {
			return successCallback(res);
		},
		(err) => {
			return failureCallback(err);
		}
	);
};

const saveOrder = (
	data: any,
	successCallback: CallbackFunction,
	failureCallback: CallbackFunction
) => {
	api[data.order && data.order._id ? "patch" : "post"](
		"/order" + (data.order && data.order._id ? "/" + data.order._id : ""),
		data,
		(res) => {
			return successCallback(res);
		},
		(err) => {
			return failureCallback(err);
		}
	);
};

const getOrders = (
	params: object,
	successCallback: CallbackFunction,
	failureCallback: CallbackFunction
) => {
	api.get(
		"/orders",
		{ params },
		(res) => {
			return successCallback(res);
		},
		(err) => {
			return failureCallback(err);
		}
	);
};

const getOneOrder = (
	id: string,
	successCallback: CallbackFunction,
	failureCallback: CallbackFunction
) => {
	api.get(
		"/order/" + id,
		{},
		(res) => {
			return successCallback(res);
		},
		(err) => {
			return failureCallback(err);
		}
	);
};

const cancelOrder = (
	id: string,
	successCallback: CallbackFunction,
	failureCallback: CallbackFunction
) => {
	api.get(
		"/order/cancel/" + id,
		{},
		(res) => {
			return successCallback(res);
		},
		(err) => {
			return failureCallback(err);
		}
	);
};

const updateOrderStatus = (
	data: any,
	successCallback: CallbackFunction,
	failureCallback: CallbackFunction
) => {
	api.patch(
		"/order/status/" + data.id,
		data,
		(res) => {
			return successCallback(res);
		},
		(err) => {
			return failureCallback(err);
		}
	);
};

const getCustomers = (
	params: object,
	successCallback: CallbackFunction,
	failureCallback: CallbackFunction
) => {
	api.get(
		"/customers",
		{ params },
		(res) => {
			return successCallback(res);
		},
		(err) => {
			return failureCallback(err);
		}
	);
};

const getDeliveryFees = (
	data: any,
	successCallback: CallbackFunction,
	failureCallback: CallbackFunction
) => {
	api.post(
		"/order/calculateDeliveryFees",
		data,
		(res) => {
			return successCallback(res);
		},
		(err) => {
			return failureCallback(err);
		}
	);
};

const login = (
	data: any,
	successCallback: CallbackFunction,
	failureCallback: CallbackFunction
) => {
	api.post(
		"/login",
		data,
		(res) => {
			return successCallback(res);
		},
		(err) => {
			return failureCallback(err);
		}
	);
};

const getUsers = (
	params: object,
	successCallback: CallbackFunction,
	failureCallback: CallbackFunction
) => {
	api.get(
		"/users",
		{ params },
		(res) => {
			return successCallback(res);
		},
		(err) => {
			return failureCallback(err);
		}
	);
};

const getOneUser = (
	id: string | number,
	successCallback: CallbackFunction,
	failureCallback: CallbackFunction
) => {
	api.get(
		"/users/" + id,
		{},
		(res) => {
			return successCallback(res);
		},
		(err) => {
			return failureCallback(err);
		}
	);
};

const saveUser = (
	data: any,
	successCallback: CallbackFunction,
	failureCallback: CallbackFunction
) => {
	api[data._id ? "patch" : "post"](
		"/user" + (data._id ? "/" + data._id : ""),
		data,
		(res) => {
			return successCallback(res);
		},
		(err) => {
			return failureCallback(err);
		}
	);
};

const upload = (
	data: any,
	contentType: string,
	successCallback: CallbackFunction,
	failureCallback: CallbackFunction
) => {
	return api.post(
		"/order/upload",
		data,
		(res) => {
			return successCallback(res);
		},
		(err) => {
			return failureCallback(err);
		},
		{
			headers: {
				"Content-Type": contentType,
			},
		}
	);
};

const getStatitics = (
	params: object,
	successCallback: CallbackFunction,
	failureCallback: CallbackFunction
) => {
	api.get(
		"/statistics",
		{ params },
		(res) => {
			return successCallback(res);
		},
		(err) => {
			return failureCallback(err);
		}
	);
};

const saveCharges = (
	data: any,
	successCallback: CallbackFunction,
	failureCallback: CallbackFunction
) => {
	api.post(
		"/expenses",
		data,
		(res) => {
			return successCallback(res);
		},
		(err) => {
			return failureCallback(err);
		}
	);
};

const downloadPhoto = (
	id: string,
	params: any,
	successCallback: CallbackFunction,
	failureCallback: CallbackFunction
) => {
	api.get(
		"/order/download/" + id,
		{ params },
		(res) => {
			return successCallback(res);
		},
		(err) => {
			return failureCallback(err);
		}
	);
};

const saveVues = (
	data: any,
	successCallback: CallbackFunction,
	failureCallback: CallbackFunction
) => {
	api.post(
		"/order/vues",
		data,
		(res) => {
			return successCallback(res);
		},
		(err) => {
			return failureCallback(err);
		}
	);
};

const sendReport = (
	data: any,
	successCallback: CallbackFunction,
	failureCallback: CallbackFunction
) => {
	api.post(
		"/sendDailyReport",
		data,
		(res) => {
			return successCallback(res);
		},
		(err) => {
			return failureCallback(err);
		}
	);
};

const getStocks = (
	params: any,
	successCallback: CallbackFunction,
	failureCallback: CallbackFunction
) => {
	api.get(
		"/stocks",
		{ params },
		(res) => {
			return successCallback(res);
		},
		(err) => {
			return failureCallback(err);
		}
	);
};

const getGalleries = (
	params: any,
	successCallback: CallbackFunction,
	failureCallback: CallbackFunction
) => {
	api.get(
		"/galleries",
		{ params },
		(res) => {
			return successCallback(res);
		},
		(err) => {
			return failureCallback(err);
		}
	);
};

const getOneGallery = (
	id: string | number,
	successCallback: CallbackFunction,
	failureCallback: CallbackFunction
) => {
	api.get(
		"/gallery/" + id,
		{},
		(res) => {
			return successCallback(res);
		},
		(err) => {
			return failureCallback(err);
		}
	);
};

const saveGallery = (
	data: any,
	successCallback: CallbackFunction,
	failureCallback: CallbackFunction
) => {
	api[data._id ? "patch" : "post"](
		"/gallery" + (data._id ? "/" + data._id : ""),
		data,
		(res) => {
			return successCallback(res);
		},
		(err) => {
			return failureCallback(err);
		}
	);
};

const getProductStocks = (
	successCallback: CallbackFunction,
	failureCallback: CallbackFunction
) => {
	api.get(
		"/productStocks",
		{},
		(res) => {
			return successCallback(res);
		},
		(err) => {
			return failureCallback(err);
		}
	);
};

const updateGlobalStocks = (
	data: any,
	successCallback: CallbackFunction,
	failureCallback: CallbackFunction
) => {
	api.post(
		"/updateGlobalStocks",
		data,
		(res) => {
			return successCallback(res);
		},
		(err) => {
			return failureCallback(err);
		}
	);
};

const updateGalleryStocks = (
	data: any,
	successCallback: CallbackFunction,
	failureCallback: CallbackFunction
) => {
	api.post(
		"/updateGalleryStocks",
		data,
		(res) => {
			return successCallback(res);
		},
		(err) => {
			return failureCallback(err);
		}
	);
};
const sendIncident = (
	data: any,
	contentType: string,
	successCallback: CallbackFunction,
	failureCallback: CallbackFunction
) => {
	return api.post(
		"/incident",
		data,
		(res) => {
			return successCallback(res);
		},
		(err) => {
			return failureCallback(err);
		},
		{
			headers: {
				"Content-Type": contentType,
			},
		}
	);
};
const getCashFlow = (
	params: object,
	successCallback: CallbackFunction,
	failureCallback: CallbackFunction
) => {
	api.get(
		"/cashFlow",
		{ params },
		(res) => {
			return successCallback(res);
		},
		(err) => {
			return failureCallback(err);
		}
	);
};

const addCashOperation = (
	data: any,
	successCallback: CallbackFunction,
	failureCallback: CallbackFunction
) => {
	api.post(
		"/cashFlow",
		data,
		(res) => {
			return successCallback(res);
		},
		(err) => {
			return failureCallback(err);
		}
	);
};

const getProviders = (
	params: object,
	successCallback: CallbackFunction,
	failureCallback: CallbackFunction
) => {
	api.get(
		"/businessProviders",
		{ params },
		(res) => {
			return successCallback(res);
		},
		(err) => {
			return failureCallback(err);
		}
	);
};

const getOneProvider = (
	id: string | number,
	successCallback: CallbackFunction,
	failureCallback: CallbackFunction
) => {
	api.get(
		"/businessProviders/" + id,
		{},
		(res) => {
			return successCallback(res);
		},
		(err) => {
			return failureCallback(err);
		}
	);
};

const saveProvider = (
	data: any,
	successCallback: CallbackFunction,
	failureCallback: CallbackFunction
) => {
	api[data._id ? "patch" : "post"](
		"/businessProvider" + (data._id ? "/" + data._id : ""),
		data,
		(res) => {
			return successCallback(res);
		},
		(err) => {
			return failureCallback(err);
		}
	);
};

const services = {
	getProducts,
	getOrders,
	saveCustomer,
	saveOrder,
	getOneOrder,
	cancelOrder,
	getCustomers,
	getDeliveryFees,
	login,
	getUsers,
	getOneUser,
	saveUser,
	upload,
	getStatitics,
	saveCharges,
	downloadPhoto,
	updateOrderStatus,
	saveVues,
	sendReport,
	getProductStocks,
	getStocks,
	updateGlobalStocks,
	updateGalleryStocks,
	getGalleries,
	getOneGallery,
	saveGallery,
	sendIncident,
	getCashFlow,
	addCashOperation,
	getProviders,
	getOneProvider,
	saveProvider,
};
export default services;
