import { Dialog, Transition } from "@headlessui/react";
import React, { FC, Fragment, useEffect, useState } from "react";
import ShareSelect from "shared/Select/Select";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import ButtonClose from "shared/ButtonClose/ButtonClose";
import Textarea from "shared/Textarea/Textarea";
import Label from "./Label/Label";
import services from "utils/services";
import toast from "react-hot-toast";
import { useSelector } from "react-redux";

export interface ModalIncidentProps {
	show: boolean;
	onCloseModal: () => void;
}

const ModalIncident: FC<ModalIncidentProps> = ({ show, onCloseModal }) => {
	const auth = useSelector((state: any) => state.auth);
	const [products, setProducts] = useState([]);
	const [image, setImage] = useState<any>(null);
	const [comment, setComment] = useState("");
	const [selectedProduct, setSelectedProduct] = useState("");

	useEffect(() => {
		services.getProductStocks(
			(res) => {
				setProducts(res.productStocks);
			},
			(err) => {
				console.error(err);
			}
		);
	}, []);

	const send = () => {
		let data: any = {
			product: selectedProduct,
			comment,
			gallery: auth.user._id,
		};
		let contentType = "application/json";
		if (image) {
			contentType = "multipart/form-data";
			data = new FormData();
			data.append("product", selectedProduct);
			data.append("comment", comment);
			data.append("image", image);
			data.append("gallery", auth.user._id);
		}

		services.sendIncident(
			data,
			contentType,
			(success) => {
				toast.success("Email envoyé !", {
					position: "top-center",
					id: "nc-product-notify",
					duration: 3000,
					className: "border-1 border-green-500 font-semibold",
					style: { borderWidth: "1px" },
				});

				onCloseModal();
			},
			(error) => {}
		);
	};

	return (
		<Transition appear show={show} as={Fragment}>
			<Dialog
				as="div"
				className="fixed inset-0 z-50"
				onClose={() => {
					onCloseModal();
				}}
			>
				<div className="flex items-stretch md:items-center justify-center h-full text-center md:px-4">
					<Transition.Child
						as={Fragment}
						enter="ease-out duration-300"
						enterFrom="opacity-0"
						enterTo="opacity-100"
						leave="ease-in duration-200"
						leaveFrom="opacity-100"
						leaveTo="opacity-0"
					>
						<Dialog.Overlay className="fixed inset-0 bg-black/40 dark:bg-black/70" />
					</Transition.Child>

					{/* This element is to trick the browser into centering the modal contents. */}
					<span className="inline-block align-middle" aria-hidden="true">
						&#8203;
					</span>
					<Transition.Child
						as={Fragment}
						enter="ease-out duration-300"
						enterFrom="opacity-0 scale-95"
						enterTo="opacity-100 scale-100"
						leave="ease-in duration-200"
						leaveFrom="opacity-100 scale-100"
						leaveTo="opacity-0 scale-95"
					>
						<div className="relative inline-flex xl:py-8 w-full max-h-full w-[500px]">
							<div
								className="flex-1 flex overflow-hidden max-h-full p-8 w-full text-left align-middle transition-all transform lg:rounded-2xl bg-white 
				  dark:bg-neutral-900 dark:border dark:border-slate-700 dark:text-slate-100 shadow-xl max-h-[500px]"
							>
								<span className="absolute right-3 top-3 z-50">
									<ButtonClose onClick={onCloseModal} />
								</span>

								<div className="flex-1 overflow-y-auto rounded-xl hiddenScrollbar">
									<h3 className="text-xl font-semibold flex items-center">
										Produit détérioré
									</h3>

									<div className="mt-5">
										<div>
											<Label>Produit :</Label>
											<ShareSelect
												sizeClass="h-10"
												className="shadow-lg border-[#6b7280] rounded-md mt-3"
												value={selectedProduct}
												onChange={({ target }: { target: any }) => {
													setSelectedProduct(target.value);
												}}
												placeholder="Choisir un produit"
											>
												<option value=""></option>
												{products.map((p: any, ind: number) => (
													<option value={p.name} key={ind}>
														{p.name}
													</option>
												))}
											</ShareSelect>
										</div>
										<div className="mt-3">
											<Label>Photo de l'objet :</Label>
											<input
												type="file"
												className=""
												onChange={({ target }) => {
													if (target.files && target.files.length > 0) {
														setImage(target.files[0]);
													}
												}}
											/>
										</div>
										<div className="mt-3">
											<Label>Commentaire:</Label>
											<Textarea
												className="mt-1.5 border-[#6b7280] rounded-md"
												value={comment}
												onChange={({ target }) => setComment(target.value)}
											/>
										</div>
									</div>
									<div className="flex flex-col sm:flex-row pt-6 items-center justify-center">
										<ButtonPrimary
											className="xl:py-1 py-1 sm:px-3 rounded-xl shadow-none sm:text-sm"
											onClick={() => {
												send();
											}}
										>
											Envoyer le mail
										</ButtonPrimary>
									</div>
								</div>
							</div>
						</div>
					</Transition.Child>
				</div>
			</Dialog>
		</Transition>
	);
};

export default ModalIncident;
