import { createSlice } from "@reduxjs/toolkit";

const initialState: {
	token: string | null;
	refreshToken: string | null;
	user: any;
	filters: any;
} = {
	token: null,
	user: null,
	refreshToken: null,
	filters: null,
};

export const authSlice = createSlice({
	name: "auth",
	initialState,
	reducers: {
		saveUser: (state, action) => {
			state.token = action.payload.token;
			state.refreshToken = action.payload.refreshToken;
			if (action.payload.user) {
				state.user = action.payload.user;
			}
		},
		logout: (state) => {
			state.token = null;
			state.refreshToken = null;
			state.user = null;
			state.filters = null;
		},
		saveFilters: (state, action) => {
			state.filters = action.payload.filters;
		},
	},
});

// Action creators are generated for each case reducer function
export const { saveUser, logout, saveFilters } = authSlice.actions;

export default authSlice.reducer;
