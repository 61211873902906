import Prices from "components/Prices";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import { useDispatch, useSelector } from "react-redux";
import { fillCart } from "redux/reducers/cart";
import services from "utils/services";
import dayjs from "dayjs";
import {
	ArrowLeftIcon,
	ArrowUpTrayIcon,
	CheckIcon,
} from "@heroicons/react/24/solid";
import ModalAlert from "components/ModalAlert";
import ModalUploadPhotos from "components/ModalUploadPhotos";
import { Link } from "react-router-dom";
import Button from "shared/Button/Button";
import { useAuth } from "provider/useAuth";
import Select from "shared/Select/Select";
import { Product } from "data/data";
const STATUSES: any[] = [
	{ label: "En attente", value: "PENDING" },
	{ label: "Payé", value: "PAID" },
	{
		label: "En cours de traitement",
		value: "PROCESSING",
	},
	{ label: "Envoyé", value: "SHIPPED" },
	{ label: "Livrée", value: "DELIVERED" },
	{ label: "Annulée", value: "CANCELED" },
];

const ViewOrderPage = () => {
	const authContext = useAuth();

	const [loading, setLoading] = useState<boolean>(false);
	const [order, setOrder] = useState<any>({});
	const [orderStatus, setOrderStatus] = useState<any>("");
	const [showAlert, setShowAlert] = useState<any>(false);
	const [showMessage, setShowMessage] = useState<any>(false);
	const [modalUploadShow, setModalUploadShow] = useState<any>(false);
	const [uploadItem, setUploadItem] = useState<any>(null);

	const productStore = useSelector((state: any) => state.product);

	const navigate = useNavigate();
	const dispatch = useDispatch();

	const { id } = useParams();
	useEffect(() => {
		if (id) {
			getOrder(id);
		}
	}, []);

	const getOrder = (id: string) => {
		services.getOneOrder(
			id,
			(success) => {
				success.order.totals = success.totals;
				setOrder(success.order);
				setOrderStatus(success.order.status);

				setLoading(false);
			},
			(error) => {
				setLoading(false);
			}
		);
	};

	const continueOrder = (order: any) => {
		dispatch(fillCart(order));
		navigate("/produits");
	};

	const cancelOrder = (order: any) => {
		setShowAlert(false);
		setLoading(true);
		services.cancelOrder(
			order._id,
			(success) => {
				getOrder(order._id);
				setShowMessage(success.message);
			},
			(error) => {
				setLoading(false);
				console.error("order save error", error);
			}
		);
	};

	const updateOrderStatus = () => {
		// setShowAlert(false);
		setLoading(true);
		services.updateOrderStatus(
			{ id: order._id, status: orderStatus },
			(success) => {
				// getOrder(order._id);
				// setShowMessage(success.message);
				setOrder(success.order);
				setLoading(false);
			},
			(error) => {
				setLoading(false);
				console.error("order save error", error);
			}
		);
	};

	const renderOrderItem = (item: any, index: number) => {
		const { price, name, support, dimension, effect, product, options, free } =
			item;

		const currentProduct = productStore.list.find((p: any) => p.name == name);
		return (
			<div key={index} className="flex py-4 sm:py-7 last:pb-0 first:pt-0">
				<div>
					<div className="relative rounded-lg overflow-hidden flex w-24 h-24 items-center justify-center border-slate-500 border">
						{item.photos &&
						item.photos.length > 0 &&
						item.photos.find((p: any) => p.typePhoto == "final")?.thumb ? (
							<img
								className=" rounded-lg object-cover z-0"
								src={
									"data:" +
									item.photos.find((p: any) => p.typePhoto == "final")
										.mimetype +
									";base64," +
									item.photos.find((p: any) => p.typePhoto == "final").thumb
								}
								alt=""
							/>
						) : (
							<img
								className=" rounded-lg object-cover z-0"
								src={process.env.REACT_APP_API_URL + "/" + currentProduct.image}
								alt=""
							/>
						)}
					</div>

					{order.status != "PENDING" && (
						<div className="text-center">
							<Button
								className="text-sm bg-white border-[1px] border-slate-500 text-slate-900 mt-1 hover:border-slate-700 hover:bg-neutral-200"
								fontSize="text-sm font-medium text-black"
								sizeClass="py-2 px-3"
								onClick={() => {
									setUploadItem(item);
									setModalUploadShow(true);
								}}
							>
								<ArrowUpTrayIcon
									className="w-4 h-4 mr-2 -ml-1"
									aria-hidden="true"
								/>
								Upload
							</Button>
							{/* <Link
								to="#"
								onClick={() => {
									setUploadItem(item);
									setModalUploadShow(true);
								}}
								className="font-bold text-center"
							>
								Upload
							</Link> */}
						</div>
					)}
				</div>

				<div className="ml-4 flex flex-1 flex-col">
					<div>
						<div className="flex justify-between ">
							<div className="min-w-[150px]">
								<h3 className="text-base font-medium line-clamp-1">{name}</h3>
								<p className="mt-1 text-sm text-slate-500 dark:text-slate-400">
									<span>{support ? support.name : ""}</span>
								</p>
								{!!dimension && !!dimension.size && (
									<p className="mt-1 text-sm text-slate-500 dark:text-slate-400">
										<span>
											{dimension.type ? dimension.type + " - " : ""}{" "}
											{dimension.size}
										</span>
									</p>
								)}
								{/* {!!effect && (
									<p className="mt-1 text-sm text-slate-500 dark:text-slate-400">
										<span>{effect}</span>
									</p>
								)} */}
								{!!options &&
									options.length > 0 &&
									options.map((o: any) => (
										<p className="mt-1 text-sm text-slate-500 dark:text-slate-400">
											<span>{o.name}</span>
										</p>
									))}
							</div>
							<Prices className="mt-0.5 ml-2" price={price} free={free} />
						</div>
					</div>
					<div className="flex flex-1 items-end justify-between text-sm">
						{/* <p className="text-gray-500 dark:text-slate-400 flex items-center">
							<span className="hidden sm:inline-block">Qty</span>
							<span className="inline-block sm:hidden">x</span>
							<span className="ml-2">1</span>
						</p> */}
					</div>
				</div>
			</div>
		);
	};

	const renderOrder = () => {
		if (order._id) {
			return (
				<div className="border border-slate-200 dark:border-slate-700 rounded-lg overflow-hidden z-0">
					<div className="flex flex-col sm:flex-row sm:justify-start sm:gap-5  p-4 sm:p-8 bg-slate-50 dark:bg-slate-500/5">
						<div>
							<p className="text-sm font-semibold">
								#{order.reference} - {order.customer.lastName}{" "}
								{order.customer.firstName}
							</p>
							<p className="text-sm">
								{order.customer.email} - {order.customer.telephone}
							</p>
							<p className="text-sm">{order.customer.country}</p>
							<p className="text-slate-500 dark:text-slate-400 text-sm mt-1.5 sm:mt-2">
								<span>{dayjs(order.orderDate).format("DD/MM/YYYY")}</span>
								<span className="mx-2">·</span>
								<span className="text-primary-500">{order.paymentMode}</span>
								<span className="mx-2">·</span>
								<span className="text-green-500 !leading-none font-bold">
									{order.totalPrice.toFixed(2)} MAD
								</span>
							</p>
							{!!order.customer.otherInformations && (
								<>
									<p className="text-sm font-semibold">Commentaire</p>
									<p className="text-slate-500 dark:text-slate-400 text-sm mt-1.5 sm:mt-2">
										{order.customer.otherInformations}
									</p>
								</>
							)}
						</div>

						{!!order.shippingAddress && (
							<>
								<div className="mx-2 border-l border-slate-400 dark:border-slate-700 h-12"></div>

								<div>
									<p className="text-sm font-semibold">Adresse de livraison</p>
									<p className="text-slate-500 dark:text-slate-400 text-sm mt-1.5 sm:mt-2">
										{order.shippingAddress.formatted_address}
									</p>
								</div>
							</>
						)}

						<div className="mx-2 border-l border-slate-400 dark:border-slate-700 h-12"></div>
						<div className="">
							<p className="text-sm font-semibold">Statut de la commande</p>
							<div className="flex flex-row">
								{!!authContext.user && authContext.user.role == "ADMIN" ? (
									<>
										<Select
											name="status"
											id="status"
											value={orderStatus}
											className="form-select text-sm rounded-md py-1 border-slate-200 dark:border-slate-700 relative z-10 dark:bg-slate-800 "
											onChange={({ target }) => {
												setOrderStatus(target.value);
											}}
										>
											{STATUSES.map((s: any, ind: number) => (
												<option value={s.value} key={ind}>
													{s.label}
												</option>
											))}
										</Select>
										<ButtonPrimary
											sizeClass="px-3 py-1"
											fontSize="text-sm"
											className="bg-slate-900 ml-1"
											disabled={order.status == orderStatus}
											onClick={() => {
												updateOrderStatus();
											}}
										>
											<CheckIcon className="w-5 h-5" />
										</ButtonPrimary>
									</>
								) : (
									<span className="text-primary-500 font-bold">
										{STATUSES.find((s) => s.value == order.status).label}
									</span>
								)}
							</div>
						</div>
						{order.status == "PENDING" && (
							<div className="flex flex-1 justify-end">
								<div className="flex flex-col gap-5">
									<ButtonPrimary
										className="py-2 px-4 sm:py-2 sm:px-3 text-sm "
										onClick={() => continueOrder(order)}
									>
										Continuer la commande
									</ButtonPrimary>
									{/* <ButtonPrimary
										className="py-2 px-4 sm:py-2 sm:px-3 text-sm bg-danger"
										onClick={() => setShowAlert(true)}
									>
										Annuler la commande
									</ButtonPrimary> */}
								</div>
							</div>
						)}
						{order.status != "PENDING" && (
							<div className="flex flex-1 justify-end">
								<div className="flex flex-col gap-5">
									<ButtonPrimary
										className="py-2 px-4 sm:py-2 sm:px-3 text-sm bg-danger"
										onClick={() => setShowAlert(true)}
									>
										Demande d'annulation
									</ButtonPrimary>
								</div>
							</div>
						)}
					</div>
					<div className="border-t border-b border-slate-200 dark:border-slate-700 p-2 sm:p-8 divide-y divide-y-slate-200 dark:divide-slate-700">
						{order.products.map(renderOrderItem)}
					</div>
					<div className="border-slate-200 flex flex-row sm:flex-row sm:justify-start sm:gap-5 p-4 sm:p-8 bg-slate-50 dark:bg-slate-500/5">
						<div className="lg:w-[75%] md:w-[50%] w-[0]"></div>
						{order.products.some((p: Product) => !p.free) && (
							<div className="flex flex-row lg:[25%] md:w-[50%] w-[100%] gap-3">
								<div className="flex flex-col flex-1">
									{order.discountAmount > 0 && (
										<p className="text-sm font-semibold">
											Remise ({order.discountPercent} %)
										</p>
									)}
									{order.deliveryFees > 0 && (
										<p className="text-sm font-semibold">Frais de livraison</p>
									)}

									<p className="text-md font-semibold">TOTAL</p>
									<p className="text-sm font-semibold">Total HT</p>
									<p className="text-sm font-semibold">TVA (20%)</p>
								</div>
								<div className="flex flex-col  flex-1 items-end">
									{order.discountAmount > 0 && (
										<p className="text-sm font-semibold">
											{order.discountAmount.toFixed(2)} MAD
										</p>
									)}
									{order.deliveryFees > 0 && (
										<p className="text-sm font-semibold">
											{order.deliveryFees} MAD
										</p>
									)}

									<p className="text-md font-semibold">
										{order.totalPrice.toFixed(2)} MAD
									</p>

									<p className="text-sm font-semibold">
										{order.totals.totalHT.toFixed(2)} MAD
									</p>

									<p className="text-sm font-semibold">
										{order.totals.totalTax.toFixed(2)} MAD
									</p>
								</div>
							</div>
						)}
					</div>
				</div>
			);
		}
	};

	return (
		<div className="container">
			<div className="space-y-10 sm:space-y-12">
				<div className="flex mt-auto pt-4 items-end justify-between text-sm">
					<a
						className="relative z-10 flex items-center mt-3 font-medium text-primary-6000 hover:text-primary-500 text-sm cursor-pointer"
						onClick={() => navigate(-1)}
					>
						<ArrowLeftIcon className="w-4 h-4" /> <span>Retour</span>
					</a>
				</div>
				<div className="mt-14 sm:mt-20">{renderOrder()}</div>
			</div>

			{showAlert && (
				<ModalAlert
					onCloseModal={() => setShowAlert(false)}
					onConfirm={() => cancelOrder(order)}
					confirmText="Oui"
					closeText="Non"
					title="Annulation"
					text="Êtes vous sûr de vouloir annuler cette commande"
					show={showAlert}
				/>
			)}

			{!!showMessage && (
				<ModalAlert
					onCloseModal={() => setShowMessage(false)}
					closeText="OK"
					title=""
					text={showMessage}
					show={!!showMessage}
				/>
			)}

			{modalUploadShow && (
				<ModalUploadPhotos
					show={modalUploadShow}
					onCloseModal={() => {
						setUploadItem(null);
						setModalUploadShow(false);
						getOrder(id || "");
					}}
					product={uploadItem}
					orderId={order._id}
				/>
			)}
		</div>
	);
};

export default ViewOrderPage;
