import React, { FC, useEffect, useState } from "react";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import BagIcon from "components/BagIcon";
import Prices from "components/Prices";
import toast from "react-hot-toast";
import NotifyAddTocart from "components/NotifyAddTocart";
import Select from "shared/Select/Select";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
	addToCart,
	setOrderDate,
	setReferenceIRIS,
	setNumTicket,
} from "redux/reducers/cart";
import Input from "shared/Input/Input";
import dayjs from "dayjs";

export interface ProductDetailPageProps {
	className?: string;
}

const ProductDetailPage: FC<ProductDetailPageProps> = ({ className = "" }) => {
	const navigate = useNavigate();

	const [dimensionActive, setDimensionActive] = React.useState("");
	const [supportActive, setSupportActive] = React.useState("");
	const [effectActive, setEffectActive] = React.useState("");
	const [optionsActive, setOptionsActive] = React.useState<any>([]);
	const [dimensionsChoices, setDimensionsChoices] = React.useState([]);
	const [supportsChoices, setSupportsChoices] = React.useState([]);

	const cart = useSelector((state: any) => state.cart);
	const productStore = useSelector((state: any) => state.product);
	const dispatch = useDispatch();
	const { name } = useParams();

	const currentProduct = productStore.list.find((p: any) => p.name == name);

	useEffect(() => {
		populateChoices();
		setOptionsActive([]);
	}, [supportActive]);

	useEffect(() => {
		const combination = getCombination();
		if (
			combination &&
			(!combination.options.length ||
				optionsActive.some(
					(o: any) =>
						!combination.options.find((co: any) => o.typeOption == co.type)
				))
		) {
			setOptionsActive([]);
		}
	}, [dimensionActive]);

	const populateChoices = () => {
		const dimensions: any = [];
		const supports: any = [];

		let combinations = currentProduct.combinations;

		combinations.forEach((comb: any) => {
			if (!supports.find((s: any) => s.code == comb.support.code)) {
				supports.push(comb.support);
			}
		});

		if (supportActive) {
			combinations = combinations.filter(
				(c: any) => c.support.code == supportActive
			);
		}

		combinations.forEach((comb: any) => {
			if (
				comb.dimension.size &&
				!dimensions.find(
					(d: any) =>
						d.type == comb.dimension.type && d.size == comb.dimension.size
				)
			) {
				dimensions.push(comb.dimension);
			}
		});

		if (
			!dimensions.find(
				(d: any) => (d.type ? d.type + " - " : "") + d.size == dimensionActive
			)
		) {
			setDimensionActive("");
			setOptionsActive([]);
		}
		setDimensionsChoices(dimensions);
		setSupportsChoices(supports);
	};

	const addTocart = () => {
		const combination = getCombination();
		if (combination) {
			// toast.custom(
			// 	(t) => (
			// 		<NotifyAddTocart
			// 			productImage={
			// 				process.env.REACT_APP_API_URL + "/" + currentProduct.image
			// 			}
			// 			effectSelected={effectActive}
			// 			show={t.visible}
			// 			supportSelected={combination.support.name}
			// 			dimensionSelected={dimensionActive}
			// 			price={getPrice() || 0}
			// 		/>
			// 	),
			// 	{ position: "top-right", id: "nc-product-notify", duration: 3000 }
			// );

			dispatch(
				addToCart({
					product: { _id: currentProduct._id },
					name: currentProduct.name,
					support: combination.support,
					dimension: combination.dimension,
					effect: effectActive,
					price: getPrice() || 0,
					options: optionsActive,
					withDelivery: combination.withDelivery,
				})
			);

			if (supportActive == "VUE") {
				navigate("/");
			} else {
				navigate("/checkout");
			}
		}
	};

	const renderVariants = () => {
		if (!supportsChoices || !supportsChoices.length) {
			return null;
		}

		return (
			<div>
				<label htmlFor="">
					<span className="text-sm font-medium">
						Support:
						{/* <span className="ml-1 font-semibold">
							{variants[variantActive].name}
						</span> */}
					</span>
				</label>
				<div className="flex mt-3">
					<Select
						className="mt-1.5"
						value={supportActive}
						onChange={({ target }) => {
							setSupportActive(target.value);
						}}
					>
						<option></option>
						{supportsChoices.map((s: any, index: number) => (
							<option value={s.code} key={index}>
								{s.name}
							</option>
						))}
					</Select>
				</div>
			</div>
		);
	};

	const renderSizeList = () => {
		if (!dimensionsChoices || !dimensionsChoices.length) {
			return null;
		}
		return (
			<div>
				<div className="flex justify-between font-medium text-sm">
					<label htmlFor="">
						<span className="">
							Dimension:
							{/* <span className="ml-1 font-semibold">{sizeSelected}</span> */}
						</span>
					</label>
					{/* <a
						target="_blank"
						rel="noopener noreferrer"
						href="##"
						className="text-primary-6000 hover:text-primary-500"
					>
						See sizing chart
					</a> */}
				</div>
				<div className="grid grid-cols-1 sm:grid-cols-1 gap-2 mt-3">
					<Select
						className="mt-1.5"
						value={dimensionActive}
						onChange={({ target }) => {
							setDimensionActive(target.value);
						}}
						// disabled={!supportActive}
					>
						<option></option>
						{dimensionsChoices.map((d: any, index: number) => (
							<option
								value={(d.type ? d.type + " - " : "") + d.size}
								key={index}
							>
								{d.type ? d.type + " -" : null} {d.size}
							</option>
						))}
					</Select>
				</div>
			</div>
		);
	};

	const renderEffectList = () => {
		return (
			<div>
				<div className="flex justify-between font-medium text-sm">
					<label htmlFor="">
						<span className="">
							Effet:
							{/* <span className="ml-1 font-semibold">{sizeSelected}</span> */}
						</span>
					</label>
				</div>
				<div className="grid grid-cols-4 gap-2 mt-3">
					{["AUCUN", "DUST", "EXPLOSION", "COLLISION", "HALO"].map(
						(effect, index) => {
							const isActive = effect === effectActive;
							return (
								<div
									key={index}
									className={`relative h-10 sm:h-11 rounded-2xl border flex items-center justify-center 
                text-sm sm:text-base uppercase font-semibold select-none overflow-hidden z-0 cursor-pointer
								${
									isActive
										? "bg-primary-6000 border-primary-6000 text-white hover:bg-primary-6000"
										: "border-slate-300 dark:border-slate-600 text-slate-900 dark:text-slate-200 hover:bg-neutral-50 dark:hover:bg-neutral-700"
								}`}
									onClick={() => {
										setEffectActive(effect);
									}}
								>
									{effect}
								</div>
							);
						}
					)}
				</div>
			</div>
		);
	};

	const renderOptionsList = () => {
		const combination = getCombination();
		if (!combination) {
			return null;
		}
		return (
			<div>
				{combination.options.length > 0 &&
					combination.options.map((option: any, index: any) => {
						return (
							<div>
								<div className="flex justify-between font-medium text-md my-3">
									<label htmlFor="">
										<span className="">{option.name}</span>
									</label>
								</div>
								<div className="grid grid-cols-4 gap-2 mt-3">
									{!option.mandatory && (
										<div
											className={`relative h-11 sm:h-11 rounded-2xl border flex items-center justify-center 
                text-sm sm:text-base uppercase font-semibold select-none overflow-hidden z-0 cursor-pointer
								${
									!optionsActive.find((o: any) => o.typeOption == option.type)
										? "bg-primary-6000 border-primary-6000 text-white hover:bg-primary-6000"
										: "border-slate-300 dark:border-slate-600 text-slate-900 dark:text-slate-200 hover:bg-neutral-50 dark:hover:bg-neutral-700"
								}`}
											onClick={() => {
												setOptionsActive(
													optionsActive.filter(
														(o: any) => o.typeOption != option.type
													)
												);
											}}
										>
											SANS
										</div>
									)}
									{option.choices.map((choice: any, index: any) => {
										const isActive =
											choice.name ===
											optionsActive.find(
												(o: any) => o.typeOption == option.type
											)?.name;
										return (
											<div
												key={index}
												className={`relative h-12 sm:h-11 rounded-2xl border flex items-center justify-center 
                text-sm sm:text-base uppercase font-semibold select-none overflow-hidden z-0 cursor-pointer
								${
									isActive
										? "bg-primary-6000 border-primary-6000 text-white hover:bg-primary-6000"
										: "border-slate-300 dark:border-slate-600 text-slate-900 dark:text-slate-200 hover:bg-neutral-50 dark:hover:bg-neutral-700"
								}`}
												onClick={() => {
													//setEffectActive(option);
													const curOptInd = optionsActive.findIndex(
														(o: any) => o.typeOption == option.type
													);
													if (curOptInd !== -1) {
														optionsActive[curOptInd] = {
															...choice,
															typeOption: option.type,
														};
													} else {
														optionsActive.push({
															...choice,
															typeOption: option.type,
														});
													}

													setOptionsActive([...optionsActive]);
												}}
											>
												{choice.name}
											</div>
										);
									})}
								</div>
							</div>
						);
					})}
				<div>
					<div className="flex justify-between font-medium text-md my-3">
						<label htmlFor="">
							<span className="">Personnalisation</span>
						</label>
					</div>
					<div className="grid grid-cols-4 gap-2 mt-3">
						<div
							className={`relative h-11 sm:h-11 rounded-2xl border flex items-center justify-center 
                text-sm sm:text-base uppercase font-semibold select-none overflow-hidden z-0 cursor-pointer
								${
									!optionsActive.find(
										(o: any) => o.typeOption == "personnalized"
									)
										? "bg-primary-6000 border-primary-6000 text-white hover:bg-primary-6000"
										: "border-slate-300 dark:border-slate-600 text-slate-900 dark:text-slate-200 hover:bg-neutral-50 dark:hover:bg-neutral-700"
								}`}
							onClick={() => {
								setOptionsActive(
									optionsActive.filter(
										(o: any) => o.typeOption != "personnalized"
									)
								);
							}}
						>
							NON
						</div>
						<div
							className={`relative h-12 sm:h-11 rounded-2xl border flex items-center justify-center 
                text-sm sm:text-base uppercase font-semibold select-none overflow-hidden z-0 cursor-pointer
								${
									optionsActive.find(
										(o: any) => o.typeOption == "personnalized"
									)
										? "bg-primary-6000 border-primary-6000 text-white hover:bg-primary-6000"
										: "border-slate-300 dark:border-slate-600 text-slate-900 dark:text-slate-200 hover:bg-neutral-50 dark:hover:bg-neutral-700"
								}`}
							onClick={() => {
								//setEffectActive(option);
								optionsActive.push({
									price: combination.personalizationPrice,
									type: "personnalized",
									typeOption: "personnalized",
									name: "+Personnalisation",
								});

								setOptionsActive([...optionsActive]);
							}}
						>
							OUI
						</div>
					</div>
				</div>
			</div>
		);
	};
	// const renderStatus = () => {
	// 	if (!status) {
	// 		return null;
	// 	}
	// 	const CLASSES =
	// 		"absolute top-3 left-3 px-2.5 py-1.5 text-xs bg-white dark:bg-slate-900 nc-shadow-lg rounded-full flex items-center justify-center text-slate-700 text-slate-900 dark:text-slate-300";
	// 	if (status === "New in") {
	// 		return (
	// 			<div className={CLASSES}>
	// 				<SparklesIcon className="w-3.5 h-3.5" />
	// 				<span className="ml-1 leading-none">{status}</span>
	// 			</div>
	// 		);
	// 	}
	// 	if (status === "50% Discount") {
	// 		return (
	// 			<div className={CLASSES}>
	// 				<IconDiscount className="w-3.5 h-3.5" />
	// 				<span className="ml-1 leading-none">{status}</span>
	// 			</div>
	// 		);
	// 	}
	// 	if (status === "Sold Out") {
	// 		return (
	// 			<div className={CLASSES}>
	// 				<NoSymbolIcon className="w-3.5 h-3.5" />
	// 				<span className="ml-1 leading-none">{status}</span>
	// 			</div>
	// 		);
	// 	}
	// 	if (status === "limited edition") {
	// 		return (
	// 			<div className={CLASSES}>
	// 				<ClockIcon className="w-3.5 h-3.5" />
	// 				<span className="ml-1 leading-none">{status}</span>
	// 			</div>
	// 		);
	// 	}
	// 	return null;
	// };

	const getCombination = () => {
		let combination = currentProduct.combinations.find((c: any) => {
			return (
				c.support.code == supportActive &&
				(!dimensionsChoices.length ||
					(c.dimension.type ? c.dimension.type + " - " : "") +
						c.dimension.size ==
						dimensionActive)
			);
		});

		return combination;
	};

	const getPrice = () => {
		let combination = getCombination();
		let price = 0;
		if (combination) {
			price = combination.price;
			optionsActive.forEach((o: any) => (price += o.price || 0));
		}

		return combination ? price : null;
	};
	const renderSectionContent = () => {
		return (
			<div className="space-y-7 2xl:space-y-6">
				{/* ---------- 1 HEADING ----------  */}
				<div>
					<h2 className="text-2xl sm:text-3xl font-semibold">
						{currentProduct.name}
					</h2>
				</div>
				<div className="">
					<label htmlFor="">
						<span className="text-sm font-medium">
							Date de commande:{" "}
							<Input
								className=" rounded-md !rounded-l-none"
								type="date"
								value={dayjs(cart.orderDate).format("YYYY-MM-DD")}
								onChange={({ target }) => {
									dispatch(setOrderDate(target.value));
								}}
							/>
						</span>
					</label>
				</div>
				{/* ---------- 3 VARIANTS AND SIZE LIST ----------  */}
				{currentProduct.combinations.length > 1 && (
					<>
						<div className="">{renderVariants()}</div>
						<div className="">{renderSizeList()}</div>
					</>
				)}
				{/* <div className="">{renderEffectList()}</div> */}
				{supportActive && (dimensionActive || !dimensionsChoices.length) ? (
					<div className="">{renderOptionsList()}</div>
				) : null}

				{supportActive == "AD" || supportActive == "AC" ? (
					<div className="flex gap-5">
						<div className="flex flex-col w-[50%]">
							<div className="font-medium text-sm">
								<label htmlFor="">
									<span className="">Réference IRIS</span>
								</label>
							</div>
							<div className="f">
								<Input
									className="mt-1.5"
									value={cart.referenceIRIS}
									onChange={({ target }) =>
										dispatch(setReferenceIRIS(target.value))
									}
								/>
							</div>
						</div>
						<div className="flex flex-col w-[50%]">
							<div className="font-medium text-sm">
								<label htmlFor="">
									<span className="">Num. Ticket</span>
								</label>
							</div>
							<div className="font-medium text-sm">
								<Input
									className="mt-1.5"
									value={cart.numTicket}
									onChange={({ target }) =>
										dispatch(setNumTicket(target.value))
									}
								/>
							</div>
						</div>
					</div>
				) : null}

				{/*  ---------- 4  QTY AND ADD TO CART BUTTON */}
				{(currentProduct.combinations.length <= 1 ||
					(!!supportActive &&
						(!!dimensionActive || !dimensionsChoices.length))) && (
					<Prices
						contentClass="py-1 px-2 md:py-1.5 md:px-3 text-xl font-semibold border-0 justify-end text-right"
						price={getPrice()}
					/>
				)}
				<div className="flex space-x-3.5">
					<ButtonPrimary
						className="flex-1 flex-shrink-0"
						onClick={addTocart}
						disabled={
							currentProduct.combinations.length > 1 &&
							(!supportActive ||
							(!dimensionActive && dimensionsChoices.length) ||
							((supportActive == "AD" || supportActive == "AC") &&
								(!cart.referenceIRIS || !cart.numTicket))
								? true
								: false)
						}
					>
						<BagIcon className="hidden sm:inline-block w-5 h-5 mb-0.5" />
						<span className="ml-3">
							{supportActive == "VUE" ? "Valider" : "Checkout"}
						</span>
					</ButtonPrimary>
				</div>
			</div>
		);
	};

	// const renderDetailSection = () => {
	// 	return (
	// 		<div className="">
	// 			<h2 className="text-2xl font-semibold">Product Details</h2>
	// 			<div className="prose prose-sm sm:prose dark:prose-invert sm:max-w-4xl mt-7">
	// 				<p>
	// 					The patented eighteen-inch hardwood Arrowhead deck --- finely
	// 					mortised in, makes this the strongest and most rigid canoe ever
	// 					built. You cannot buy a canoe that will afford greater satisfaction.
	// 				</p>
	// 				<p>
	// 					The St. Louis Meramec Canoe Company was founded by Alfred Wickett in
	// 					1922. Wickett had previously worked for the Old Town Canoe Co from
	// 					1900 to 1914. Manufacturing of the classic wooden canoes in Valley
	// 					Park, Missouri ceased in 1978.
	// 				</p>
	// 				<ul>
	// 					<li>Regular fit, mid-weight t-shirt</li>
	// 					<li>Natural color, 100% premium combed organic cotton</li>
	// 					<li>
	// 						Quality cotton grown without the use of herbicides or pesticides -
	// 						GOTS certified
	// 					</li>
	// 					<li>Soft touch water based printed in the USA</li>
	// 				</ul>
	// 			</div>
	// 		</div>
	// 	);
	// };

	if (!currentProduct) {
		return null;
	}
	return (
		<div className={`nc-ProductDetailPage ${className}`}>
			{/* MAIn */}
			<main className="container mt-5 lg:mt-11">
				<div className="lg:flex">
					{/* CONTENT */}
					<div className="w-full lg:w-[30%] w-[40%]">
						{/* HEADING */}
						<div className="relative">
							<div className="aspect-w-16 aspect-h-16">
								<img
									src={
										process.env.REACT_APP_API_URL + "/" + currentProduct.image
									}
									className="w-full rounded-2xl object-cover"
									alt={currentProduct.name}
								/>
							</div>
							{/* {renderStatus()} */}
							{/* META FAVORITES */}
							{/* <LikeButton className="absolute right-3 top-3 " /> */}
						</div>
						{/* <div className="grid grid-cols-2 gap-3 mt-3 sm:gap-6 sm:mt-6 xl:gap-8 xl:mt-8">
							{[LIST_IMAGES_DEMO[1], LIST_IMAGES_DEMO[2]].map((item, index) => {
								return (
									<div
										key={index}
										className="aspect-w-11 xl:aspect-w-10 2xl:aspect-w-11 aspect-h-16"
									>
										<img
											src={item}
											className="w-full rounded-2xl object-cover"
											alt="product detail 1"
										/>
									</div>
								);
							})}
						</div> */}
					</div>

					{/* SIDEBAR */}
					<div className="w-full lg:w-[45%] lg:w-[60%] pt-10 pb-10 lg:pt-0 lg:pl-7 xl:pl-9 2xl:pl-10">
						{renderSectionContent()}
					</div>
				</div>

				{/* DETAIL AND REVIEW */}
				{/* <div className="mt-12 sm:mt-16 space-y-10 sm:space-y-16"> */}
				{/* <div className="block xl:hidden">
						<Policy />
					</div> */}

				{/* {renderDetailSection()} */}

				{/* <hr className="border-slate-200 dark:border-slate-700" /> */}
				{/* </div> */}
			</main>
		</div>
	);
};

export default ProductDetailPage;
