import { ArrowDownTrayIcon } from "@heroicons/react/24/solid";
import React, { FC, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import ButtonSecondary from "shared/Button/ButtonSecondary";
import Input from "shared/Input/Input";
import NcModal from "shared/NcModal/NcModal";
import Select from "shared/Select/Select";
import services from "utils/services";

export interface ModalUploadPhotosProps {
	show: boolean;
	orderId: string;
	product: any;
	onCloseModal: () => void;
}

const nbPhotos: any = { SOLO: 2, DUO: 3, TRIO: 4, QUATUOR: 5, QUINTUOR: 6 };

const ModalUploadPhotos: FC<ModalUploadPhotosProps> = ({
	show,
	product,
	orderId,
	onCloseModal,
}) => {
	const [photos, setPhotos] = useState<any>([]);
	const [loading, setLoading] = useState(false);
	const [downloading, setDownloading] = useState<boolean | string>(false);

	useEffect(() => {
		if (photos.length < nbPhotos[product.name]) {
			for (let i = photos.length; i < nbPhotos[product.name]; i++) {
				let typePhoto = "";
				if (i < nbPhotos[product.name] - 1) {
					typePhoto = "iris" + (i + 1);
				} else {
					typePhoto = "final";
				}

				photos.push(
					product.photos.find((p: any) => p.typePhoto == typePhoto) || {
						typePhoto,
					}
				);
				// photos.push({ typePhoto: "final" });
			}

			setPhotos([...photos]);
		}
	}, []);

	// const addPhotos = () => {
	// 	photos.push({});
	// 	setPhotos([...photos]);
	// };

	const checkPhotos = () => {
		let errors = false;

		if (photos.some((p: any) => !p.thumb && !p.ufile)) {
			errors = true;
		}

		if (
			product.name != "SOLO" &&
			photos.some((p: any) => p.typePhoto != "final" && !p.name)
		) {
			errors = true;
		}

		return errors;
	};
	const onUpload = async () => {
		const errors = checkPhotos();

		if (errors) {
			return;
		}

		try {
			setLoading(true);
			for (const p of photos) {
				let formData = new FormData();
				let data: { orderId: string; product: any; photo: any } = {
					orderId,
					product: product.timestamp,
					photo: null,
				};
				if (!p.ufile) {
					// photos.forEach((p: any) => {
					data.photo = {
						filename: p.filename,
						name: p.name,
						typePhoto: p.typePhoto,
					};
					// });
				} else {
					// Update the formData object
					formData.append("orderId", orderId);
					formData.append("product", product.timestamp);
					formData.append("typePhoto", p.typePhoto);
					formData.append("name", p.name);
					formData.append("photo", p.ufile);

					// Details of the uploaded file
					// console.log(file);
				}
				await services.upload(
					!p.ufile ? data : formData,
					!p.ufile ? "application/json" : "multipart/form-data",
					(success) => {
						console.log(success);
					},
					(error) => {
						console.error(error);
					}
				);
			}

			setLoading(false);
			onCloseModal();
		} catch (error) {
			setLoading(false);
			console.error(error);
		}
	};

	const downloadImg = (photo: any) => {
		setDownloading(photo.typePhoto);
		services.downloadPhoto(
			orderId,
			{
				productId: product._id,
				filename: photo.filename,
				typePhoto: photo.typePhoto,
			},
			(success) => {
				console.log(success);
				setDownloading(false);

				var a = document.createElement("a"); //Create <a>
				a.href = "data:" + success.contentType + ";base64," + success.file; //Image Base64 Goes here
				a.download = success.filename;
				a.click();
			},
			(error) => {
				setDownloading(false);
			}
		);
	};

	const renderContent = () => {
		return (
			<form action="#">
				<h3 className=" text-center text-lg font-semibold text-neutral-900 dark:text-neutral-200">
					Upload
				</h3>
				<div className="flex-shrink-0 flex items-start justify-center mt-5 gap-3 flex-wrap">
					{photos.map((p: any, index: any) => (
						<div className="flex flex-col items-center w-40">
							<div
								className={
									"relative rounded-lg overflow-hidden flex flex-col w-40 h-40 items-center justify-center border " +
									(!p.ufile && !p.thumb ? "border-danger" : "border-slate-600")
								}
								key={index}
							>
								{p && p.ufile ? (
									<img
										className="w-32 h-32 rounded-lg object-cover z-0"
										src={URL.createObjectURL(p.ufile)}
										alt=""
									/>
								) : p && p.thumb ? (
									<img
										className="w-32 h-32 rounded-lg object-cover z-0"
										src={"data:" + p.contentType + ";base64," + p.thumb}
										alt=""
									/>
								) : (
									<svg
										xmlns="http://www.w3.org/2000/svg"
										fill="none"
										viewBox="0 0 24 24"
										strokeWidth={1.5}
										stroke="currentColor"
										className="w-10 h-10 z-0"
									>
										<path
											strokeLinecap="round"
											strokeLinejoin="round"
											d="M3 16.5v2.25A2.25 2.25 0 005.25 21h13.5A2.25 2.25 0 0021 18.75V16.5m-13.5-9L12 3m0 0l4.5 4.5M12 3v13.5"
										/>
									</svg>
								)}

								<input
									type="file"
									className="absolute inset-0 opacity-0 cursor-pointer"
									onChange={({ target }) => {
										if (target.files && target.files.length > 0) {
											photos[index] = {
												ufile: target.files[0],
												typePhoto: p.typePhoto,
												name: p.name,
											};
											setPhotos([...photos]);
											// setFile(target.files[0]);
										}
									}}
								/>
							</div>
							<span
								className="mt-1.5 border-slate-600 disabled:text-slate-900"
								// value={p.typePhoto}
								// onChange={({ target }) => {
								// 	photos[index].typePhoto = target.value;
								// 	setPhotos([...photos]);
								// }}
							>
								{/* <option value="final"> */}
								{p.typePhoto == "final"
									? "Fichier à imprimer"
									: "IRIS " + (index + 1)}
								{/* </option> */}
							</span>
							{product.name != "SOLO" && index < photos.length - 1 && (
								<Input
									className={
										"mt-1.5 " + (!p.name ? "border-danger" : "border-slate-600")
									}
									value={p.name}
									onChange={({ target }) => {
										photos[index].name = target.value;
										setPhotos([...photos]);
									}}
								/>
							)}

							{!!p && !!p.thumb && (
								<div className="text-center">
									<Link
										to="#"
										onClick={() => {
											downloadImg(p);
										}}
										className="text-center flex items-center underline py-1 px-1 rounded-lg"
									>
										<ArrowDownTrayIcon
											className="w-4 h-4 mr-2 -ml-1"
											aria-hidden="true"
										/>{" "}
										Download {downloading == p.typePhoto && _renderLoading()}
									</Link>
								</div>
							)}

							{/* {index > 0 && index == photos.length - 1 && (
								<div className="text-center">
									<Link
										to="#"
										onClick={() => {
											photos.pop();
											setPhotos([...photos]);
										}}
										className="text-sm text-danger border-0 py-1 px-2"
									>
										- Supprimer
									</Link>
								</div>
							)} */}
						</div>
					))}
				</div>

				{/* {photos.length < 10 && (
					<div className="text-center">
						<button type="button" onClick={() => addPhotos()}>
							<svg
								xmlns="http://www.w3.org/2000/svg"
								fill="none"
								viewBox="0 0 24 24"
								strokeWidth={1.5}
								stroke="currentColor"
								className="w-10 h-10 z-0"
							>
								<path
									strokeLinecap="round"
									strokeLinejoin="round"
									d="M12 9v6m3-3H9m12 0a9 9 0 11-18 0 9 9 0 0118 0z"
								/>
							</svg>
						</button>
					</div>
				)} */}
				<div className="mt-8 space-x-3 text-center">
					<ButtonPrimary
						type="button"
						disabled={checkPhotos() || loading}
						onClick={() => onUpload()}
						loading={loading}
					>
						Charger
					</ButtonPrimary>
					<ButtonSecondary type="button" onClick={onCloseModal}>
						Annuler
					</ButtonSecondary>
				</div>
			</form>
		);
	};

	const _renderLoading = () => {
		return (
			<svg
				className="animate-spin -ml-1 mr-3 h-4 w-4 ml-1"
				xmlns="http://www.w3.org/2000/svg"
				fill="none"
				viewBox="0 0 24 24"
			>
				<circle
					className="opacity-25"
					cx="12"
					cy="12"
					r="10"
					stroke="currentColor"
					strokeWidth="3"
				></circle>
				<path
					className="opacity-75"
					fill="currentColor"
					d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
				></path>
			</svg>
		);
	};

	const renderTrigger = () => {
		return null;
	};

	return (
		<NcModal
			isOpenProp={show}
			onCloseModal={onCloseModal}
			contentExtraClass="w-[60%] h-[50%]"
			renderContent={renderContent}
			renderTrigger={renderTrigger}
			modalTitle=""
		/>
	);
};

export default ModalUploadPhotos;
