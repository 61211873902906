import React, { FC } from "react";

export interface PricesProps {
	className?: string;
	price?: number | null;
	contentClass?: string;
	free?: boolean;
}

const Prices: FC<PricesProps> = ({
	className = "",
	price,
	contentClass = "py-1 px-2 md:py-1.5 md:px-2.5 text-sm font-medium",
	free = false,
}) => {
	return (
		<div className={`${className}`}>
			<div
				className={`flex items-center border-2 border-green-500 rounded-lg ${contentClass}`}
			>
				<span className="flex items-center gap-1 text-green-500 !leading-none">
					{!!free && (
						<svg
							xmlns="http://www.w3.org/2000/svg"
							fill="none"
							viewBox="0 0 24 24"
							strokeWidth="1.5"
							stroke="currentColor"
							className="w-5 h-5"
						>
							<path
								strokeLinecap="round"
								strokeLinejoin="round"
								d="M21 11.25v8.25a1.5 1.5 0 0 1-1.5 1.5H5.25a1.5 1.5 0 0 1-1.5-1.5v-8.25M12 4.875A2.625 2.625 0 1 0 9.375 7.5H12m0-2.625V7.5m0-2.625A2.625 2.625 0 1 1 14.625 7.5H12m0 0V21m-8.625-9.75h18c.621 0 1.125-.504 1.125-1.125v-1.5c0-.621-.504-1.125-1.125-1.125h-18c-.621 0-1.125.504-1.125 1.125v1.5c0 .621.504 1.125 1.125 1.125Z"
							/>
						</svg>
					)}
					{free ? "Offert" : price ? price.toFixed(2) + " MAD" : null}
				</span>
			</div>
		</div>
	);
};

export default Prices;
