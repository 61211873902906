import React from "react";
import { FC } from "react";
import { NavLink } from "react-router-dom";

export interface CommonLayoutProps {
	children?: React.ReactNode;
	customer?: any;
}

const CommonLayout: FC<CommonLayoutProps> = ({ children, customer }) => {
	return (
		<div className="nc-CommonLayoutProps container">
			<div className="mt-5 sm:mt-10">
				{!!customer && !!customer.id && (
					<div className="max-w-4xl mx-auto">
						<div className="max-w-2xl">
							<h2 className="text-3xl xl:text-4xl font-semibold">Account</h2>
							<span className="block mt-4 text-neutral-500 dark:text-neutral-400 text-base sm:text-lg">
								<span className="text-slate-900 dark:text-slate-200 font-semibold">
									{customer.lastName}
								</span>{" "}
								{customer.email}
							</span>
						</div>

						<hr className="mt-10 border-slate-200 dark:border-slate-700"></hr>

						<div className="flex space-x-8 md:space-x-14 overflow-x-auto hiddenScrollbar">
							{[
								{
									name: "Informations client",
									link: "/client",
								},
								// {
								// 	name: "Save lists",
								// 	link: "/account-savelists",
								// },
								{
									name: "Commandes",
									link: "/account-my-order",
								},
								// {
								//   name: "Change password",
								//   link: "/account-change-password",
								// },
								// {
								//   name: "Change Billing",
								//   link: "/account-billing",
								// },
							].map((item, index) => (
								<NavLink
									key={index}
									to={item.link}
									className={({ isActive }) =>
										`block py-5 md:py-8 border-b-2 border-transparent flex-shrink-0  text-sm sm:text-base ${
											isActive
												? "border-primary-500 font-medium text-slate-900 dark:text-slate-200"
												: "text-slate-500 dark:text-slate-400 hover:text-slate-800 dark:hover:text-slate-200"
										}`
									}
								>
									{item.name}
								</NavLink>
							))}
						</div>
						<hr className="border-slate-200 dark:border-slate-700"></hr>
					</div>
				)}
			</div>
			<div className="max-w-4xl mx-auto pt-14 sm:pt-26 pb-24 lg:pb-32">
				{children}
			</div>
		</div>
	);
};

export default CommonLayout;
