import React, { FC } from "react";

export interface HeaderProps {}

const Header: FC<HeaderProps> = () => {
	return (
		<div className="nc-Header relative w-full z-40 ">{/* <MainNav2 /> */}</div>
	);
};

export default Header;
