import { Dialog, Transition } from "@headlessui/react";
import React, { FC, Fragment, useEffect, useState } from "react";
import ShareSelect from "shared/Select/Select";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import ButtonSecondary from "shared/Button/ButtonSecondary";
import ButtonClose from "shared/ButtonClose/ButtonClose";
import ReactSelect from "react-select";
import { COUNTRY_LIST } from "contains/contants";

export interface ModalListeVuesProps {
	show: boolean;
	vues: any[];
	onSave: (vues: any[]) => void;
	onCloseModal: () => void;
}

const ModalListeVues: FC<ModalListeVuesProps> = ({
	show,
	vues,
	onSave,
	onCloseModal,
}) => {
	const [isEdit, setIsEdit] = useState(false);
	const [editVues, setEditVues] = useState<any[]>([]);

	useEffect(() => {
		const edit = vues.map((c: any) => {
			return { ...c };
		});

		if (edit.length < 10) {
			for (let i = edit.length; i < 10; i++) {
				edit.push({ customerName: "", productName: "", nbPhotos: 0 });
			}
		}

		setEditVues(edit);
	}, []);
	return (
		<Transition appear show={show} as={Fragment}>
			<Dialog
				as="div"
				className="fixed inset-0 z-50"
				onClose={() => {
					vues.forEach((c: any) => {
						c.delete = 0;
					});
					setEditVues([...vues]);
					onCloseModal();
				}}
			>
				<div className="flex items-stretch md:items-center justify-center h-full text-center md:px-4">
					<Transition.Child
						as={Fragment}
						enter="ease-out duration-300"
						enterFrom="opacity-0"
						enterTo="opacity-100"
						leave="ease-in duration-200"
						leaveFrom="opacity-100"
						leaveTo="opacity-0"
					>
						<Dialog.Overlay className="fixed inset-0 bg-black/40 dark:bg-black/70" />
					</Transition.Child>

					{/* This element is to trick the browser into centering the modal contents. */}
					<span className="inline-block align-middle" aria-hidden="true">
						&#8203;
					</span>
					<Transition.Child
						as={Fragment}
						enter="ease-out duration-300"
						enterFrom="opacity-0 scale-95"
						enterTo="opacity-100 scale-100"
						leave="ease-in duration-200"
						leaveFrom="opacity-100 scale-100"
						leaveTo="opacity-0 scale-95"
					>
						<div className="relative inline-flex xl:py-8 w-full max-h-full w-[700px]">
							<div
								className="flex-1 flex overflow-hidden max-h-full p-8 w-full text-left align-middle transition-all transform lg:rounded-2xl bg-white 
				  dark:bg-neutral-900 dark:border dark:border-slate-700 dark:text-slate-100 shadow-xl max-h-[700px]"
							>
								<span className="absolute right-3 top-3 z-50">
									<ButtonClose onClick={onCloseModal} />
								</span>

								<div className="flex-1 overflow-y-auto rounded-xl hiddenScrollbar">
									<h3 className="text-xl font-semibold flex items-center">
										VUES{" "}
									</h3>
									<div className="flex flex-row justify-center items-center mt-2.5 mb-1 gap-3">
										<p className="w-[30%]">Nom</p>
										<p className="w-[30%]">Pays</p>
										<p className="w-[30%]">Nb. Iris traités</p>
									</div>
									{editVues
										.filter((ch: any) => !ch.delete)
										.map((vue: any, ind) => (
											<div
												className="flex flex-row items-center justify-center mt-2.5 mb-1 gap-3"
												key={ind}
											>
												<span className="">{ind + 1} </span>

												<input
													className="shadow-lg rounded-sm w-[30%] text-sm"
													type="text"
													value={vue.customerName}
													placeholder="Nom du client"
													onChange={({ target }) => {
														vue.customerName = target.value;
														setEditVues([...editVues]);
													}}
												/>
												<ReactSelect
													styles={{
														control: (baseStyles, state) => ({
															...baseStyles,
															borderColor: "#6b7280",
															borderRadius: "0",
														}),
													}}
													className={"border-black rounded-none w-[30%]"}
													classNames={{
														control: (state) =>
															"border-1 border-black rounded-none border-neutral-200",
													}}
													value={{ value: vue.country, label: vue.country }}
													onChange={(choice) => {
														vue.country = choice?.value;
														setEditVues([...editVues]);
													}}
													placeholder="Pays"
													options={COUNTRY_LIST.map((c) => {
														return {
															value: c.name,
															label: c.name,
														};
													})}
												/>
												<ShareSelect
													sizeClass="h-10"
													className="shadow-lg w-[30%] border-[#6b7280] rounded-none"
													value={vue.nbPhotos}
													onChange={({ target }: { target: any }) => {
														vue.nbPhotos = target.value;
														setEditVues([...editVues]);
													}}
													// disabled={!supportActive}
													placeholder="Nb. iris traités"
												>
													<option value=""></option>
													{[1, 2, 3, 4, 5, 6, 7, 8].map(
														(i: number, ind: number) => (
															<option value={i} key={ind}>
																{i}
															</option>
														)
													)}
												</ShareSelect>
												{/* <ShareSelect
													sizeClass="h-10"
													className="shadow-lg w-[30%] border-[#6b7280] rounded-none"
													value={vue.productName}
													onChange={({ target }: { target: any }) => {
														vue.productName = target.value;
														setEditVues([...editVues]);
													}}
													// disabled={!supportActive}
													placeholder="Type de produit"
												>
													<option value="">Produit</option>
													<option value="SOLO">SOLO</option>
													<option value="DUO">DUO</option>
													<option value="TRIO">TRIO</option>
													<option value="QUATUOR">QUATUOR</option>
													<option value="QUINTUOR">QUINTUOR</option>
												</ShareSelect> */}
												{ind > 10 && (
													<button
														className={`flex items-center justify-center rounded-lg bg-red-400 hover:bg-red-600 text-slate-50 w-4 h-4 `}
														onClick={() => {
															// ch.delete = 1;
															editVues.find(
																(c: any) => c._id == vue._id
															).delete = 1;

															setEditVues([...editVues]);
														}}
													>
														-
													</button>
												)}
											</div>
										))}
									<div className="flex mt-2">
										<ButtonPrimary
											className="xl:py-1 py-1 xl:px-3 sm:px-3 rounded-xl shadow-none sm:text-sm bg-slate-600"
											onClick={() => {
												editVues.push({
													customerName: "",
													productName: "",
													nbPhotos: 0,
												});
												setEditVues([...editVues]);
											}}
										>
											+ Ajouter
										</ButtonPrimary>
									</div>
									<div className="flex flex-col sm:flex-row pt-6 items-center justify-center">
										<ButtonPrimary
											className="xl:py-1 py-1 sm:px-3 rounded-xl shadow-none sm:text-sm"
											onClick={() => {
												onSave(
													editVues.filter(
														(v) => !v.delete && v.customerName && v.nbPhotos
													)
												);
											}}
										>
											Valider
										</ButtonPrimary>
										<ButtonSecondary
											className="xl:py-1 py-1 sm:px-3 rounded-xl ml-3 bg-slate-200 sm:text-sm"
											onClick={() => {
												onCloseModal();
											}}
										>
											Annuler
										</ButtonSecondary>
									</div>
								</div>
							</div>
						</div>
					</Transition.Child>
				</div>
			</Dialog>
		</Transition>
	);
};

export default ModalListeVues;
