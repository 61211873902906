import React, { FC, useEffect, useState } from "react";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import { Helmet } from "react-helmet-async";
import UserForm from "components/Forms/UserForm";
import { useNavigate, useParams } from "react-router-dom";
import services from "utils/services";
import { Link } from "react-router-dom";
import { string, mixed, object } from "yup";

export interface UserEditProps {
	className?: string;
}

const UserEdit: FC<UserEditProps> = ({ className = "" }) => {
	const [user, setUser] = useState<any>({});
	const [isEdit, setIsEdit] = useState<boolean>(false);
	const [loading, setLoading] = useState<boolean>(false);
	const [errors, setErrors] = useState<any>({});
	const [galleryList, setGalleryList] = useState<any>([]);

	const getGalleries = () => {
		services.getGalleries(
			{},
			(success) => {
				setGalleryList(success.galleries);
			},
			(error) => console.error(error)
		);
	};

	const navigate = useNavigate();

	const getUserSchema = () => {
		return object({
			firstName: string().required(""),
			lastName: string().required(""),
			username: string().required(""),
			// password: user._id ? string().notRequired() : string().required(""),
			// email: string().required("").email("Email invalide"),
			// role: mixed().oneOf(["ADMIN", "ASSISTANT", "MANAGER"], "").required(""),
		});
	};

	const { userId } = useParams();
	useEffect(() => {
		getGalleries();
		if (userId) {
			services.getOneUser(
				userId,
				(success) => {
					setUser(success.user);
					setIsEdit(true);
				},
				(error) => {}
			);
		}
	}, []);

	const checkFields = () => {
		let fieldErrors: any = {};
		try {
			getUserSchema().validateSync(user, { abortEarly: false });
			setErrors({});
			return true;
		} catch (err: any) {
			console.log(err);
			err.inner?.forEach((oneErr: { path: string; message: string }) => {
				fieldErrors[oneErr.path] = oneErr.message;
			});
			setErrors(fieldErrors);
			return false;
		}
	};

	const saveUser = () => {
		const isValid = checkFields();
		if (isValid) {
			setLoading(true);
			services.saveUser(
				user,
				(success) => {
					navigate(-1);
					setLoading(false);
				},
				(error) => {
					setLoading(false);

					if (error.response.status == 422) {
						// errors.email = error.response.data.message;
					} else {
						// errors.global = "Une erreur est survenue";
					}

					// setErrors({ ...errors });
					console.log(error);
				}
			);
		}
	};

	return (
		<div className={`nc-Edit ${className} container`} data-nc-id="Edit">
			<Helmet>
				<title>Utilisateur || IRIS GALERIE MAROC</title>
			</Helmet>

			<div className="mt-10 flex flex-col sm:w-[70%] justify-center ml-auto mr-auto">
				<Link
					onClick={() => navigate(-1)}
					className="w-10 h-10 sm:w-12 sm:h-12 hover:bg-slate-100 dark:hover:bg-slate-800 rounded-full  flex items-center justify-center text-right"
					to={"#"}
				>
					<svg
						xmlns="http://www.w3.org/2000/svg"
						fill="none"
						viewBox="0 0 24 24"
						strokeWidth={1.5}
						stroke="currentColor"
						className="w-6 h-6"
					>
						<path
							strokeLinecap="round"
							strokeLinejoin="round"
							d="M19.5 12h-15m0 0l6.75 6.75M4.5 12l6.75-6.75"
						/>
					</svg>
				</Link>
				<div className="space-y-10 sm:space-y-12">
					{/* HEADING */}
					<h2 className="text-2xl sm:text-3xl font-semibold">
						{isEdit && user ? (
							<>
								<div className="text-sm">Utilisateur</div> {user.firstName}{" "}
								{user.lastName}
							</>
						) : (
							"Nouvel utilisateur"
						)}
					</h2>
					<div className="">
						<UserForm
							user={user}
							setUser={setUser}
							errors={errors}
							galleries={galleryList}
						/>
						<div className="mt-5 pt-2 text-center w-full">
							<ButtonPrimary
								onClick={() => saveUser()}
								loading={loading}
								disabled={loading}
								className=" w-full"
							>
								Valider
							</ButtonPrimary>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default UserEdit;
