import ButtonSecondary from "shared/Button/ButtonSecondary";
import { useEffect, useState } from "react";
import services from "utils/services";
import { Link } from "react-router-dom";
import { PlusIcon } from "@heroicons/react/24/solid";

const SettingsProviderList = () => {
	const [providers, setProviders] = useState<any>([]);
	useEffect(() => {
		getProviders();
	}, []);

	const getProviders = () => {
		services.getProviders(
			{},
			(success) => {
				setProviders(success.providers);
			},
			(error) => console.error(error)
		);
	};
	const renderProvider = (provider: any, index: number) => {
		const { _id, firstName, lastName, telephone, commission } = provider;
		return (
			<div key={index}>
				<hr className="mt-3 border-slate-200 dark:border-slate-700"></hr>

				<div className="flex flex-row items-center pt-3 sm:pt-3 last:pb-0 first:pt-0">
					<span className="w-[40%]">
						{firstName} {lastName}
					</span>
					{/* <span className="w-[25%]">{email}</span> */}
					<span className="w-[20%]">{telephone}</span>
					<div className="w-[10%] flex justify-end">
						<Link
							className="w-10 h-10 sm:w-12 sm:h-12 hover:bg-slate-100 dark:hover:bg-slate-800 rounded-full  flex items-center justify-center text-right"
							to={"/apporteur/" + _id}
						>
							<svg
								xmlns="http://www.w3.org/2000/svg"
								fill="none"
								viewBox="0 0 24 24"
								strokeWidth="1.5"
								stroke="currentColor"
								className="w-6 h-6"
							>
								<path
									strokeLinecap="round"
									strokeLinejoin="round"
									d="M16.862 4.487l1.687-1.688a1.875 1.875 0 112.652 2.652L10.582 16.07a4.5 4.5 0 01-1.897 1.13L6 18l.8-2.685a4.5 4.5 0 011.13-1.897l8.932-8.931zm0 0L19.5 7.125M18 14v4.75A2.25 2.25 0 0115.75 21H5.25A2.25 2.25 0 013 18.75V8.25A2.25 2.25 0 015.25 6H10"
								/>
							</svg>
						</Link>
					</div>
				</div>
			</div>
		);
	};

	return (
		<div>
			{/* <CommonLayout> */}
			<div className="space-y-3 sm:space-y-5">{/* HEADING */}</div>
			<div className="m-5 pt-2 text-right">
				<ButtonSecondary href="/apporteur" className="bg-slate-200">
					<PlusIcon className="w-5 h-5 mr-2 -ml-1" aria-hidden="true" />
					Ajouter
				</ButtonSecondary>
			</div>

			<div className="flex flex-row">
				<span className="w-[40%] font-medium">Nom & Prénom</span>
				{/* <span className="w-[25%] font-medium">Email</span> */}
				<span className="w-[20%] font-medium">téléphone</span>
				<span></span>
			</div>
			{/* </CommonLayout> */}
			{providers.map((provider: any, index: number) =>
				renderProvider(provider, index)
			)}
		</div>
	);
};

export default SettingsProviderList;
