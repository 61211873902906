import { createSlice } from "@reduxjs/toolkit";
import dayjs from "dayjs";

const TAX = 0.2;
const initialState = {
	items: [],
	paymentMode: null,
	customer: {},
	orderDate: dayjs().format("YYYY-MM-DD HH:MM:ss"),
};

const getTotals = (items, deliveryFees = 0, discountAmount = 0) => {
	let totalQuantity = 0;
	let totalPrice = 0;
	let subTotal = 0;
	let totalTax = 0;
	items
		.filter((item) => !item.free)
		.forEach((item) => {
			totalQuantity += item.quantity;
			totalPrice += item.price * item.quantity;
		});
	subTotal = totalPrice;

	totalPrice += deliveryFees - discountAmount;
	totalTax = (TAX * totalPrice) / (1 + TAX);
	return { totalPrice, totalQuantity, subTotal, totalTax };
};

export const cartSlice = createSlice({
	name: "cart",
	initialState,
	reducers: {
		addToCart: (state, action) => {
			const product = action.payload;
			product.quantity = 1;
			// let foundProduct = state.items.find((p) => p.id === product.id);
			// if (foundProduct !== undefined && foundProduct.type === "product") {
			// 	foundProduct.quantity++;
			// } else {
			product.timestamp = product.timestamp || Date.now();
			state.items.push(product);
			state.totals = getTotals(
				state.items,
				state.deliveryFees,
				state.discountAmount
			);

			// }
			state.updatedAt = Date.now();
		},

		removeFromcart: (state, action) => {
			state.items = state.items.filter(
				(item) => item.timestamp !== action.payload.timestamp
			);
			state.totals = getTotals(
				state.items,
				state.deliveryFees,
				state.discountAmount
			);

			state.updatedAt = Date.now();
		},

		updateItem: (state, action) => {
			const item = state.items.find(
				(item) => item.timestamp == action.payload.timestamp
			);

			if (typeof action.payload.free != undefined) {
				item.free = action.payload.free;
			}

			state.totals = getTotals(
				state.items,
				state.deliveryFees,
				state.discountAmount
			);

			state.updatedAt = Date.now();
		},

		// changeQuantity: (state, action) => {
		// 	const product = action.payload.product;
		// 	const quantity = action.payload.quantity;
		// 	const foundProduct = state.items.find((p) => p.id === product.id);
		// 	if (foundProduct !== undefined) {
		// 		foundProduct.quantity += quantity;
		// 		if (foundProduct.quantity <= 0) {
		// 			state.items = state.items.filter(
		// 				(item) => item.timestamp !== action.payload.timestamp
		// 			);
		// 		}

		// 		state.updatedAt = Date.now();
		// 	}
		// },
		setPaymentMode: (state, action) => {
			state.paymentMode = action.payload.paymentMode;
			state.updatedAt = Date.now();
		},
		saveCustomerToCart: (state, action) => {
			state.customer = action.payload;
			state.updatedAt = Date.now();
		},
		saveShippingAddress: (state, action) => {
			state.customer.shippingAddress = action.payload;
			state.updatedAt = Date.now();
		},

		clearCart: (state) => {
			state.totals = {};
			state.items = [];
			state.customer = {};
			state.paymentMode = null;
			state.order = null;
			state.deliveryFees = "";
			state.discountAmount = 0;
			state.discountPercent = 0;
			state.orderDate = dayjs().format("YYYY-MM-DD HH:MM:ss");
			state.updatedAt = Date.now();
			state.referenceIRIS = null;
			state.numTicket = null;
		},
		setDeliveryFees: (state, action) => {
			state.deliveryFees = action.payload.deliveryFees;
			state.totals = getTotals(
				state.items,
				state.deliveryFees,
				state.discountAmount
			);
		},
		setDiscountAmount: (state, action) => {
			state.discountAmount = action.payload.discountAmount;
			state.discountPercent = action.payload.discountPercent;
			state.totals = getTotals(
				state.items,
				state.deliveryFees,
				state.discountAmount
			);
		},
		setOrder: (state, action) => {
			state.order = action.payload.order;
			if (action.payload.photos && action.payload.product) {
				const item = state.items.find(
					(it) => it.timestamp == action.payload.product
				);
				item.photos = action.payload.photos;
			}
		},
		setOrderDate: (state, action) => {
			const now = dayjs();
			state.orderDate = dayjs(action.payload, "YYYY-MM-DD")
				.hour(now.hour())
				.minute(now.minute())
				.second(now.second())
				.format("YYYY-MM-DD HH:MM:ss");
		},
		setReferenceIRIS: (state, action) => {
			state.referenceIRIS = action.payload;
		},
		setNumTicket: (state, action) => {
			state.numTicket = action.payload;
		},
		fillCart: (state, action) => {
			state.items = action.payload.products.map((p) => {
				return {
					product: { _id: p._id },
					name: p.name,
					support: p.support,
					dimension: p.dimension,
					effect: p.effect,
					price: p.price,
					quantity: p.quantity,
					timestamp: p.timestamp,
					photos: p.photos,
				};
			});

			state.orderDate = dayjs(action.payload.orderDate);
			state.customer = action.payload.customer;
			state.paymentMode = null;
			state.order = { _id: action.payload._id };
			state.deliveryFees = action.payload.deliveryFees;
			state.discountAmount = action.payload.discountAmount;
			state.totals = getTotals(
				state.items,
				state.deliveryFees,
				state.discountAmount
			);
			// state.updatedAt = Date.now();
		},
	},
});

// Action creators are generated for each case reducer function
export const {
	addToCart,
	removeFromcart,
	updateItem,
	// changeQuantity,
	clearCart,
	setPaymentMode,
	saveCustomerToCart,
	saveShippingAddress,
	setOrder,
	setOrderDate,
	setReferenceIRIS,
	setNumTicket,
	setDiscountAmount,
	setDeliveryFees,
	fillCart,
} = cartSlice.actions;

export default cartSlice.reducer;
