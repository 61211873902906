import { configureStore } from "@reduxjs/toolkit";
import { loadState, saveState } from "./localStorage";
import cartReducer from "./reducers/cart";
import productReducer from "./reducers/products";
import authReducer from "./reducers/auth";

const persistedState = loadState();

const store = configureStore({
	reducer: { cart: cartReducer, product: productReducer, auth: authReducer },
	preloadedState: persistedState,
});

store.subscribe(() => {
	saveState(store.getState());
});

export default store;
