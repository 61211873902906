import React, { useEffect, useState } from "react";
import BackgroundSection from "components/BackgroundSection/BackgroundSection";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import { ExclamationCircleIcon, PlusIcon } from "@heroicons/react/24/solid";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { clearCart } from "redux/reducers/cart";
import services from "utils/services";
import ButtonSecondary from "shared/Button/ButtonSecondary";
import dayjs from "dayjs";
import Input from "shared/Input/Input";
import ButtonCircle from "shared/Button/ButtonCircle";
import Select from "react-select";
import Label from "components/Label/Label";
import { addProducts } from "redux/reducers/products";

import ShareSelect from "shared/Select/Select";
import { Product } from "data/data";
import { saveFilters } from "redux/reducers/auth";
import ButtonThird from "shared/Button/ButtonThird";
import ModalListeVues from "components/ModalListeVues";
import ModalDailyReport from "components/ModalDailyReport";
import toast from "react-hot-toast";
import ModalIncident from "components/ModalIncident";

function PageHome() {
	const navigate = useNavigate();
	const dispatch = useDispatch();

	const auth = useSelector((state: any) => state.auth);

	const [orders, setOrders] = useState<any>([]);
	const [statistics, setStatistics] = useState<any>({});
	const [search, setSearch] = useState<string>(auth.filters?.search || "");
	const [dateFrom, setDateFrom] = useState<string>(
		auth.filters?.dateFrom || dayjs().format("YYYY-MM-DD")
	);
	const [dateTo, setDateTo] = useState<string>(
		auth.filters?.dateTo || dayjs().format("YYYY-MM-DD")
	);
	const [selectedStatus, setSelectedStatus] = useState<any>(
		auth.filters?.selectedStatus || []
	);
	const [selectedPayment, setSelectedPayment] = useState<any>(
		auth.filters?.selectedPayment || []
	);
	const [dimensions, setDimensions] = useState<any>([]);
	const [supports, setSupports] = useState<any>([]);
	const [selectedProductType, setSelectedProductType] = useState<any>(
		auth.filters?.selectedProductType || ""
	);
	const [selectedSupport, setSelectedSupport] = useState<any>(
		auth.filters?.selectedSupport || ""
	);
	const [selectedDimension, setSelectedDimension] = useState<any>(
		auth.filters?.selectedDimension || ""
	);

	const [selectedOption, setSelectedOption] = useState<any>(
		auth.filters?.selectedOption || ""
	);
	const [products, setProducts] = useState<Product[]>([]);
	const [vues, setVues] = useState<any[]>([]);
	const [galleries, setGalleries] = useState<any[]>([]);
	const [selectedGallery, setSelectedGallery] = useState<any>(
		auth.filters?.selectedGallery
	);
	const [showListeVues, setShowListeVues] = useState(false);
	const [showDailyReport, setShowDailyReport] = useState(false);
	const [dailyReportData, setDailyReportData] = useState<any>({});
	const [showModalIncident, setShowModalIncident] = useState(false);
	const [providers, setProviders] = useState<any>([]);
	const [selectedProvider, setSelectedProvider] = useState<any>(null);

	useEffect(() => {
		getOrders(
			selectedStatus,
			selectedPayment,
			search,
			dateFrom,
			dateTo,
			selectedGallery
		);
		loadProducts();
		dispatch(clearCart());

		if (auth.user.role == "ADMIN") {
			loadGalleries();
			getProviders();
		}
	}, []);

	const loadProducts = () => {
		services.getProducts(
			{},
			(res) => {
				dispatch(addProducts(res));
				populateChoices(res);
				setProducts(res);
			},
			(err) => {
				console.error(err);
			}
		);
	};
	useEffect(() => {
		populateChoices(products);
	}, [selectedDimension, selectedSupport]);

	const loadGalleries = () => {
		services.getGalleries(
			{},
			(res) => {
				setGalleries(
					res.galleries.map((g: any) => {
						return { value: g._id, label: g.name };
					})
				);
			},
			(err) => {
				console.error(err);
			}
		);
	};
	const getProviders = () => {
		services.getProviders(
			{},
			(res) => {
				setProviders(
					res.providers.map((g: any) => {
						return { value: g._id, label: g.firstName + " " + g.lastName };
					})
				);
			},
			(error) => console.error(error)
		);
	};

	// useEffect(() => {
	// 	updateFilters();
	// }, [
	// 	selectedDimension,
	// 	selectedSupport,
	// 	dateTo,
	// 	dateFrom,
	// 	search,
	// 	selectedPayment,
	// 	selectedProductType,
	// ]);

	const updateFilters = () => {
		dispatch(
			saveFilters({
				filters: {
					selectedDimension,
					selectedSupport,
					dateFrom,
					dateTo,
					search,
					selectedPayment,
					selectedProductType,
					selectedOption,
					selectedGallery,
				},
			})
		);
	};

	const populateChoices = (products: Product[]) => {
		const dimensionsList: any = [];
		const supportsList: any = [];

		products
			.filter(
				(product: any) =>
					product.name == selectedProductType || !selectedProductType
			)
			.forEach((currentProduct: any) => {
				let combinations = currentProduct.combinations;

				combinations.forEach((comb: any) => {
					if (!supportsList.find((s: any) => s.code == comb.support.code)) {
						supportsList.push(comb.support);
					}
				});

				if (selectedSupport) {
					combinations = combinations.filter(
						(c: any) => c.support.code == selectedSupport
					);
				}

				combinations.forEach((comb: any) => {
					if (
						comb.dimension.size &&
						!dimensionsList.find(
							(d: any) =>
								d.type == comb.dimension.type && d.size == comb.dimension.size
						)
					) {
						dimensionsList.push(comb.dimension);
					}
				});
			});

		if (
			!dimensionsList.find(
				(d: any) => (d.type ? d.type + " - " : "") + d.size == selectedDimension
			)
		) {
			setSelectedDimension("");
		}
		setDimensions(dimensionsList);
		setSupports(supportsList);
	};

	const getOrders = (
		status: any,
		paymentMode: any,
		search = "",
		dateFrom: string,
		dateTo: string,
		gallery: any,
		provider?: any
	) => {
		updateFilters();
		services.getOrders(
			{
				status: status ? status.map((s: any) => s.value).join(",") : [],
				paymentMode: paymentMode
					? paymentMode.map((s: any) => s.value).join(",")
					: [],
				search,
				dateFrom,
				dateTo,
				productType: selectedProductType,
				support: selectedSupport,
				dimension: selectedDimension,
				option: selectedOption,
				gallery: gallery && gallery.value ? gallery.value : null,
				provider: provider && provider.value ? provider.value : null,
			},
			(success) => {
				setOrders(success.orders);
				setStatistics(success.statistics);
				setVues(success.vues);
			},
			(error) => {
				console.error(error);
			}
		);
	};

	const getTodayStats = () => {
		services.getOrders(
			{
				dateFrom: dayjs().format("YYYY-MM-DD"),
				dateTo: dayjs().format("YYYY-MM-DD"),
				dayReport: 1,
			},
			(success) => {
				setDailyReportData(success.statistics);
				setShowDailyReport(true);
			},
			(error) => {
				console.error(error);
			}
		);
	};

	const renderOrder = (order: any, index: number) => {
		return (
			<div
				className="flex-1 border border-slate-200 dark:border-slate-700 rounded-lg overflow-hidden z-0"
				key={index}
			>
				<div className="flex flex-col sm:flex-row sm:justify-between sm:items-center p-2 sm:p-8 bg-slate-50 dark:bg-slate-500/5">
					<div>
						<p className="text-sm font-semibold">
							#{order.reference} - {order.customer.lastName}{" "}
							{order.customer.firstName}
						</p>

						{(!!order.referenceIRIS || !!order.numTicket) && (
							<p>
								{!!order.referenceIRIS && (
									<span className="text-xs mr-3">
										Réf. IRIS: {order.referenceIRIS}
									</span>
								)}
								{!!order.numTicket && (
									<span className="text-xs">
										Num. Ticket: {order.numTicket}
									</span>
								)}
							</p>
						)}
						<p className="text-slate-500 dark:text-slate-400 text-sm mt-1.5 sm:mt-2">
							<span>{dayjs(order.orderDate).format("DD/MM/YYYY")}</span>
							<span className="mx-2">·</span>
							<span className="text-primary-500">
								{order.status}{" "}
								{order.status == "PAID" ? "- " + order.paymentMode : ""}
							</span>
							{order.status == "PAID" && (
								<>
									{" "}
									<span className="mx-2">·</span>
									<span className="text-green-500 !leading-none font-bold">
										{order.totalPrice.toFixed(2)} MAD
									</span>
								</>
							)}{" "}
							{!!order.user && (
								<>
									<span className="mx-2">·</span>
									<span className="italic">par {order.user.firstName}</span>
								</>
							)}
						</p>
						{auth.user.role == "ADMIN" && !!order.gallery && (
							<p className="text-xs mt-2">{order.gallery.name}</p>
						)}
					</div>
					<div className="mt-3 sm:mt-0">
						<ButtonSecondary
							onClick={() => navigate("/commande/" + order._id)}
							sizeClass="py-2.5 px-4 sm:px-6"
							fontSize="text-sm font-medium"
						>
							Voir
						</ButtonSecondary>
					</div>
				</div>
			</div>
		);
	};

	return (
		<div className="nc-PageHome relative overflow-hidden">
			{/* SECTION HERO */}
			{/* <SectionHero2 /> */}
			<div className="container  flex flex-col lg:flex-row justify-center gap-3 relative my-5 flex-wrap ">
				<div className="flex flex-col sm:flex-row gap-2 flex-1">
					<div
						className={`flex flex-col sm:w-[40%] p-5 rounded-2xl bg-neutral-100 dark:bg-opacity-90 relative items-center justify-center`}
					>
						<div className="mt-2.5 text-center">
							<p className="font-bold text-slate-900 text-xl mb-2">
								{new Intl.NumberFormat("fr-FR", {
									style: "currency",
									currency: "MAD",
								}).format(statistics.totalCA || 0)}{" "}
							</p>
							<p className="font-bold text-slate-500 text-xl mb-2">
								({statistics.total} -{" "}
								{statistics.total != 0 || statistics.totalVues != 0
									? (
											(parseFloat(statistics.total) /
												(parseFloat(statistics.totalVues) +
													parseFloat(statistics.total))) *
											100
									  ).toFixed()
									: 0}
								{"%"})
							</p>
							<p className="text-slate-500 mt-0.5 text-sm text-center">
								Total CA
							</p>
							{statistics.deliveryFees > 0 && (
								<p className="text-slate-500 mt-0.5 text-sm text-center">
									Hors Frais livraison : {statistics.deliveryFees}
								</p>
							)}
							<p className="text-slate-500 mt-0.5 text-sm text-center">
								Nombre de vues : {statistics.totalVues}
							</p>

							<p className="text-slate-500 mt-0.5 text-sm text-center">
								{!!statistics.salesByCountries && (
									<>
										{(statistics.salesByCountries["maroc"].total != 0 ||
											statistics.salesByCountries["maroc"].vues != 0) && (
											<>
												Maroc : {statistics.salesByCountries["maroc"].total} (
												{statistics.salesByCountries["maroc"].vues}) <br />
											</>
										)}
										{(statistics.salesByCountries["france"].total != 0 ||
											statistics.salesByCountries["france"].vues != 0) && (
											<>
												France : {statistics.salesByCountries["france"].total} (
												{statistics.salesByCountries["france"].vues}) <br />
											</>
										)}
										{(statistics.salesByCountries["others"].total != 0 ||
											statistics.salesByCountries["others"].vues != 0) && (
											<>
												Autres : {statistics.salesByCountries["others"].total} (
												{statistics.salesByCountries["others"].vues}) <br />
											</>
										)}
									</>
								)}
								{[5, 10, 15, 20, 30].map(
									(d, ind) =>
										!!statistics["discount" + d] &&
										statistics["discount" + d].total != 0 && (
											<span key={ind}>
												Remise {d}% : {statistics["discount" + d].total}
												{" - "}
												{statistics["discount" + d].totalCA.toFixed(2)} MAD
												<br />
											</span>
										)
								)}
							</p>
						</div>
					</div>

					<div
						className={`flex flex-col sm:w-[60%] p-5 rounded-2xl bg-neutral-100 dark:bg-opacity-90 relative`}
					>
						<div className="mt-2.5">
							<div className="font-medium text-slate-900 mb-2 text-xs">
								{!!statistics.topSales &&
									statistics.topSales.map((ts: any, index: number) => (
										<div
											className="flex flex-row mb-3 items-center justify-between"
											key={index}
										>
											<div>
												<span className="mr-2 rounded-full px-2.5 py-1.5 text-xs text-slate-700 dark:text-slate-300 border border-slate-500 dark:border-slate-700">
													{index + 1}
												</span>
												{ts.name}
											</div>
											<div>
												{new Intl.NumberFormat("fr-FR", {
													style: "currency",
													currency: "MAD",
												}).format(ts.totalPrice)}{" "}
												<span className="text-slate-500 mt-0.5 text-sm">
													({ts.total})
												</span>
											</div>
										</div>
									))}
							</div>
							<p className="text-slate-500 mt-0.5 text-sm text-center">
								Top Ventes
							</p>
						</div>
					</div>
				</div>
				<div className="flex flex-col sm:flex-row gap-2 flex-1">
					<div
						className={`flex flex-col flex-1 p-5 rounded-2xl bg-neutral-100 dark:bg-opacity-90 relative`}
					>
						<div className="mt-2.5">
							<div className="font-medium text-slate-900 mb-2 text-sm">
								{!!statistics.salesByType &&
									statistics.salesByType.map((ts: any, index: number) => (
										<div
											className="flex flex-row mb-3 items-center justify-between"
											key={index}
										>
											<div>
												<span className="mr-2 rounded-full px-2.5 py-1.5 text-xs text-slate-700 dark:text-slate-300 border border-slate-500 dark:border-slate-700">
													{index + 1}
												</span>
												{ts.name}
											</div>
											<div>
												{new Intl.NumberFormat("fr-FR", {
													style: "currency",
													currency: "MAD",
												}).format(ts.totalPrice)}{" "}
												<span className="text-slate-500 mt-0.5 text-sm">
													({ts.total})
												</span>
											</div>
										</div>
									))}
							</div>
							<p className="text-slate-500 mt-0.5 text-sm text-center">
								Ventes par types de produits
							</p>
						</div>
					</div>
					<div
						className={`flex flex-col flex-1 p-5 rounded-2xl bg-neutral-100 dark:bg-opacity-90 relative`}
					>
						<div className="mt-2.5">
							<div className="font-medium text-slate-900 mb-2 text-sm">
								{!!statistics.salesBySupport &&
									statistics.salesBySupport.map((ts: any, index: number) => (
										<div
											className="flex flex-row mb-3 items-center justify-between"
											key={index}
										>
											<div>
												<span className="mr-2 rounded-full px-2.5 py-1.5 text-xs text-slate-700 dark:text-slate-300 border border-slate-500 dark:border-slate-700">
													{index + 1}
												</span>
												{ts.name}
											</div>
											<div>
												{new Intl.NumberFormat("fr-FR", {
													style: "currency",
													currency: "MAD",
												}).format(ts.totalPrice)}{" "}
												<span className="text-slate-500 mt-0.5 text-sm">
													({ts.total} -{" "}
													{((ts.total / statistics.total) * 100).toFixed()}%)
												</span>
											</div>
										</div>
									))}
							</div>
							<p className="text-slate-500 mt-0.5 text-sm text-center">
								Ventes par supports
							</p>
						</div>
					</div>
				</div>
			</div>

			<div className="container relative space-y-24 my-10 lg:space-y-32 lg:my-10">
				{/* <div className="py-24 lg:py-32 border-t border-b border-slate-200 dark:border-slate-700">
					<SectionHowItWork />
				</div> */}

				{/* SECTION */}
				{/* <SectionPromo1 /> */}

				{/* SECTION */}
				<div className="relative py-8 lg:py-5">
					<BackgroundSection className="bg-neutral-50/70 dark:bg-black/20" />
					<div className="flex mt-5 justify-end items-start gap-2">
						{auth.user.role != "ADMIN" && (
							<>
								<ButtonPrimary
									fontSize="text-sm"
									sizeClass="py-2 px-3"
									onClick={() => {
										dispatch(clearCart());
										navigate("/client");
									}}
								>
									<PlusIcon className="w-6 h-6 mr-2 -ml-1" aria-hidden="true" />
									Nouvelle Commande
								</ButtonPrimary>
								<ButtonThird
									fontSize="text-sm"
									sizeClass="py-2 px-3"
									onClick={() => {
										setShowListeVues(true);
									}}
								>
									Enregitrer une vue
								</ButtonThird>
								<ButtonThird
									fontSize="text-sm"
									sizeClass="py-2 px-3"
									className="text-neutral-700 border border-danger bg-red-100 text-danger hover:bg-red-200"
									onClick={() => {
										getTodayStats();
									}}
								>
									Rapport journée
								</ButtonThird>

								<ButtonThird
									fontSize="text-sm"
									sizeClass="py-1 px-1"
									className="text-neutral-700 border border-slate bg-slate-100 text-slate hover:bg-slate-300 "
									onClick={() => {
										setShowModalIncident(true);
									}}
								>
									<svg
										xmlns="http://www.w3.org/2000/svg"
										fill="none"
										viewBox="0 0 24 24"
										strokeWidth={1.5}
										stroke="currentColor"
										className="w-6 h-6"
									>
										<path
											strokeLinecap="round"
											strokeLinejoin="round"
											d="M12 9v3.75m9-.75a9 9 0 1 1-18 0 9 9 0 0 1 18 0Zm-9 3.75h.008v.008H12v-.008Z"
										/>
									</svg>
								</ButtonThird>
							</>
						)}
					</div>
					<div className="flex flex-col sm:flex-row gap-3 mt-5 items-center relative">
						<div className="w-[90%] ">
							<div className="flex flex-col sm:flex-row gap-3 mt-5 items-center">
								<form
									className="relative w-full sm:w-[30%]"
									onSubmit={(e) => e.preventDefault()}
								>
									<label
										htmlFor="search-input"
										className="text-neutral-800 dark:text-neutral-300"
									>
										<Input
											className="shadow-lg border-1 border-neutral-200 dark:border"
											id="search-input"
											type="search"
											placeholder="Ref. commande, client"
											sizeClass="pl-4 py-4 pr-4 md:pl-4 "
											onChange={({ target }) => setSearch(target.value)}
										/>
									</label>
								</form>
								{/* <Select
									name="status"
									id="status"
									value={selectedStatus}
									placeholder="Statut de commande"
									className="w-full sm:w-[30%] text-sm rounded-md border-1 shadow-lg dark:border border-1 border-neutral-200"
									onChange={(e) => {
										setSelectedStatus(e);
									}}
									styles={{
										control: (baseStyles, state) => ({
											...baseStyles,
											border: 0,
										}),
									}}
									classNames={{
										control: (state) =>
											"border-0 py-2 px-2 border-1 border-neutral-200",
									}}
									isMulti={true}
									options={[
										{ value: "PENDING", label: "En attente" },
										{ value: "PAID", label: "Payée" },
										{ value: "SHIPPED", label: "Envoyée" },
										{ value: "PROCESSING", label: "En cours de traitement" },
										{ value: "DELIVERED", label: "LIvrée" },
										{ value: "CANCELED", label: "Annulée" },
									]}
								/> */}

								<Select
									name="paymentMode"
									id="paymentMode"
									value={selectedPayment}
									placeholder="Mode de paiement"
									className="w-full sm:w-[30%] text-sm rounded-md border-1 shadow-lg dark:border border-1 border-neutral-200"
									onChange={(e) => {
										setSelectedPayment(e);
									}}
									styles={{
										control: (baseStyles, state) => ({
											...baseStyles,
											border: 0,
										}),
									}}
									classNames={{
										control: (state) =>
											"border-0 py-2 px-2 border-1 border-neutral-200",
									}}
									isMulti={true}
									options={[
										{ value: "CARD", label: "Carte bancaire" },
										{ value: "CASH", label: "Espèces" },
										{ value: "TRANSFERT", label: "Virement bancaire" },
										{ value: "PAYPAL", label: "Paypal" },
										{ value: "PENDING", label: "En attente" },
									]}
								/>

								{auth.user.role == "ADMIN" && galleries.length > 0 && (
									<Select
										name="gallery"
										id="gallery"
										value={selectedGallery}
										placeholder="Galerie"
										className="w-full sm:w-[30%] text-sm rounded-md border-1 shadow-lg dark:border border-1 border-neutral-200"
										onChange={(e) => {
											setSelectedGallery(e);
										}}
										styles={{
											control: (baseStyles, state) => ({
												...baseStyles,
												border: 0,
											}),
										}}
										classNames={{
											control: (state) =>
												"border-0 py-2 px-2 border-1 border-neutral-200",
										}}
										isClearable={true}
										options={galleries}
									/>
								)}

								{auth.user.role == "ADMIN" && providers.length > 0 && (
									<Select
										name="provider"
										id="provider"
										value={selectedProvider}
										placeholder="Apporteur"
										className="w-full sm:w-[30%] text-sm rounded-md border-1 shadow-lg dark:border border-1 border-neutral-200"
										onChange={(e) => {
											setSelectedProvider(e);
										}}
										styles={{
											control: (baseStyles, state) => ({
												...baseStyles,
												border: 0,
											}),
										}}
										classNames={{
											control: (state) =>
												"border-0 py-2 px-2 border-1 border-neutral-200",
										}}
										isClearable={true}
										options={providers}
									/>
								)}

								<ButtonCircle
									className="transform"
									size="absolute right-5 w-11 h-11"
									type="button"
									onClick={() =>
										getOrders(
											selectedStatus,
											selectedPayment,
											search,
											dateFrom,
											dateTo,
											selectedGallery,
											selectedProvider
										)
									}
								>
									<svg
										className="h-5 w-5"
										viewBox="0 0 24 24"
										fill="none"
										xmlns="http://www.w3.org/2000/svg"
									>
										<path
											d="M11.5 21C16.7467 21 21 16.7467 21 11.5C21 6.25329 16.7467 2 11.5 2C6.25329 2 2 6.25329 2 11.5C2 16.7467 6.25329 21 11.5 21Z"
											stroke="currentColor"
											strokeWidth="1.5"
											strokeLinecap="round"
											strokeLinejoin="round"
										/>
										<path
											d="M22 22L20 20"
											stroke="currentColor"
											strokeWidth="1.5"
											strokeLinecap="round"
											strokeLinejoin="round"
										/>
									</svg>
								</ButtonCircle>
							</div>

							<div className="flex flex-col sm:flex-row  sm:w-[80%]  gap-3 mt-5 items-center">
								<ShareSelect
									className="mt-1.5 shadow-lg  "
									value={selectedProductType}
									onChange={({ target }: { target: any }) => {
										setSelectedProductType(target.value);
									}}
									// disabled={!supportActive}
									placeholder="Type de produit"
								>
									<option value="">Produit</option>
									<option value="SOLO">SOLO</option>
									<option value="DUO">DUO</option>
									<option value="TRIO">TRIO</option>
									<option value="QUATUOR">QUATUOR</option>
									<option value="QUINTUOR">QUINTUOR</option>
									<option value="BOOK VISION">BOOK VISION</option>
								</ShareSelect>
								<ShareSelect
									className="mt-1.5 shadow-lg "
									value={selectedSupport}
									onChange={({ target }: { target: any }) => {
										setSelectedSupport(target.value);
										// populateChoices(products);
									}}
									// disabled={!supportActive}
								>
									<option value="">Support</option>
									{supports.map((s: any, index: number) => (
										<option value={s.code} key={index}>
											{s.name}
										</option>
									))}
								</ShareSelect>
								<ShareSelect
									className="mt-1.5 shadow-lg "
									value={selectedDimension}
									onChange={({ target }: { target: any }) => {
										setSelectedDimension(target.value);
										// populateChoices(products);
									}}
									// disabled={!supportActive}
								>
									<option value="">Dimension</option>
									{dimensions.map((d: any, index: number) => {
										return (
											<option
												value={(d.type ? d.type + " - " : "") + d.size}
												key={index}
											>
												{d.type ? d.type + " -" : null} {d.size}
											</option>
										);
									})}
								</ShareSelect>
								<ShareSelect
									className="mt-1.5 shadow-lg "
									value={selectedOption}
									onChange={({ target }: { target: any }) => {
										setSelectedOption(target.value);
										// populateChoices(products);
									}}
									// disabled={!supportActive}
								>
									<option value="">Option</option>
									<option value="N">SANS</option>
									<option value="FRAME">FRAME</option>
								</ShareSelect>
							</div>
							<div className="flex flex-col sm:flex-row gap-3 mt-5 items-center">
								<div className="max-w-lg items-center">
									<Label className="text-neutral-800 text-sm">Entre: </Label>
									<div className="mt-1.5 flex">
										<span className="inline-flex items-center px-2.5 rounded-md !rounded-r-none border border-r-0 border-neutral-200 dark:border-neutral-700 bg-neutral-50 dark:bg-neutral-800 text-neutral-500 dark:text-neutral-400 text-sm">
											<i className="text-2xl las la-calendar"></i>
										</span>
										<Input
											className=" rounded-md !rounded-l-none"
											type="date"
											value={dateFrom}
											onChange={({ target }) => {
												setDateFrom(target.value);
											}}
										/>
									</div>
								</div>
								<div className="max-w-lg items-center">
									<Label className="text-neutral-800 text-sm">Et : </Label>
									<div className="mt-1.5 flex">
										<span className="inline-flex items-center px-2.5 rounded-md !rounded-r-none border border-r-0 border-neutral-200 dark:border-neutral-700 bg-neutral-50 dark:bg-neutral-800 text-neutral-500 dark:text-neutral-400 text-sm">
											<i className="text-2xl las la-calendar"></i>
										</span>
										<Input
											className="rounded-md !rounded-l-none"
											type="date"
											value={dateTo}
											onChange={({ target }) => {
												setDateTo(target.value);
											}}
										/>
									</div>
								</div>
							</div>
						</div>
					</div>
					<div className="grid grid-cols-1 md:grid-cols-2 sm:grid-cols-2 mt-5 gap-3 flex-row-3">
						{orders.map((o: any, ind: number) => renderOrder(o, ind))}
					</div>
				</div>
			</div>

			{showListeVues && (
				<ModalListeVues
					vues={vues}
					show={showListeVues}
					onSave={(vues) => {
						services.saveVues(
							{ vues },
							(success) => {
								setVues(success.vues);
								setShowListeVues(false);
							},
							(error) => {}
						);
					}}
					onCloseModal={() => setShowListeVues(false)}
				/>
			)}

			{showDailyReport && (
				<ModalDailyReport
					data={dailyReportData}
					show={showDailyReport}
					onSend={(data) => {
						services.sendReport(
							{ stats: data },
							(success) => {
								setShowDailyReport(false);
								toast.success("Rapport envoyé !", {
									position: "top-center",
									id: "nc-product-notify",
									duration: 3000,
									className: "border-1 border-green-500 font-semibold",
									style: { borderWidth: "1px" },
								});
							},
							(error) => {}
						);
					}}
					onCloseModal={() => setShowDailyReport(false)}
				/>
			)}
			{showModalIncident && (
				<ModalIncident
					show={showModalIncident}
					onCloseModal={() => setShowModalIncident(false)}
				/>
			)}
		</div>
	);
}

export default PageHome;
