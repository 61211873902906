import Label from "components/Label/Label";
import React, { FC, useState } from "react";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import Input from "shared/Input/Input";
import CommonLayout from "./CommonLayout";
import { saveCustomerToCart } from "redux/reducers/cart";
import { useNavigate } from "react-router-dom";
import CustomerForm from "components/Forms/CustomerForm";
import { useDispatch, useSelector } from "react-redux";
import services from "utils/services";
import dayjs from "dayjs";

import { string, mixed, object } from "yup";
import { COUNTRY_LIST } from "contains/contants";

export interface AccountPageProps {
	className?: string;
	screen?: string;
}

const ResultList: FC<any> = ({ items, onSelect }) => {
	return (
		<div className="absolute w-full bg-slate-50 py-5 border-1 border-neutral-200">
			{items.map((it: any, index: number) => (
				<div
					className="py-1 px-5 cursor-pointer hover:bg-slate-100"
					onClick={() => {
						onSelect(it);
					}}
				>
					<span className="text-md">
						{it.firstName} {it.lastName}{" "}
					</span>
					<span className="text-sm">
						{it.email} - {it.telephone}
					</span>
				</div>
			))}
		</div>
	);
};

const CustomerAccountPage: FC<AccountPageProps> = ({
	className = "",
	screen = "order",
}) => {
	const cart = useSelector((state: any) => state.cart);
	const dispatch = useDispatch();
	const [customer, setCustomer] = useState<any>(cart.customer);
	const [search, setSearch] = useState<any>("");
	const [errors, setErrors] = useState<any>({});
	const [customerList, setCustomerList] = useState<any>([]);
	const [loading, setLoading] = useState<boolean>(false);
	const navigate = useNavigate();

	let customerSchema = object({
		firstName: string().required(""),
		lastName: string().required(""),
		email: string().required("").email("Email invalide"),
		country: mixed().required(""),
		// gender: mixed().oneOf(["male", "female"], "").required(""),
	});

	const checkFields = () => {
		let fieldErrors: any = {};
		try {
			customerSchema.validateSync(customer, { abortEarly: false });
			setErrors({});
			return true;
		} catch (err: any) {
			console.log(err);
			err.inner?.forEach((oneErr: { path: string; message: string }) => {
				fieldErrors[oneErr.path] = oneErr.message;
			});
			setErrors(fieldErrors);
			return false;
		}
	};

	const saveCustomer = () => {
		const isValid = checkFields();
		if (isValid) {
			dispatch(saveCustomerToCart(customer));

			setLoading(true);
			services.saveCustomer(
				customer,
				(success) => {
					if (success.customer.dateBirth) {
						success.customer.dateBirth = dayjs(
							success.customer.dateBirth
						).format("YYYY-MM-DD");
					}

					dispatch(saveCustomerToCart(success.customer));
					navigate("/produits");
					setLoading(false);
				},
				(error) => {
					setLoading(false);

					if (error.response.status == 422) {
						errors.email = error.response.data.message;
					} else {
						errors.global = "Une erreur est survenue";
					}

					setErrors({ ...errors });
					console.log(error);
				}
			);
		}
	};

	const findCustomers = (search: string) => {
		if (search) {
			services.getCustomers(
				{ search },
				(success) => {
					setCustomerList(success.customers);
				},
				(error) => {
					console.error(error);
				}
			);
		} else {
			setCustomerList([]);
		}
	};
	return (
		<div className={`nc-AccountPage ${className}`} data-nc-id="AccountPage">
			<CommonLayout customer={customer}>
				<div className="space-y-5 sm:space-y-6">
					{/* HEADING */}
					<h2 className="text-2xl sm:text-3xl font-semibold">
						Informations client
					</h2>

					<div className="flex flex-row gap-5 items-end mt-2">
						<div className="w-[80%] relative">
							<Label className="text-sm">Rechercher un client</Label>
							<Input
								className=""
								placeholder="Nom, prenom, email, téléphone"
								value={search}
								type="search"
								onChange={({ target }) => {
									setSearch(target.value);
									if (!target.value) {
										setCustomerList([]);
										setCustomer({
											lastName: "",
											firstName: "",
											email: "",
											telephone: "",
											otherInformations: "",
											country: "",
											gender: null,
										});
									} else if (target.value.length > 2) {
										findCustomers(target.value);
									}
								}}
							/>
							{customerList.length > 0 && (
								<ResultList
									items={customerList}
									onSelect={(item: any) => {
										setCustomer(item);
										setCustomerList([]);

										setSearch(
											`${item.firstName} ${item.lastName} - ${item.email} ${
												item.telephone ? " - " + item.telephone : ""
											}`
										);
									}}
								/>
							)}
						</div>
						{/* <ButtonCircle
							className="transform"
							size="w-11 h-11"
							type="button"
							onClick={() => findCustomers(search)}
						>
							<svg
								className=" h-5 w-5"
								viewBox="0 0 24 24"
								fill="none"
								xmlns="http://www.w3.org/2000/svg"
							>
								<path
									d="M11.5 21C16.7467 21 21 16.7467 21 11.5C21 6.25329 16.7467 2 11.5 2C6.25329 2 2 6.25329 2 11.5C2 16.7467 6.25329 21 11.5 21Z"
									stroke="currentColor"
									strokeWidth="1.5"
									strokeLinecap="round"
									strokeLinejoin="round"
								/>
								<path
									d="M22 22L20 20"
									stroke="currentColor"
									strokeWidth="1.5"
									strokeLinecap="round"
									strokeLinejoin="round"
								/>
							</svg>
						</ButtonCircle> */}
					</div>

					<CustomerForm
						customer={customer}
						setCustomer={setCustomer}
						errors={errors}
					/>
					<div className="pt-2 text-right">
						<ButtonPrimary
							onClick={() => saveCustomer()}
							loading={loading}
							disabled={loading}
						>
							Valider
						</ButtonPrimary>
					</div>
				</div>
			</CommonLayout>
		</div>
	);
};

export default CustomerAccountPage;
