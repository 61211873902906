import React, { FC } from "react";
import facebookSvg from "images/Facebook.svg";
import twitterSvg from "images/Twitter.svg";
import googleSvg from "images/Google.svg";
import { Helmet } from "react-helmet-async";
import Input from "shared/Input/Input";
import { Link, Navigate } from "react-router-dom";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import { useAuth } from "provider/useAuth";
import services from "utils/services";

export interface PageLoginProps {
	className?: string;
}

const PageLogin: FC<PageLoginProps> = ({ className = "" }) => {
	const authContext = useAuth();
	const [loading, setLoading] = React.useState<boolean>(false);
	const [email, setEmail] = React.useState<string>("");
	const [password, setPassword] = React.useState<string>("");

	if (authContext.token) {
		return <Navigate to="/" replace />;
	}

	const onLogin = async (email: string, password: string) => {
		if (email && password) {
			setLoading(true);
			services.login(
				{ email, password },
				(success) => {
					authContext.onLogin(
						success.token,
						success.refreshToken,
						success.authUser
					);
				},
				(error) => {
					setLoading(false);
				}
			);
		}
	};
	return (
		<div className={`nc-PageLogin ${className}`} data-nc-id="PageLogin">
			<Helmet>
				<title>Login || IRIS GALERIE MAROC</title>
			</Helmet>
			<div className="container mb-24 lg:mb-32">
				<h2 className="my-20 flex items-center text-3xl leading-[115%] md:text-5xl md:leading-[115%] font-semibold text-neutral-900 dark:text-neutral-100 justify-center">
					Login
				</h2>
				<div className="max-w-md mx-auto space-y-6">
					{/* <div className="grid gap-3">
						{loginSocials.map((item, index) => (
							<a
								key={index}
								href={item.href}
								className="flex w-full rounded-lg bg-primary-50 dark:bg-neutral-800 px-4 py-3 transform transition-transform sm:px-6 hover:translate-y-[-2px]"
							>
								<img
									className="flex-shrink-0"
									src={item.icon}
									alt={item.name}
								/>
								<h3 className="flex-grow text-center text-sm font-medium text-neutral-700 dark:text-neutral-300 sm:text-sm">
									{item.name}
								</h3>
							</a>
						))}
					</div> */}
					{/* OR */}
					{/* <div className="relative text-center">
						<span className="relative z-10 inline-block px-4 font-medium text-sm bg-white dark:text-neutral-400 dark:bg-neutral-900">
							OR
						</span>
						<div className="absolute left-0 w-full top-1/2 transform -translate-y-1/2 border border-neutral-100 dark:border-neutral-800"></div>
					</div> */}
					{/* FORM */}
					<div className="grid grid-cols-1 gap-6">
						<label className="block">
							<span className="text-neutral-800 dark:text-neutral-200">
								Email ou nom d'utilisateur
							</span>
							<Input
								type="email"
								placeholder=""
								className="mt-1"
								onChange={({ target }) => setEmail(target.value)}
							/>
						</label>
						<label className="block">
							<span className="flex justify-between items-center text-neutral-800 dark:text-neutral-200">
								Mot de passe
								{/* <Link to="/forgot-pass" className="text-sm text-green-600">
									Forgot password?
								</Link> */}
							</span>
							<Input
								type="password"
								placeholder=""
								className="mt-1"
								onChange={({ target }) => setPassword(target.value)}
							/>
						</label>
						<ButtonPrimary
							loading={loading}
							disabled={loading}
							type="button"
							onClick={() => {
								onLogin(email, password);
							}}
						>
							Se connecter
						</ButtonPrimary>
					</div>

					{/* ==== */}
					{/* <span className="block text-center text-neutral-700 dark:text-neutral-300">
						New user? {` `}
						<Link className="text-green-600" to="/signup">
							Create an account
						</Link>
					</span> */}
				</div>
			</div>
		</div>
	);
};

export default PageLogin;
