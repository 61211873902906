import React from "react";
import store from "redux/store";

export const AuthContext = React.createContext({
	token: store.getState().auth.token,
	user: store.getState().auth.user,
	loading: false,
	users: [],
	onLogin: (token: string, refreshToken: string, user: any) => {},
	onLogout: () => {},
});
