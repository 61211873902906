import { Dialog, Transition } from "@headlessui/react";
import {
	CheckCircleIcon,
	CheckIcon,
	MinusIcon,
	PlusIcon,
} from "@heroicons/react/24/solid";
import React, { FC, Fragment, useEffect, useState } from "react";
import ButtonCircle from "shared/Button/ButtonCircle";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import ButtonSecondary from "shared/Button/ButtonSecondary";
import ButtonClose from "shared/ButtonClose/ButtonClose";
import Label from "./Label/Label";
import { XMarkIcon } from "@heroicons/react/24/outline";
import services from "utils/services";
import { CASH_FLOW_TYPES } from "contains/contants";
import dayjs from "dayjs";
import Select from "shared/Select/Select";

export interface ModalCashFlowProps {
	show: boolean;
	gallery: any;
	onSave: (charges: any[]) => void;
	onCloseModal: () => void;
}

const ModalCashFlow: FC<ModalCashFlowProps> = ({
	show,
	gallery,
	onSave,
	onCloseModal,
}) => {
	const [isEdit, setIsEdit] = useState(false);
	const [list, setList] = useState<any[]>([]);
	const [operations, setOperations] = useState<any[]>([]);
	const [amountBank, setAmountBank] = useState<any>(0);
	const [amountDivers, setAmountDivers] = useState<any>(0);

	useEffect(() => {
		loadCashFlow();
	}, []);

	const addOperation = () => {
		operations.push({ type: "", amount: 0 });
		setOperations([...operations]);
	};

	const loadCashFlow = () => {
		services.getCashFlow(
			{ gallery },
			(res) => {
				setList(res.list);
			},
			(error) => {}
		);
	};

	const saveOperations = () => {
		services.addCashOperation(
			{ amountBank, amountDivers, gallery, operations },
			(res) => {
				loadCashFlow();
				setIsEdit(false);
				setAmountBank(0);
				setAmountDivers(0);
				setOperations([]);
			},
			(error) => {}
		);
	};
	return (
		<Transition appear show={show} as={Fragment}>
			<Dialog
				as="div"
				className="fixed inset-0 z-50"
				onClose={() => {
					onCloseModal();
				}}
			>
				<div className="flex items-stretch md:items-center justify-center h-full text-center md:px-4">
					<Transition.Child
						as={Fragment}
						enter="ease-out duration-300"
						enterFrom="opacity-0"
						enterTo="opacity-100"
						leave="ease-in duration-200"
						leaveFrom="opacity-100"
						leaveTo="opacity-0"
					>
						<Dialog.Overlay className="fixed inset-0 bg-black/40 dark:bg-black/70" />
					</Transition.Child>

					{/* This element is to trick the browser into centering the modal contents. */}
					<span className="inline-block align-middle" aria-hidden="true">
						&#8203;
					</span>
					<Transition.Child
						as={Fragment}
						enter="ease-out duration-300"
						enterFrom="opacity-0 scale-95"
						enterTo="opacity-100 scale-100"
						leave="ease-in duration-200"
						leaveFrom="opacity-100 scale-100"
						leaveTo="opacity-0 scale-95"
					>
						<div className="relative inline-flex xl:py-8 w-full max-h-full w-[600px]">
							<div
								className="flex-1 flex overflow-hidden max-h-full p-8 w-full text-left align-middle transition-all transform lg:rounded-2xl bg-white 
				  dark:bg-neutral-900 dark:border dark:border-slate-700 dark:text-slate-100 shadow-xl max-h-[500px]"
							>
								<span className="absolute right-3 top-3 z-50">
									<ButtonClose onClick={onCloseModal} />
								</span>

								<div className="flex-1 overflow-y-auto rounded-xl hiddenScrollbar">
									<h3 className="text-xl font-semibold flex items-center mt-2">
										Mouvements de caisse{" "}
										{!isEdit && (
											<ButtonCircle
												className="transform ml-2 bg-slate-400 text-black hover:bg-slate-300"
												size="w-6 h-6"
												type="button"
												onClick={() => {
													setIsEdit(true);
													addOperation();
												}}
											>
												<PlusIcon className="h-4 w-4" />
											</ButtonCircle>
										)}
									</h3>

									{isEdit ? (
										<>
											<div>
												{operations.map((op: any, index: number) => (
													<div className="flex flex-row items-center mt-2.5 mb-1 border-bottom-1 gap-3">
														<div className="w-[80%]">
															<div className="flex flex-row items-center gap-3">
																<div className="flex flex-col w-[60%]">
																	<Select
																		className="rounded text-sm "
																		placeholder="Type"
																		value={op.type}
																		onChange={({ target }) => {
																			operations[index].type = target.value;
																			setOperations([...operations]);
																		}}
																	>
																		<option value="">Type</option>
																		{Object.keys(CASH_FLOW_TYPES).map(
																			(o: any, i: any) => (
																				<option value={o} key={i}>
																					{CASH_FLOW_TYPES[o]}
																				</option>
																			)
																		)}
																	</Select>
																</div>
																<div className="flex flex-col">
																	<input
																		placeholder="Montant"
																		className="rounded text-sm text-right"
																		type="text"
																		value={op.amount}
																		onChange={({ target }) => {
																			operations[index].amount = target.value;
																			setOperations([...operations]);
																		}}
																	/>
																</div>
															</div>
															<div className="mt-1">
																<input
																	className="rounded text-sm w-[100%]"
																	placeholder="Commentaire"
																	type="text"
																	value={op.comment}
																	onChange={({ target }) => {
																		operations[index].comment = target.value;
																		setOperations([...operations]);
																	}}
																/>
															</div>
														</div>
														{index === operations.length - 1 && (
															<>
																<ButtonCircle
																	className="transform ml-2 bg-slate-400 text-black hover:bg-slate-300"
																	size="w-6 h-6"
																	type="button"
																	onClick={() => {
																		addOperation();
																	}}
																>
																	<PlusIcon className="h-4 w-4" />
																</ButtonCircle>
																<ButtonCircle
																	className="transform ml-2 bg-slate-400 text-black hover:bg-slate-300"
																	size="w-6 h-6"
																	type="button"
																	onClick={() => {
																		operations.splice(index, 1);
																		setOperations([...operations]);
																	}}
																>
																	<MinusIcon className="h-4 w-4" />
																</ButtonCircle>
															</>
														)}
													</div>
												))}
											</div>

											<div className="flex flex-row items-center justify-between mb-1 border-bottom-1 p-2 gap-3">
												<div>
													<Label>&nbsp;</Label>
													<div className="flex flex-row items-center justify-between">
														<ButtonCircle
															className="transform ml-2 bg-slate-400 text-black hover:bg-slate-300"
															size="w-6 h-6"
															type="button"
															onClick={() => {
																saveOperations();
															}}
														>
															<CheckIcon className="h-4 w-6" />
														</ButtonCircle>
														<ButtonCircle
															className="transform ml-2 bg-danger text-black hover:bg-danger"
															size="w-6 h-6"
															type="button"
															onClick={() => {
																setOperations([]);
																setIsEdit(false);
															}}
														>
															<XMarkIcon className="h-4 w-6" />
														</ButtonCircle>
													</div>
												</div>
											</div>
										</>
									) : null}
									{list.map((line: any, ind) => {
										return (
											<div key={ind}>
												{ind === 0 && (
													<div
														key={ind}
														className="flex flex-row item-center justify-between mt-1.5 mb-4 border-b border-neutral-400"
													>
														<p className="font-semibold text-slate-900 text-sm">
															Fonds de caisse
														</p>
														<p className="text-slate-900 mt-0.5 text-sm flex gap-1 justify-end items-center text-right">
															{parseFloat(line.currentCashFund).toFixed(2)} MAD
														</p>
													</div>
												)}

												{line.operations.map((op: any, ind2: number) => (
													<div
														key={ind2}
														className="flex flex-row item-center justify-between mt-2.5 mb-1 border-b border-neutral-400"
													>
														<p className="font-semibold text-slate-900 text-sm">
															<span className="text-muted text-[11px]">
																{dayjs(line.date).format("DD/MM/YYYY HH:mm:ss")}
															</span>
															<br />
															{CASH_FLOW_TYPES[op.type]}
															{!!op.comment && (
																<span className="text-muted text-[11px]">
																	{" "}
																	- {op.comment}
																</span>
															)}{" "}
														</p>
														<p className="text-slate-900 mt-0.5 text-sm flex gap-1 justify-end items-center text-right">
															<span
																className={
																	op.type != "SALES"
																		? "text-danger"
																		: "text-success"
																}
															>
																{op.type != "SALES" ? "-" : ""}
																{parseFloat(op.amount).toFixed(2)} MAD
															</span>
														</p>
													</div>
												))}

												{ind === list.length - 1 && (
													<div className="flex flex-row item-center justify-between mt-5 mb-1">
														<p className="font-semibold text-slate-900 text-sm">
															Fonds de caisse initial
														</p>
														<p className="text-slate-900 mt-0.5 text-sm flex gap-1 justify-end items-center text-right">
															{line.previousCashFund} MAD
														</p>
													</div>
												)}
											</div>
										);
									})}
								</div>
							</div>
						</div>
					</Transition.Child>
				</div>
			</Dialog>
		</Transition>
	);
};

export default ModalCashFlow;
