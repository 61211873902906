import React, { FC } from "react";
import { Link } from "react-router-dom";
import NcImage from "shared/NcImage/NcImage";
import { Product } from "data/data";

export interface ProductCardProps {
	className?: string;
	data?: Product;
	isLiked?: boolean;
}

const ProductCard: FC<ProductCardProps> = ({
	className = "",
	data = {},
	isLiked,
}) => {
	const {
		name,
		price,
		description,
		sizes,
		variants,
		variantType,
		status,
		image,
	} = data;
	const [variantActive, setVariantActive] = React.useState(0);
	const [showModalQuickView, setShowModalQuickView] = React.useState(false);

	return (
		<>
			<div
				className={`nc-ProductCard relative flex flex-col bg-transparent ${className}`}
				data-nc-id="ProductCard"
			>
				<Link
					to={"/product-detail/" + name}
					className="absolute inset-0"
				></Link>

				<div className="relative flex-shrink-0 bg-slate-50 dark:bg-slate-300 rounded-3xl overflow-hidden z-1 group">
					<Link to={"/product-detail/" + name} className="block">
						<NcImage
							containerClassName="flex aspect-w-11 aspect-h-12 w-full h-0"
							src={process.env.REACT_APP_API_URL + "/" + image}
							className="object-cover w-full h-full drop-shadow-xl"
						/>
					</Link>

					{/* <ProductStatus status={status} /> */}

					{/* <LikeButton liked={isLiked} className="absolute top-3 right-3 z-10" /> */}

					{/* {sizes ? renderSizeList() : renderGroupButtons()} */}
				</div>

				<div className="space-y-4 px-2.5 pt-5 pb-2.5">
					<div className="flex justify-between items-end ">
						<h2
							className={`nc-ProductCard__title text-base font-semibold transition-colors`}
						>
							{name}
						</h2>
						{/* <Prices price={price} /> */}
					</div>
				</div>
			</div>
		</>
	);
};

export default ProductCard;
