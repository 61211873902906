import Label from "components/Label/Label";
import React, { FC, useRef, useState } from "react";
import ReactGoogleAutocomplete, {
	usePlacesWidget,
} from "react-google-autocomplete";
import { useDispatch, useSelector } from "react-redux";
import { saveShippingAddress, setDeliveryFees } from "redux/reducers/cart";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import ButtonSecondary from "shared/Button/ButtonSecondary";
import Input from "shared/Input/Input";
import Radio from "shared/Radio/Radio";
import Select from "shared/Select/Select";
import services from "utils/services";

interface Props {
	isActive: boolean;
	onCloseActive: () => void;
	onOpenActive: () => void;
}

const ShippingAddress: FC<Props> = ({
	isActive,
	onCloseActive,
	onOpenActive,
}) => {
	const cart = useSelector((state: any) => state.cart);
	const [error, setError] = useState<string>("");
	const [fees, setFees] = useState<any>(null);
	const [shippingAddress, setShippingAddress] = useState<any>(
		cart.customer.shippingAddress
	);

	const autocompteRef = useRef<HTMLInputElement | null>(null);
	const dispatch = useDispatch();

	const onSaveShippingAddress = () => {
		// services.getDeliveryFees(
		// 	shippingAddress,
		// 	(success) => {
		// 		services.getDeliveryFees(
		// 			shippingAddress,
		// 			(success) => {
		// 				if (success.deliveryFees) {
		dispatch(saveShippingAddress(shippingAddress));
		// dispatch(setDeliveryFees({ deliveryFees: success.deliveryFees }));
		onCloseActive();
		// 				} else {
		// 					setError(success.error);
		// 				}
		// 			},
		// 			(error) => {}
		// 		);
		// 	},
		// 	(error) => {
		// 		console.error("Impossible de calculer les frais de livraisons", error);
		// 		setError("Impossible de calculer les frais de livraisons");
		// 	}
		// );
	};

	const renderShippingAddress = () => {
		return (
			<div className="border border-slate-200 dark:border-slate-700 rounded-xl ">
				<div className="p-6 flex flex-col sm:flex-row items-start">
					<span className="hidden sm:block">
						<svg
							className="w-6 h-6 text-slate-700 dark:text-slate-400 mt-0.5"
							viewBox="0 0 24 24"
							fill="none"
							xmlns="http://www.w3.org/2000/svg"
						>
							<path
								d="M12.1401 15.0701V13.11C12.1401 10.59 14.1801 8.54004 16.7101 8.54004H18.6701"
								stroke="currentColor"
								strokeWidth="1.5"
								strokeLinecap="round"
								strokeLinejoin="round"
							/>
							<path
								d="M5.62012 8.55005H7.58014C10.1001 8.55005 12.1501 10.59 12.1501 13.12V13.7701V17.25"
								stroke="currentColor"
								strokeWidth="1.5"
								strokeLinecap="round"
								strokeLinejoin="round"
							/>
							<path
								d="M7.14008 6.75L5.34009 8.55L7.14008 10.35"
								stroke="currentColor"
								strokeWidth="1.5"
								strokeLinecap="round"
								strokeLinejoin="round"
							/>
							<path
								d="M16.8601 6.75L18.6601 8.55L16.8601 10.35"
								stroke="currentColor"
								strokeWidth="1.5"
								strokeLinecap="round"
								strokeLinejoin="round"
							/>
							<path
								d="M9 22H15C20 22 22 20 22 15V9C22 4 20 2 15 2H9C4 2 2 4 2 9V15C2 20 4 22 9 22Z"
								stroke="currentColor"
								strokeWidth="1.5"
								strokeLinecap="round"
								strokeLinejoin="round"
							/>
						</svg>
					</span>

					<div className="sm:ml-8">
						<h3 className=" text-slate-700 dark:text-slate-300 flex ">
							<span className="uppercase">Adresse de Livraison</span>
							<svg
								fill="none"
								viewBox="0 0 24 24"
								strokeWidth="2.5"
								stroke="currentColor"
								className="w-5 h-5 ml-3 text-slate-900 dark:text-slate-100"
							>
								<path
									strokeLinecap="round"
									strokeLinejoin="round"
									d="M4.5 12.75l6 6 9-13.5"
								/>
							</svg>
						</h3>
						<div className="font-semibold mt-1 text-sm">
							<span className="">
								{shippingAddress ? shippingAddress.formatted_address : ""}
							</span>
						</div>
					</div>
					<ButtonSecondary
						sizeClass="py-2 px-4 "
						fontSize="text-sm font-medium"
						className="bg-slate-50 dark:bg-slate-800 mt-5 sm:mt-0 sm:ml-auto !rounded-lg"
						onClick={onOpenActive}
					>
						Modifier
					</ButtonSecondary>
				</div>
				<div
					className={`border-t border-slate-200 dark:border-slate-700 px-6 py-7 space-y-4 sm:space-y-6 ${
						isActive ? "block" : "hidden"
					}`}
				>
					{/* ============ */}
					{/* <div className="grid grid-cols-1 sm:grid-cols-2 gap-4 sm:gap-3">
						<div>
							<Label className="text-sm">Nom</Label>
							<Input className="mt-1.5" defaultValue="Cole" />
						</div>
						<div>
							<Label className="text-sm">Prénom</Label>
							<Input className="mt-1.5" defaultValue="Enrico " />
						</div>
					</div> */}

					{/* ============ */}
					<div className="sm:flex space-y-4 sm:space-y-0 sm:space-x-3">
						<div className="flex-1">
							<Label className="text-sm">Adresse</Label>
							{/* <Input
								className="mt-1.5"
								placeholder=""
								defaultValue={"123, Dream Avenue, USA"}
								type={"text"}
							/> */}

							<ReactGoogleAutocomplete
								ref={autocompteRef}
								apiKey={process.env.REACT_APP_GOOGLE}
								className="block w-full border border-neutral-200 focus:border-primary-300 focus:ring focus:ring-primary-200 focus:ring-opacity-50 bg-white dark:border-neutral-700 dark:focus:ring-primary-6000 dark:focus:ring-opacity-25 dark:bg-neutral-900 disabled:bg-neutral-200 dark:disabled:bg-neutral-800 mt-1.5 h-11 px-4 py-3 rounded-2xl"
								onPlaceSelected={(place) => {
									//autocompleteRef.current.setValue(place?.formatted_address);
									const lAddress = {
										location: {
											coords: {
												latitude: place.geometry.location.lat(),
												longitude: place.geometry.location.lng(),
											},
										},
										formatted_address: place.formatted_address,
										address: "",
										country: "",
										city: "",
										city2: "",
										state: "",
										postalCode: "",
									};

									// Get each component of the address from the place details,
									// and then fill-in the corresponding field on the form.
									// place.address_components are google.maps.GeocoderAddressComponent objects
									// which are documented at http://goo.gle/3l5i5Mr
									for (const component of place.address_components) {
										// @ts-ignore remove once typings fixed
										const componentType = component.types[0];

										switch (componentType) {
											case "street_number": {
												lAddress.address = `${component.long_name} ${lAddress.address}`;
												break;
											}

											case "route": {
												lAddress.address += component.short_name;
												break;
											}

											case "postal_code": {
												lAddress.postalCode = `${component.long_name}${lAddress.postalCode}`;
												break;
											}

											case "postal_code_suffix": {
												lAddress.postalCode = `${lAddress.postalCode}-${component.long_name}`;
												break;
											}

											case "locality":
												lAddress.city = component.long_name;
												break;

											case "administrative_area_level_2": {
												if (component.types.includes("political")) {
													lAddress.city2 = component.long_name;
												}
												break;
											}
											case "administrative_area_level_1": {
												break;
											}

											case "country":
												lAddress.country = component.long_name;
												break;
										}
									}
									if (autocompteRef && autocompteRef.current) {
										autocompteRef.current.value = lAddress.address;
									}
									setShippingAddress(lAddress);
								}}
								onChange={({ target }: React.ChangeEvent<HTMLInputElement>) => {
									setShippingAddress({ address: target.value });
								}}
								defaultValue={
									shippingAddress && shippingAddress.address
										? shippingAddress.address
										: ""
								}
								inputAutocompleteValue={
									shippingAddress && shippingAddress.address
										? shippingAddress.address
										: ""
								}
								options={{
									fields: [
										"address_components",
										"geometry",
										"formatted_address",
									],
									types: ["address"],
								}}
							/>
						</div>
						{/* <div className="sm:w-1/3">
							<Label className="text-sm">Apt, Suite *</Label>
							<Input className="mt-1.5" defaultValue="55U - DD5 " />
						</div> */}
					</div>

					{/* ============ */}
					<div className="grid grid-cols-1 sm:grid-cols-2 gap-4 sm:gap-3">
						<div>
							<Label className="text-sm">Ville</Label>
							<Input
								className="mt-1.5"
								value={
									shippingAddress &&
									(shippingAddress.city || shippingAddress.city2)
										? shippingAddress.city || shippingAddress.city2
										: ""
								}
							/>
						</div>
						<div>
							<Label className="text-sm">Pays</Label>
							{/* <Select className="mt-1.5" defaultValue="United States ">
								<option value="United States">United States</option>
								<option value="United States">Canada</option>
								<option value="United States">Mexico</option>
								<option value="United States">Israel</option>
								<option value="United States">France</option>
								<option value="United States">England</option>
								<option value="United States">Laos</option>
								<option value="United States">China</option>
							</Select> */}

							<Input
								className="mt-1.5"
								value={
									shippingAddress && shippingAddress.country
										? shippingAddress.country
										: ""
								}
							/>
						</div>
					</div>

					{/* ============ */}
					<div className="grid grid-cols-1 sm:grid-cols-2 gap-4 sm:gap-3">
						{/* <div>
							<Label className="text-sm">State/Province</Label>
							<Input className="mt-1.5" defaultValue="Texas" />
						</div> */}
						<div>
							<Label className="text-sm">Code postal</Label>
							<Input
								className="mt-1.5"
								value={
									shippingAddress && shippingAddress.postalCode
										? shippingAddress.postalCode
										: ""
								}
							/>
						</div>
					</div>

					{/* ============ */}
					{/* <div>
						<Label className="text-sm">Address type</Label>
						<div className="mt-1.5 grid grid-cols-1 sm:grid-cols-2 gap-2 sm:gap-3">
							<Radio
								label={`<span class="text-sm font-medium">Home <span class="font-light">(All Day Delivery)</span></span>`}
								id="Address-type-home"
								name="Address-type"
								defaultChecked
							/>
							<Radio
								label={`<span class="text-sm font-medium">Office <span class="font-light">(Delivery <span class="font-medium">9 AM - 5 PM</span>)</span> </span>`}
								id="Address-type-office"
								name="Address-type"
							/>
						</div>
					</div> */}

					{/* ============ */}
					{!!error && <div className="font-medium">{error}</div>}
					<div className="flex flex-col sm:flex-row pt-6">
						<ButtonPrimary
							className="sm:!px-7 shadow-none"
							onClick={onSaveShippingAddress}
						>
							Enregistrer
						</ButtonPrimary>
						<ButtonSecondary
							className="mt-3 sm:mt-0 sm:ml-3"
							onClick={onCloseActive}
						>
							Annuler
						</ButtonSecondary>
					</div>
				</div>
			</div>
		);
	};
	return renderShippingAddress();
};

export default ShippingAddress;
