import React, { FC, useState } from "react";
import CommonLayout from "./CommonLayout";
import { Helmet } from "react-helmet-async";
import SettingsUserList from "./SettingsUserList";
import SettingsGalleryList from "./SettingsGalleryList";
import SettingsProviderList from "./SettingsProviderList";

export interface SettingsPageProps {
	className?: string;
}

const SettingsPage: FC<SettingsPageProps> = ({ className = "" }) => {
	const [selectedTab, setSelectedTab] = useState<String>("Galeries");
	return (
		<div className={`nc-SettingsPage ${className}`} data-nc-id="SettingsPage">
			<Helmet>
				<title>Paramètres || IRIS GALERIE MAROC</title>
			</Helmet>
			<CommonLayout onSelectTab={(tab: String) => setSelectedTab(tab)}>
				<div className="space-y-10 sm:space-y-12">
					{/* HEADING */}
					{selectedTab == "Galeries" && <SettingsGalleryList />}
					{selectedTab == "Utilisateurs" && <SettingsUserList />}
					{selectedTab == "Apporteurs d'affaires" && <SettingsProviderList />}
				</div>
			</CommonLayout>
		</div>
	);
};

export default SettingsPage;
