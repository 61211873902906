import Label from "components/Label/Label";
import React, { FC, useState } from "react";
import Input from "shared/Input/Input";
import Select from "shared/Select/Select";

export interface UserForm {
	className?: string;
	user?: any;
	setUser?: any;
	errors?: any;
	galleries?: [];
}

const UserForm: FC<UserForm> = ({ user, setUser, errors, galleries }) => {
	return (
		<div className="flex flex-col md:flex-row">
			<div className="flex-grow mt-10 md:mt-0 space-y-6">
				<div className="flex flex-col md:flex-row">
					<div className="mt-10 md:mt-0 space-y-1  flex-1">
						<Label>Nom</Label>
						<Input
							className={
								"mt-1.5" +
								(errors["lastName"] !== undefined ? " border-danger" : "")
							}
							value={user.lastName}
							onChange={({ target }) =>
								setUser({ ...user, lastName: target.value })
							}
						/>
					</div>
					<div className="mt-10 md:mt-0 md:pl-5 space-y-1  flex-1">
						<Label>Prénom</Label>
						<Input
							className={
								"mt-1.5" +
								(errors["firstName"] !== undefined ? " border-danger" : "")
							}
							value={user.firstName}
							onChange={({ target }) =>
								setUser({ ...user, firstName: target.value })
							}
						/>
					</div>
				</div>
				<div className="flex flex-col md:flex-row">
					<div className="mt-10 md:mt-0 space-y-1  flex-1">
						<Label>Nom d'utilisateur</Label>
						<div className="mt-1.5 flex">
							<Input
								className={
									"mt-1.5" +
									(errors["username"] !== undefined ? " border-danger" : "")
								}
								value={user.username}
								onChange={({ target }) =>
									setUser({ ...user, username: target.value })
								}
							/>
						</div>
					</div>
				</div>

				{/* <div className="flex flex-col md:flex-row">
					<div className="mt-10 md:mt-0 space-y-1 flex-1">
						<Label>Email</Label>
						<div className="mt-1.5 flex">
							<span
								className={
									"inline-flex items-center px-2.5 rounded-l-2xl border border-r-0 border-neutral-200 dark:border-neutral-700 bg-neutral-50 dark:bg-neutral-800 text-neutral-500 dark:text-neutral-400 text-sm" +
									(errors["email"] !== undefined ? " border-danger" : "")
								}
							>
								<i className="text-2xl las la-envelope" />
							</span>
							<Input
								className={
									"!rounded-l-none" +
									(errors["email"] !== undefined ? " border-danger" : "")
								}
								placeholder="example@email.com"
								value={user.email}
								onChange={({ target }) =>
									setUser({ ...user, email: target.value })
								}
							/>
						</div>
						{!!errors && !!errors.email && (
							<span className="text-sm ">{errors.email}</span>
						)}
					</div>
					<div className="mt-10 md:mt-0 md:pl-5 space-y-1  flex-1">
						<Label>Téléphone</Label>
						<div className="mt-1.5 flex">
							<span
								className={
									"inline-flex items-center px-2.5 rounded-l-2xl border border-r-0 border-neutral-200 dark:border-neutral-700 bg-neutral-50 dark:bg-neutral-800 text-neutral-500 dark:text-neutral-400 text-sm" +
									(errors["telephone"] !== undefined ? " border-danger" : "")
								}
							>
								<i className="text-2xl las la-phone-volume"></i>
							</span>
							<Input
								className={
									"!rounded-l-none" +
									(errors["telephone"] !== undefined ? " border-danger" : "")
								}
								value={user.telephone}
								onChange={({ target }) =>
									setUser({ ...user, telephone: target.value })
								}
							/>
						</div>
					</div>
				</div> */}

				{user.role != "ADMIN" && (
					<div className="mt-10 md:mt-0 space-y-1  flex-1">
						<Label>Galerie</Label>

						<Select
							className={
								"mt-1.5" +
								(errors["gallery"] !== undefined ? " border-danger" : "")
							}
							placeholder=""
							value={user.gallery}
							onChange={({ target }) =>
								setUser({ ...user, gallery: target.value })
							}
						>
							<option value=""></option>
							{galleries?.map((g: any) => (
								<option value={g._id}>{g.name}</option>
							))}
						</Select>
					</div>
				)}

				{/* <div className="flex flex-col md:flex-row">
					<div className="mt-10 md:mt-0 space-y-1  flex-1">
						<Label>Mot de passe</Label>
						<div className="mt-1.5 flex">
							<span
								className={
									"inline-flex items-center px-2.5 rounded-l-2xl border border-r-0 border-neutral-200 dark:border-neutral-700 bg-neutral-50 dark:bg-neutral-800 text-neutral-500 dark:text-neutral-400 text-sm" +
									(errors["password"] !== undefined ? " border-danger" : "")
								}
							>
								<i className="text-2xl las la-lock"></i>
							</span>
							<Input
								className={
									"!rounded-l-none" +
									(errors["password"] !== undefined ? " border-danger" : "")
								}
								type="password"
								value={user.password}
								onChange={({ target }) =>
									setUser({ ...user, password: target.value })
								}
							/>
						</div>
					</div>
				</div> */}
				{/* <div className="mt-10 md:mt-0 space-y-1  flex-1">
					<Label>Role</Label>

					<Select
						className={
							"mt-1.5" + (errors["role"] !== undefined ? " border-danger" : "")
						}
						placeholder=""
						value={user.role}
						onChange={({ target }) => setUser({ ...user, role: target.value })}
					> */}
				{/* <option value=""></option> */}
				{/* <option value="ADMIN">Administrateur</option> */}
				{/* <option value="MANAGER">Manager</option> */}
				{/* <option value="ASSISTANT">Assistant</option>
					</Select>
				</div> */}

				<div className="mt-10 md:mt-0 space-y-1 flex-1">
					<Label>Actif / Inactif</Label>

					<Select
						className="mt-1.5"
						placeholder=""
						value={user.enabled}
						onChange={({ target }) =>
							setUser({ ...user, enabled: target.value })
						}
					>
						<option value="true">Actif</option>
						<option value="false">Inactif</option>
					</Select>
				</div>
			</div>
		</div>
	);
};

export default UserForm;
