import { useAuth } from "../provider/useAuth";
import React, { FC, ReactNode } from "react";
import { Navigate } from "react-router-dom";

export const ProtectedRoute: FC<any> = ({ children }) => {
	const { token } = useAuth();

	if (!token) {
		return <Navigate to="/login" replace />;
	}

	return children;
};
