import React, { FC } from "react";
import Logo from "shared/Logo/Logo";
import MenuBar from "shared/MenuBar/MenuBar";
import CartDropdown from "./CartDropdown";
import { useSelector } from "react-redux";
import SettingsDropdown from "./SettingsDropdown";
import { useAuth } from "provider/useAuth";
import { Link } from "react-router-dom";

export interface MainNav2LoggedProps {}

const MainNav2Logged: FC<MainNav2LoggedProps> = () => {
	const cart = useSelector((state: any) => state.cart);

	const authContext = useAuth();

	// const renderSearchForm = () => {
	// 	return (
	// 		<form
	// 			onSubmit={(e) => {
	// 				e.preventDefault();
	// 				navigate("/page-search");
	// 			}}
	// 			className="flex-1 py-2 text-slate-900 dark:text-slate-100"
	// 		>
	// 			<div className="bg-slate-50 dark:bg-slate-800 flex items-center space-x-1.5 px-5 h-full rounded">
	// 				{renderMagnifyingGlassIcon()}
	// 				<input
	// 					ref={inputRef}
	// 					type="text"
	// 					placeholder="Type and press enter"
	// 					className="border-none bg-transparent focus:outline-none focus:ring-0 w-full text-base"
	// 					autoFocus
	// 				/>
	// 				<button type="button" onClick={() => setShowSearchForm(false)}>
	// 					<XMarkIcon className="w-5 h-5" />
	// 				</button>
	// 			</div>
	// 			<input type="submit" hidden value="" />
	// 		</form>
	// 	);
	// };

	const renderContent = () => {
		return (
			<div className="h-20 flex justify-between">
				<div className="flex items-center lg:hidden flex-1">
					<MenuBar />
				</div>

				<div className="lg:flex-1 flex items-center">
					<Logo className="flex-shrink-0" />
				</div>

				<div className="flex-[2] hidden lg:flex justify-center mx-4">
					{/* {showSearchForm ? renderSearchForm() : <Navigation />} */}
				</div>

				<div className="flex-1 flex items-center justify-end text-slate-700 dark:text-slate-100">
					{/* {!showSearchForm && (
						<button
							className="hidden lg:flex w-10 h-10 sm:w-12 sm:h-12 rounded-full text-slate-700 dark:text-slate-300 hover:bg-slate-100 dark:hover:bg-slate-800 focus:outline-none items-center justify-center"
							onClick={() => setShowSearchForm(!showSearchForm)}
						>
							{renderMagnifyingGlassIcon()}
						</button>
					)} */}
					{/* <AvatarDropdown /> */}

					{cart.items.length > 0 && <CartDropdown />}
					{!!authContext.user && authContext.user.role === "ADMIN" && (
						<>
							<Link
								title="Stocks"
								to={"/stocks"}
								className="w-10 h-10 sm:w-12 sm:h-12 rounded-full text-slate-700 dark:text-slate-300 hover:bg-slate-100 dark:hover:bg-slate-800 focus:outline-none flex items-center justify-center"
							>
								<div className="flex items-center justify-center flex-shrink-0 text-slate-700 dark:text-slate-300">
									<svg
										xmlns="http://www.w3.org/2000/svg"
										fill="none"
										viewBox="0 0 24 24"
										strokeWidth="1.5"
										stroke="currentColor"
										className="w-6 h-6"
									>
										<path
											strokeLinecap="round"
											strokeLinejoin="round"
											d="M9 12h3.75M9 15h3.75M9 18h3.75m3 .75H18a2.25 2.25 0 0 0 2.25-2.25V6.108c0-1.135-.845-2.098-1.976-2.192a48.424 48.424 0 0 0-1.123-.08m-5.801 0c-.065.21-.1.433-.1.664 0 .414.336.75.75.75h4.5a.75.75 0 0 0 .75-.75 2.25 2.25 0 0 0-.1-.664m-5.8 0A2.251 2.251 0 0 1 13.5 2.25H15c1.012 0 1.867.668 2.15 1.586m-5.8 0c-.376.023-.75.05-1.124.08C9.095 4.01 8.25 4.973 8.25 6.108V8.25m0 0H4.875c-.621 0-1.125.504-1.125 1.125v11.25c0 .621.504 1.125 1.125 1.125h9.75c.621 0 1.125-.504 1.125-1.125V9.375c0-.621-.504-1.125-1.125-1.125H8.25ZM6.75 12h.008v.008H6.75V12Zm0 3h.008v.008H6.75V15Zm0 3h.008v.008H6.75V18Z"
										/>
									</svg>
								</div>
							</Link>

							<Link
								title="Dashboard"
								to={"/dashboard"}
								className="w-10 h-10 sm:w-12 sm:h-12 rounded-full text-slate-700 dark:text-slate-300 hover:bg-slate-100 dark:hover:bg-slate-800 focus:outline-none flex items-center justify-center"
							>
								<div className="flex items-center justify-center flex-shrink-0 text-slate-700 dark:text-slate-300">
									<svg
										xmlns="http://www.w3.org/2000/svg"
										fill="none"
										viewBox="0 0 24 24"
										strokeWidth="1.5"
										stroke="currentColor"
										className="w-6 h-6"
									>
										<path
											strokeLinecap="round"
											strokeLinejoin="round"
											d="M3 13.125C3 12.504 3.504 12 4.125 12h2.25c.621 0 1.125.504 1.125 1.125v6.75C7.5 20.496 6.996 21 6.375 21h-2.25A1.125 1.125 0 013 19.875v-6.75zM9.75 8.625c0-.621.504-1.125 1.125-1.125h2.25c.621 0 1.125.504 1.125 1.125v11.25c0 .621-.504 1.125-1.125 1.125h-2.25a1.125 1.125 0 01-1.125-1.125V8.625zM16.5 4.125c0-.621.504-1.125 1.125-1.125h2.25C20.496 3 21 3.504 21 4.125v15.75c0 .621-.504 1.125-1.125 1.125h-2.25a1.125 1.125 0 01-1.125-1.125V4.125z"
										/>
									</svg>
								</div>
							</Link>

							<SettingsDropdown />
						</>
					)}
					{!!authContext.user && (
						<Link
							to={"/#"}
							className="w-10 h-10 sm:w-12 sm:h-12 rounded-full text-slate-700 dark:text-slate-300 hover:bg-slate-100 dark:hover:bg-slate-800 focus:outline-none flex items-center justify-center rotate-180"
							onClick={() => {
								authContext.onLogout();
							}}
						>
							<div className="flex items-center justify-center flex-shrink-0 text-slate-700 dark:text-slate-300">
								<svg
									width="24"
									height="24"
									viewBox="0 0 24 24"
									fill="none"
									xmlns="http://www.w3.org/2000/svg"
								>
									<path
										d="M8.90002 7.55999C9.21002 3.95999 11.06 2.48999 15.11 2.48999H15.24C19.71 2.48999 21.5 4.27999 21.5 8.74999V15.27C21.5 19.74 19.71 21.53 15.24 21.53H15.11C11.09 21.53 9.24002 20.08 8.91002 16.54"
										stroke="currentColor"
										strokeWidth="1.5"
										strokeLinecap="round"
										strokeLinejoin="round"
									/>
									<path
										d="M15 12H3.62"
										stroke="currentColor"
										strokeWidth="1.5"
										strokeLinecap="round"
										strokeLinejoin="round"
									/>
									<path
										d="M5.85 8.6499L2.5 11.9999L5.85 15.3499"
										stroke="currentColor"
										strokeWidth="1.5"
										strokeLinecap="round"
										strokeLinejoin="round"
									/>
								</svg>
							</div>
						</Link>
					)}
				</div>
			</div>
		);
	};

	return (
		<div className="nc-MainNav2Logged relative z-10 bg-white dark:bg-neutral-900 border-b border-slate-100 dark:border-slate-700">
			<div className="container ">{renderContent()}</div>
		</div>
	);
};

export default MainNav2Logged;
