import React, { useState } from "react";
import { FC } from "react";
import { NavLink } from "react-router-dom";

export interface CommonLayoutProps {
	onSelectTab?: any;
	children?: React.ReactNode;
}

const CommonLayout: FC<CommonLayoutProps> = ({ children, onSelectTab }) => {
	const [selected, setSelected] = useState("Galeries");
	return (
		<div className="nc-CommonLayoutProps container">
			<div className="mt-10 sm:mt-15">
				<div className="max-w-4xl mx-auto">
					<div className="max-w-2xl">
						<h2 className="text-3xl xl:text-4xl font-semibold">Paramètres</h2>
					</div>
					<hr className="mt-10 border-slate-200 dark:border-slate-700"></hr>

					<div className="flex space-x-8 md:space-x-14 overflow-x-auto hiddenScrollbar">
						{[
							{
								name: "Galeries",
								link: "/galeries",
							},
							{
								name: "Utilisateurs",
								link: "/utilisateurs",
							},
							{
								name: "Apporteurs d'affaires",
								link: "/apporteurs",
							},
							// {
							// 	name: "Save lists",
							// 	link: "/account-savelists",
							// },
							// {
							// 	name: " My order",
							// 	link: "/account-my-order",
							// },
							// {
							// 	name: "Change password",
							// 	link: "/account-change-password",
							// },
							// {
							// 	name: "Change Billing",
							// 	link: "/account-billing",
							// },
						].map((item, index) => (
							<NavLink
								onClick={() => {
									setSelected(item.name);
									onSelectTab(item.name);
								}}
								key={index}
								to={"#"}
								className={({ isActive }) =>
									`block py-5 md:py-8 border-b-2 border-transparent flex-shrink-0  text-sm sm:text-base ${
										selected == item.name
											? "border-primary-500 font-medium text-slate-900 dark:text-slate-200"
											: "text-slate-500 dark:text-slate-400 hover:text-slate-800 dark:hover:text-slate-200"
									}`
								}
							>
								{item.name}
							</NavLink>
						))}
					</div>
					<hr className="border-slate-200 dark:border-slate-700"></hr>
				</div>
			</div>
			<div className="max-w-4xl mx-auto pt-2 pb-2">{children}</div>
		</div>
	);
};

export default CommonLayout;
