import React, { useEffect, useState } from "react";
import BackgroundSection from "components/BackgroundSection/BackgroundSection";
import services from "utils/services";
import dayjs from "dayjs";
import Input from "shared/Input/Input";
import ButtonCircle from "shared/Button/ButtonCircle";
import Label from "components/Label/Label";
import ModalDetailsCharges from "components/ModalDetailsCharges";
import { Link } from "react-router-dom";
import { ArrowsRightLeftIcon } from "@heroicons/react/24/solid";
import ModalCashFlow from "components/ModalCashFlow";
import ShareSelect from "shared/Select/Select";

function Dashboard() {
	const [statistics, setStatistics] = useState<any>({});
	const [dateFrom, setDateFrom] = useState<string>(
		dayjs().set("date", 1).format("YYYY-MM-DD")
	);
	const [dateTo, setDateTo] = useState<string>(
		dayjs().set("date", dayjs().daysInMonth()).format("YYYY-MM-DD")
	);
	const [showDetailsCharges, setShowDetailsCharges] = useState(false);
	const [showCashFlowModal, setShowCashFlowModal] = useState(false);
	const [galleries, setGalleries] = useState<any>([]);
	const [selectedGallery, setSelectedGallery] = useState<any>(null);

	useEffect(() => {
		loadGalleries();
		loadStatistics(dateFrom, dateTo);
	}, []);

	const loadGalleries = () => {
		services.getGalleries(
			{},
			(res) => {
				setGalleries(res.galleries);
			},
			(err) => {
				console.error(err);
			}
		);
	};

	const loadStatistics = (dateFrom: string, dateTo: string) => {
		services.getStatitics(
			{
				dateFrom,
				dateTo,
				gallery: selectedGallery,
			},
			(success) => {
				setStatistics(success.statistics);
			},
			(error) => {
				console.error(error);
			}
		);
	};

	const updateCharges = (charges: any[]) => {
		services.saveCharges(
			{ expenses: charges, gallery: selectedGallery },
			(success) => {
				loadStatistics(dateFrom, dateTo);
			},
			(error) => {
				console.error(error);
			}
		);
	};

	const renderLine = (line: any, index: number) => {
		return (
			<tr
				className={
					"border-y-2 border-slate-200 dark:border-slate-700 hover:bg-neutral-100 "
				}
				key={index}
			>
				<td className="py-2 text-center">
					{dayjs(line.date).format("DD/MM/YYYY")}
				</td>
				<td className="py-2 text-right">
					{new Intl.NumberFormat("fr-FR", {
						style: "currency",
						currency: "MAD",
					}).format(line.totalCA)}
				</td>
				<td className="py-2 text-right">
					{new Intl.NumberFormat("fr-FR", {
						style: "currency",
						currency: "MAD",
					}).format(line.discountAmount)}
				</td>
				<td className="py-2 text-right">
					{new Intl.NumberFormat("fr-FR", {
						style: "currency",
						currency: "MAD",
					}).format(line.deliveryFees)}
				</td>
				{/* <td className="py-2 text-right">{line.totalMaroc}</td> */}
				<td className="py-2 text-right">
					{new Intl.NumberFormat("fr-FR", {
						style: "currency",
						currency: "MAD",
					}).format(line.commissionIRIS)}
				</td>
				<td className="py-2 text-right">
					{new Intl.NumberFormat("fr-FR", {
						style: "currency",
						currency: "MAD",
					}).format(line.totalCA - line.commissionIRIS - line.deliveryFees)}
				</td>
			</tr>
		);
	};

	return (
		<div className="nc-PageHome relative overflow-hidden">
			{/* SECTION HERO */}
			{/* <SectionHero2 /> */}
			<div className="container  py-8 lg:py-5 flex flex-col justify-center gap-3 relative my-5 flex-wrap ">
				<BackgroundSection className="bg-neutral-50/70 dark:bg-black/20" />
				<div className="flex flex-row gap-3 mt-5 items-center relative justify-center">
					<div className="max-w-lg items-center">
						<Label className="text-neutral-800 text-sm">Entre: </Label>
						<div className="mt-1.5 flex">
							<span className="inline-flex items-center px-2.5 rounded-md !rounded-r-none border border-r-0 border-neutral-200 dark:border-neutral-700 bg-neutral-50 dark:bg-neutral-800 text-neutral-500 dark:text-neutral-400 text-sm">
								<i className="text-2xl las la-calendar"></i>
							</span>
							<Input
								className=" rounded-md !rounded-l-none"
								type="date"
								value={dateFrom}
								onChange={({ target }) => {
									setDateFrom(target.value);
								}}
							/>
						</div>
					</div>
					<div className="max-w-lg items-center">
						<Label className="text-neutral-800 text-sm">Et : </Label>
						<div className="mt-1.5 flex">
							<span className="inline-flex items-center px-2.5 rounded-md !rounded-r-none border border-r-0 border-neutral-200 dark:border-neutral-700 bg-neutral-50 dark:bg-neutral-800 text-neutral-500 dark:text-neutral-400 text-sm">
								<i className="text-2xl las la-calendar"></i>
							</span>
							<Input
								className="rounded-md !rounded-l-none"
								type="date"
								value={dateTo}
								onChange={({ target }) => {
									setDateTo(target.value);
								}}
							/>
						</div>
					</div>
					<div className="max-w-lg items-center">
						<Label className="text-neutral-800 text-sm">Gallerie :</Label>
						<ShareSelect
							// sizeClass="h-10"
							className="rounded-md w-[250px] mt-1.5"
							value={selectedGallery ? selectedGallery._id : ""}
							onChange={({ target }: { target: any }) => {
								if (!target.value) {
									setSelectedGallery(null);
								} else {
									setSelectedGallery(target.value);
								}
							}}
							placeholder=""
						>
							<option value=""></option>
							{galleries.map((g: any, ind: number) => (
								<option value={g._id} key={ind}>
									{g.name}
								</option>
							))}
						</ShareSelect>
					</div>
					<ButtonCircle
						style={{ marginTop: 20 }}
						className="transform"
						size="w-8 h-8"
						type="button"
						onClick={() => loadStatistics(dateFrom, dateTo)}
					>
						<svg
							className="h-4 w-4"
							viewBox="0 0 24 24"
							fill="none"
							xmlns="http://www.w3.org/2000/svg"
						>
							<path
								d="M11.5 21C16.7467 21 21 16.7467 21 11.5C21 6.25329 16.7467 2 11.5 2C6.25329 2 2 6.25329 2 11.5C2 16.7467 6.25329 21 11.5 21Z"
								stroke="currentColor"
								strokeWidth="1.5"
								strokeLinecap="round"
								strokeLinejoin="round"
							/>
							<path
								d="M22 22L20 20"
								stroke="currentColor"
								strokeWidth="1.5"
								strokeLinecap="round"
								strokeLinejoin="round"
							/>
						</svg>
					</ButtonCircle>
				</div>

				<div className="flex flex-row sm:flex-row gap-2 flex-1 items-center justify-center">
					<div
						className={`flex flex-row p-5 rounded-2xl bg-neutral-100 dark:bg-opacity-90 relative items-center justify-center`}
					>
						<div className="mt-2.5 text-center flex-1">
							<p className="font-bold text-slate-900 text-xl mb-2">
								{new Intl.NumberFormat("fr-FR", {
									style: "currency",
									currency: "MAD",
								}).format(statistics.totalCA)}
							</p>
							{/* <p className="font-bold text-slate-500 text-xl mb-2">
								({statistics.total} -{" "}
								{(
									(parseFloat(statistics.total) /
										(parseFloat(statistics.totalVues) +
											parseFloat(statistics.total))) *
									100
								).toFixed()}
								{"%"})
							</p> */}
							<p className="text-slate-500 mt-0.5 text-sm text-center">
								Total CA ht
							</p>
							<p className="text-slate-500 mt-0.5 text-sm text-center">
								Total remise :{" "}
								{new Intl.NumberFormat("fr-FR", {
									style: "currency",
									currency: "MAD",
								}).format(statistics.totalDiscount)}
							</p>

							{/* {!!statistics.salesByCountries && (
								<p className="text-slate-500 mt-0.5 text-sm text-center">
									Maroc : {statistics.salesByCountries["maroc"].total} (
									{statistics.salesByCountries["maroc"].vues})<br />
									France : {statistics.salesByCountries["france"].total} (
									{statistics.salesByCountries["france"].vues})<br />
									Autres : {statistics.salesByCountries["others"].total} (
									{statistics.salesByCountries["others"].vues})<br />
								</p>
							)} */}
						</div>
					</div>
					<div
						className={`flex flex-row gap-5 p-5 rounded-2xl bg-neutral-100 dark:bg-opacity-90 relative items-center justify-center`}
					>
						<div className="mt-2.5 text-center flex-1">
							<p className="font-bold text-slate-900 text-xl mb-2">
								{new Intl.NumberFormat("fr-FR", {
									style: "currency",
									currency: "MAD",
								}).format(
									statistics.totalCommissionIRIS + statistics.totalDelivery
								)}
							</p>
							<p className="text-slate-500 mt-0.5 text-sm text-center">
								Total à reverser à IRIS
							</p>

							<p className="text-slate-500 mt-0.5 text-sm text-center">
								Commission :{" "}
								{new Intl.NumberFormat("fr-FR", {
									style: "currency",
									currency: "MAD",
								}).format(statistics.totalCommissionIRIS)}{" "}
							</p>

							<p className="text-slate-500 mt-0.5 text-sm text-center">
								Frais de livraison :{" "}
								{new Intl.NumberFormat("fr-FR", {
									style: "currency",
									currency: "MAD",
								}).format(statistics.totalDelivery)}
							</p>
						</div>
					</div>
					<div
						className={`flex flex-row gap-5 p-5 rounded-2xl bg-neutral-100 dark:bg-opacity-90 relative items-center justify-center`}
					>
						<div className="mt-2.5 text-center flex-1">
							<p className="font-bold text-slate-900 text-xl mb-2">
								{new Intl.NumberFormat("fr-FR", {
									style: "currency",
									currency: "MAD",
								}).format(
									statistics.totalCA -
										(statistics.totalCommissionIRIS + statistics.totalDelivery)
								)}
							</p>
							<p className="text-slate-500 mt-0.5 text-sm text-center">
								Total NET
							</p>
						</div>
					</div>
					{!!statistics?.charges && (
						<div
							className={`flex flex-row gap-5 p-5 rounded-2xl bg-neutral-100 dark:bg-opacity-90 relative items-center justify-center`}
						>
							<div className="mt-2.5 text-center flex-1">
								<p
									className={
										"font-bold text-xl mb-2 " +
										(statistics.resultat >= 0
											? "text-green-500"
											: "text-danger")
									}
								>
									{new Intl.NumberFormat("fr-FR", {
										style: "currency",
										currency: "MAD",
									}).format(statistics.resultat)}
								</p>
								<p className="flex item-center justify-center text-slate-500 mt-0.5 text-sm text-center">
									Résultat{" "}
									<button
										className={`ml-1 w-5 h-5 rounded-full text-neutral-700 dark:text-slate-200 bg-none nc-shadow-lg`}
										onClick={() => setShowDetailsCharges(true)}
									>
										<svg
											xmlns="http://www.w3.org/2000/svg"
											fill="none"
											viewBox="0 0 24 24"
											strokeWidth="1.5"
											stroke="currentColor"
											className="w-5 h-5"
										>
											<path
												strokeLinecap="round"
												strokeLinejoin="round"
												d="M11.25 11.25l.041-.02a.75.75 0 011.063.852l-.708 2.836a.75.75 0 001.063.853l.041-.021M21 12a9 9 0 11-18 0 9 9 0 0118 0zm-9-3.75h.008v.008H12V8.25z"
											/>
										</svg>
									</button>
								</p>
							</div>
						</div>
					)}
				</div>
				{!!selectedGallery && (
					<div className="flex flex-row z-10 items-center">
						<ArrowsRightLeftIcon className="h-5 w-5" />
						<p className="text-slate-900  underline">
							<Link
								to={""}
								onClick={() => {
									setShowCashFlowModal(true);
								}}
							>
								Mouvement de caisse
							</Link>
						</p>
					</div>
				)}
			</div>

			<div className="container relative space-y-10 my-10">
				<BackgroundSection className="bg-neutral-50/70 dark:bg-black/20" />
				<div className="relative py-8 lg:py-5">
					<h3 className="text-center text-lg font-semibold text-neutral-900 dark:text-neutral-200 mb-4">
						Répartition du CA par jour
					</h3>
					<table className="w-[100%] table-auto text-neutral-700">
						<thead>
							<th>Date</th>
							<th className=" text-neutral-700">Total (hors livraison)</th>
							<th className=" text-neutral-700">Total Remise</th>
							<th className=" text-neutral-700">Total Livraison</th>
							{/* <th>Total Maroc</th> */}
							<th>Commission IRIS</th>
							<th>Total Net</th>
						</thead>
						<tbody>
							{statistics?.statisticsByDay?.map((line: any, ind: number) =>
								renderLine(line, ind)
							)}
						</tbody>
					</table>
					{/* <div className="grid grid-cols-1 md:grid-cols-2 sm:grid-cols-2 mt-5 gap-3 flex-row-3">
						{orders.map((o: any, ind: number) => renderOrder(o, ind))}
					</div> */}
				</div>
			</div>

			{!!statistics?.charges && showDetailsCharges && (
				<ModalDetailsCharges
					gallery={selectedGallery}
					charges={statistics?.charges}
					show={showDetailsCharges}
					dateExp={dateFrom}
					onSaveCharges={(editCharges) => updateCharges(editCharges)}
					onCloseModal={() => setShowDetailsCharges(false)}
				/>
			)}

			{!!selectedGallery && showCashFlowModal && (
				<ModalCashFlow
					gallery={selectedGallery}
					show={showCashFlowModal}
					onSave={() => {}}
					onCloseModal={() => setShowCashFlowModal(false)}
				/>
			)}
		</div>
	);
}

export default Dashboard;
