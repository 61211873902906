import React, { FC, useEffect, useRef } from "react";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import ButtonSecondary from "shared/Button/ButtonSecondary";
import NcModal from "shared/NcModal/NcModal";

export interface ModalAlertProps {
	show: boolean;
	title: string;
	text: string;
	confirmText?: string;
	onConfirm?: () => void;
	closeText: string;
	onCloseModal: () => void;
}

const ModalAlert: FC<ModalAlertProps> = ({
	show,
	title,
	text,
	confirmText,
	onConfirm,
	closeText,
	onCloseModal,
}) => {
	const textareaRef = useRef(null);

	useEffect(() => {
		if (show) {
			setTimeout(() => {
				const element: HTMLTextAreaElement | null = textareaRef.current;
				if (element) {
					(element as HTMLTextAreaElement).focus();
					(element as HTMLTextAreaElement).setSelectionRange(
						(element as HTMLTextAreaElement).value.length,
						(element as HTMLTextAreaElement).value.length
					);
				}
			}, 400);
		}
	}, [show]);

	const renderContent = () => {
		return (
			<form action="#">
				<h3 className="text-center text-lg font-semibold text-neutral-900 dark:text-neutral-200">
					{title}
				</h3>
				<div className="text-center py-2 text-sm">{text}</div>
				<div className="mt-4 space-x-3 text-center">
					{!!confirmText && (
						<ButtonPrimary type="button" onClick={onConfirm}>
							{confirmText}
						</ButtonPrimary>
					)}
					<ButtonSecondary type="button" onClick={onCloseModal}>
						{closeText}
					</ButtonSecondary>
				</div>
			</form>
		);
	};

	const renderTrigger = () => {
		return null;
	};

	return (
		<NcModal
			isOpenProp={show}
			onCloseModal={onCloseModal}
			contentExtraClass="max-w-lg"
			renderContent={renderContent}
			renderTrigger={renderTrigger}
			modalTitle=""
		/>
	);
};

export default ModalAlert;
