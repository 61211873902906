import { Dialog, Transition } from "@headlessui/react";
import React, { FC, Fragment, useEffect, useState } from "react";
import ShareSelect from "shared/Select/Select";
import ButtonCircle from "shared/Button/ButtonCircle";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import ButtonSecondary from "shared/Button/ButtonSecondary";
import ButtonClose from "shared/ButtonClose/ButtonClose";
import ReactSelect from "react-select";
import { COUNTRY_LIST } from "contains/contants";

export interface ModalUpdateStocksProps {
	show: boolean;
	stocks: any[];
	gallery: any;
	globalStocks: any;
	typeShow: number;
	onSave: (stocks: any[], type: any) => void;
	onCloseModal: () => void;
}

const ModalUpdateStocks: FC<ModalUpdateStocksProps> = ({
	show,
	stocks,
	gallery,
	globalStocks,
	typeShow,
	onSave,
	onCloseModal,
}) => {
	const [editStocks, setEditStocks] = useState<any[]>([]);

	useEffect(() => {
		const edit = stocks.map((c: any) => {
			return {
				...c,
				quantity: "",
				quantityReserve: typeShow === 1 ? "" : c.quantityReserve,
			};
		});

		setEditStocks(edit);
	}, []);
	return (
		<Transition appear show={show} as={Fragment}>
			<Dialog
				as="div"
				className="fixed inset-0 z-50"
				onClose={() => {
					onCloseModal();
				}}
			>
				<div className="flex items-stretch md:items-center justify-center h-full text-center md:px-4">
					<Transition.Child
						as={Fragment}
						enter="ease-out duration-300"
						enterFrom="opacity-0"
						enterTo="opacity-100"
						leave="ease-in duration-200"
						leaveFrom="opacity-100"
						leaveTo="opacity-0"
					>
						<Dialog.Overlay className="fixed inset-0 bg-black/40 dark:bg-black/70" />
					</Transition.Child>

					{/* This element is to trick the browser into centering the modal contents. */}
					<span className="inline-block align-middle" aria-hidden="true">
						&#8203;
					</span>
					<Transition.Child
						as={Fragment}
						enter="ease-out duration-300"
						enterFrom="opacity-0 scale-95"
						enterTo="opacity-100 scale-100"
						leave="ease-in duration-200"
						leaveFrom="opacity-100 scale-100"
						leaveTo="opacity-0 scale-95"
					>
						<div className="relative inline-flex xl:py-8 w-full max-h-full w-[700px]">
							<div
								className="flex-1 flex overflow-hidden max-h-full p-8 w-full text-left align-middle transition-all transform lg:rounded-2xl bg-white 
				  dark:bg-neutral-900 dark:border dark:border-slate-700 dark:text-slate-100 shadow-xl max-h-[700px]"
							>
								<span className="absolute right-3 top-3 z-50">
									<ButtonClose onClick={onCloseModal} />
								</span>

								<div className="flex-1 overflow-y-auto rounded-xl hiddenScrollbar">
									<h3 className="text-lg font-semibold flex items-center">
										Mise à jour des stocks -{" "}
										{typeShow === 2 ? "galerie" : "réserve"} - {gallery.name}
									</h3>
									<div className="flex flex-row justify-center items-center mt-2.5 mb-1 gap-3">
										<p className="flex-1 font-semibold">Produit</p>
										<p className="w-[20%] font-semibold">Stock actuel</p>
										<p className="w-[20%] font-semibold">Quantité à ajouter</p>
										{!!gallery && typeShow === 2 && (
											<p className="w-[20%] font-semibold">Quantité Réserve</p>
										)}
									</div>
									{editStocks.map((stock: any, ind) => (
										<div
											className="flex flex-row items-center justify-center mt-2.5 mb-1 gap-3"
											key={ind}
										>
											<p className="flex-1">{stock.name}</p>
											<p className="w-[20%] text-center">
												{stocks.find((p: any) => p.name === stock.name)[
													typeShow === 2 ? "quantity" : "quantityReserve"
												] || 0}
											</p>

											<input
												className="shadow-lg rounded-sm w-[20%] text-sm text-left"
												type="text"
												value={
													(typeShow === 2
														? stock.quantity
														: stock.quantityReserve) || ""
												}
												placeholder="Qté"
												onChange={({ target }) => {
													if (typeShow === 2) {
														stock.quantity = target.value;
													} else {
														stock.quantityReserve = target.value;
													}

													setEditStocks([...editStocks]);
												}}
											/>
											{!!gallery && typeShow === 2 && (
												<input
													className="shadow-lg rounded-sm w-[20%] text-sm text-left"
													type="text"
													value={
														(stock.quantityReserve || 0) - (stock.quantity || 0)
													}
													placeholder="Qté"
													disabled
												/>
											)}
										</div>
									))}
									<div className="flex flex-col sm:flex-row pt-6 items-center justify-center">
										<ButtonPrimary
											className="xl:py-1 py-1 sm:px-3 rounded-xl shadow-none sm:text-sm"
											disabled={
												editStocks.filter(
													(s) =>
														(typeShow === 1 && s.quantityReserve !== "") ||
														(typeShow === 2 && s.quantity !== "")
												).length === 0
											}
											onClick={() => {
												onSave(
													editStocks.filter(
														(s) =>
															(typeShow === 1 && s.quantityReserve !== "") ||
															(typeShow === 2 && s.quantity !== "")
													),
													typeShow
												);
											}}
										>
											Mettre à jour
										</ButtonPrimary>
										<ButtonSecondary
											className="xl:py-1 py-1 sm:px-3 rounded-xl ml-3 bg-slate-200 sm:text-sm"
											onClick={() => {
												onCloseModal();
											}}
										>
											Annuler
										</ButtonSecondary>
									</div>
								</div>
							</div>
						</div>
					</Transition.Child>
				</div>
			</Dialog>
		</Transition>
	);
};

export default ModalUpdateStocks;
