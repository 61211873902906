import React, { FC, ReactNode } from "react";
import { useNavigate } from "react-router-dom";
import { AuthContext } from "./AuthContext";
import { useDispatch } from "react-redux";
import { logout, saveUser } from "redux/reducers/auth";
import { useAuth } from "./useAuth";
import services from "utils/services";

const fakeAuth = () =>
	new Promise((resolve) => {
		setTimeout(() => resolve("2342f2f1d131rf12"), 250);
	});

export interface AuthProviderProps {
	children: ReactNode;
}
export const AuthProvider: FC<AuthProviderProps> = ({ children }) => {
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const authContext = useAuth();

	const [token, setToken] = React.useState<any>(authContext.token);

	const handleLogin = async (
		token: string,
		refreshToken: string,
		user: any
	) => {
		setToken(token);
		dispatch(saveUser({ token, user, refreshToken }));
		navigate("/");
	};

	const handleLogout = () => {
		setToken(null);
		dispatch(logout());
		navigate("/login");
	};

	const value: any = {
		...authContext,
		token,
		onLogin: handleLogin,
		onLogout: handleLogout,
		loading: false,
	};

	return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};
